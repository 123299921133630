
import {  jobOrderColNm } from "./jobOrderConstant"
import { shipmentColNm } from "./shipmentConstant"
import { invoiceColNm } from "./invoiceConstant"

export const receiveVoucherApiNm = {
  getReceiveVoucher: "/receive-voucher/getReceiveVoucher",
  getReceiveVoucherTable: "/receive-voucher/getReceiveVoucherTable",
  getReceiveVoucherReport: "/receive-voucher/getReceiveVoucherReport",
  getReceiveVoucherReportTable: "/receive-voucher/getReceiveVoucherReportTable",
  getExpenseWithCreditNote: "/receive-voucher/getExpenseWithCreditNote",
  generateReceiveVoucherNo: "/receive-voucher/generateReceiveVoucherNo",
  getInvCNNoRV: "/receive-voucher/getInvCNNoRV",
  getInvWihtCN: "/receive-voucher/getInvWihtCN",
  getInvWihtCNDetail: "/receive-voucher/getInvWihtCNDetail",
  insertReceiveVoucher: "/receive-voucher/insertReceiveVoucher",
  insertReceiveVoucherWithExpense: "/receive-voucher/insertReceiveVoucherWithExpense",
  insertReceiveVoucherWithInvoice: "/receive-voucher/insertReceiveVoucherWithInvoice",
  updateReceiveVoucher: "/receive-voucher/updateReceiveVoucher",
  updateReceiveVoucherColumn: "/receive-voucher/updateReceiveVoucherColumn",
  deleteReceiveVoucher: "/receive-voucher/deleteReceiveVoucher",
  uploadFile: "/receive-voucher/uploadFile",
  updateFileName: "/receive-voucher/updateFileName",
  deleteFile: "/receive-voucher/deleteFile",
}


export const receiveVoucherColNm =  {
  RVId: "เลขที่การชำระเงิน",
  RVNo: "เลขที่รับชำระเงิน",
  RVDte: "วันที่ทำรายการ",
  PayMthdNm: "วิธีชำระเงิน",
  PayMthdId: "วิธีชำระเงิน",
  BkSNm: "ธนาคาร",
  BkAccSNm: "ชื่อย่อบัญชี",
  BkAccId: "ชื่อย่อบัญชี",
  NumExp: "รายได้",
  NumRcpt: "ใบเสร็จ",
  NumInv: "Invoice",
  Total: "ยอด Invoice",
  WhtPr: "หัก ณ ที่จ่าย",
  FeeAmnt: "ค่าธรรมเนียม",
  BBNo: "เลขที่สมุดบัญชี",
  BBPage: "หน้า", 
  BBRow : "แถว",
  PayAmnt: "ยอดรับชำระเงิน",
  PayDte: "วันที่ชำระเงิน",
  DiffAmnt: "ส่วนต่าง",
  DiffDscp: "รายละเอียดส่วนต่าง",
  ChqDte: "วันที่เช็ค",
  ChqNo: "เลขที่เช็ค",
  ChqBkNm: "ธนาคารผู้ออกเช็ค",
  ChqBkId: "ธนาคารผู้ออกเช็ค",
  ChqBrch: "สาขา",
  ChqAmnt: "ยอดหน้าเช็ค",
  InvNo: invoiceColNm.InvNo,
  JobNo: shipmentColNm.JobNo,
  BNNo: invoiceColNm.BNNo,
  Rmk: "หมายเหตุ",
  IsCmpt: "Complete",
  CusSNm: jobOrderColNm.CusSNm,
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
  RcptNo: "เลขที่ใบเสร็จ"
}