import {  Dialog, DialogContent, InputAdornment, TextField } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import CheckobxFormControl from "components/CheckobxFormControl"
import ComboBox from "components/ComboBox"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { expenseColNm } from "constants/expenseConstant"
import { expenseJobOrderColNm } from "constants/expenseJobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
// import useFinanceMsData from "hooks/useFinanceMsData"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

const initDialogData = {
  IsCost: 1,
  ExpId: null,
  UntPr: 0,
  VatPrct: 0,
  WhtPrct: 0,
  IsOwnRcpt: 0,
  Qty: 1,
  ExpJobOrdId: null,
  ExpNm: "",
  IsAddDI: 0,
}
const AddExpenseDialog = ({ selectedRow, expData, dialogOpen, setDialogOpen, onFinish }) => {

  const { msData } = useContext(GlobalStateContext)
  // const { msData } = useFinanceMsData()
  const [dialogData, setDialogData] = useState({ ...initDialogData })


  const oprHide = useMemo(() => {
    let oprHide = selectedRow?.ExpJobs?.reduce((prev, cur) => {
      prev[cur.ExpId] = cur.IsOprHide
      return prev
    }, {}) || {}

    for (const exp of expData) {
      oprHide[exp.ExpId] = 1
    }
    return oprHide
  }, [selectedRow, expData])

  const expNoTransport = useMemo(() => msData?.expCombo?.filter(item => !oprHide[item.id]) || [], [msData, oprHide])

  const amountProps = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    type: 'number',
    name: name,
    sx: { width: 150 },
    label: expenseColNm[name],
    value: +dialogData[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) },
    inputProps: { style: { textAlign: 'right' } },
    InputProps: { endAdornment: <InputAdornment position="end">บาท</InputAdornment> },
  }), [dialogData])

  const comboProp = useCallback((name, label, options) => ({
    options: options,
    label: label,
    required: true,
    sx: { width: 250 },
    selectedId: dialogData.ExpId,
    setSelectedId: (id) => setDialogData(oldState => {
      const exp = msData.expenses.find(item => item.ExpId === id)
      if (exp) {
        return {
          ...oldState,
          ExpId: id,
          UntPr: exp.UntPr,
          VatPrct: exp.VatPrct,
          VatPr: exp.VatPr,
          WhtPrct: exp.WhtPrct,
          IsOwnRcpt: exp.IsOwnRcpt,
          ExpNm: exp.ExpNm,
        }
      } else {
        return { ...oldState, ExpId: id }
      }
    })
  }), [dialogData, msData, setDialogData])

  // const handleSetIsCost = useCallback((value) => () => {
  //   setDialogData(o => ({ ...o, IsCost: value }))
  // }, [])
  const handleDialogOK = useCallback(() => {
    onFinish(dialogData)
    setDialogOpen(false)
  }, [onFinish, dialogData, setDialogOpen])

  useEffect(() => {
    if (!dialogOpen) {
      setDialogData({ ...initDialogData })
    }
  }, [dialogOpen])


  console.log("in AddExpenseDialog oprHide::", oprHide)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'

    // PaperProps={{
    //   style: {
    //     minHeight: '90%',
    //     maxHeight: '90%',
    //   }
    // }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"เพิ่มค่าใช้จ่าย"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} sx={{gap:1}} >
          <BoxFR >
            {/* <TextField {...amountProps('IsCost')} 
            select fullWidth 
            abel="เก็บเงินลูดค้า" required>
            <MenuItem value={0}>เก็บเงินลูกค้า ได้</MenuItem>
            <MenuItem value={1}>เก็บเงินลูกค้า ไม่ได้</MenuItem>
          </TextField> */}
            {/* <BoxFR sx={{ border: "1px solid lightgrey", borderRadius: 1.5 }} position="relative" p={1} >
              <Typography variant="overline" position="absolute" top={-16} bgcolor="white" px={1} >การเก็บเงินลูกค้า</Typography>
              <ButtonGroup>
                <Button variant={dialogData.IsCost ? "contained" : "outlined"} sx={{ width: 80 }} onClick={handleSetIsCost(1)}>ไม่ได้</Button>
                <Button variant={dialogData.IsCost ? "outlined" : "contained"} sx={{ width: 80 }} onClick={handleSetIsCost(0)}>เก็บได้</Button>
              </ButtonGroup>
            </BoxFR> */}
            <ComboBox {...comboProp("ExpId", expenseJobOrderColNm.ExpNm, expNoTransport)} />
            <TextField {...amountProps('UntPr')} label="ราคา" sx={{width: 110}} />
            <TextField size="small" value={dialogData.Rmk} 
              onChange={(e)=>setDialogData(o=>({...o, Rmk: e.target.value}))} label="หมายเหตุ" sx={{flex:1}} />
          </BoxFR>
          {/**  Change from button group */}
          <CheckobxFormControl label={`สามารถเก็บเงินลูกค้าได้`} 
            checked={dialogData.IsCost?false:true}  
            onChange={(e)=>setDialogData(o=>({...o, IsCost: e.target.checked?0:1}))}/>
          {/* <CheckobxFormControl label={<Typography>จ่ายเงินให้กับ <strong>{driverFullName}</strong></Typography>} 
            checked={dialogData.IsAddDI?true:false}  
            onChange={(e)=>setDialogData(o=>({...o, IsAddDI: e.target.checked?1:0}))}/> */}
        </BoxFC>

      </DialogContent>
      <DialogFooter okText={"เพิ่ม"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default AddExpenseDialog