
export const textFieldProp = {
  variant: "outlined",
  size: "small",
}

export const initCompanyData = {
  NameTH: "",
  NameEN: "",
  AddrTH: "",
  AddrEN: "",
  TaxId: "",
  Tel: "",
  Fax: "",
  Email: "",
  Lat: 13.072172,
  Lng: 100.903980
}


export const initSendEmailData = {
  Email: "",
  Pass: "",
  ReplyTo: "",
  Ending: "",
  InvPreText: "",
  Cc: "",
  Bcc: ""
}


export const initPreText = {
  BookingReject: [],
  AddJobOrderChild: [],
  InvoiceFiles: [],
  ShipmentFiles: [],
  Times: ["00:00", "23:59"]
}

export const initRefColumnName = {
  shipment: {
    Ref1: "อ้างอิง1",
    Ref2: "อ้างอิง2",
    Ref3: "อ้างอิง3",
    Ref4: "อ้างอิง4",
    Ref5: "อ้างอิง5",
    Ref6: "อ้างอิง6",
  },
  job_order: {
    Ref1: "อ้างอิง1",
    Ref2: "อ้างอิง2",
    Ref3: "อ้างอิง3",
    Ref4: "อ้างอิง4",
    Ref5: "อ้างอิง5",
    Ref6: "อ้างอิง6",
  }
}

export const initRefColumnJob = {
  email:[],
  invoice: []
}
export const containerStatusColNm = {
  Name: "ชื่อ",
  NameEn: "ชื่อ (Eng)",
  Prty: "ลำดับ",
  bgClr: "สี",
  IsHide: "ซ่อน/แสดง",
}




// const initSendEmailData = {
//   Email: "bittocorp@gmail.com",
//   Pass: "eikrqvibdetmvxgl",
//   ReplyTo: "cs@kanintranpsport.com",
//   Ending: "*** REMARK :\nPlease do not reply to KANINTRANSPORT@GMAIL.COM\nIf you would like to contact us, please E-mail to CS@KANINTRANSPORT.COM . ",
//   InvPreText: "กรุณาตรวจสอบเอกสารตามไฟล์แนบ"
// }


// const initPretext = {
//   AddJobOrderChild: ["หัวเปล่าลากตู้บรรจุลงท่า", "หัวเปล่าลากตู้เปล่าคืนลาน", ""],
//   InvoiceFiles: ["ใบแจ้งหนี้", "ใบวางบิล", "ใบเสร็จลูกค้า"]

// }