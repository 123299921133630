export const processConstStatus = (valueData, containerStatuses) => {
  valueData.forEach(data => {
    const contStatuses = containerStatuses.filter(item=>!item.IsHide).map((contStatusItem) => {
      const contStatus = {
        ContStsId: contStatusItem.ContStsId,
        ContStsNm: contStatusItem.Name,
        Prty: contStatusItem.Prty,
        Clr: contStatusItem.BgClr
      }
      const drvData =  (data[`DrvSts${contStatus.ContStsId}`] && data[`DrvSts${contStatus.ContStsId}`].split(";")) || [null, ""]
      contStatus.DrvId = (drvData[0] && +drvData[0]) || null
      contStatus.DrvNNm = drvData[1]
      return contStatus;
    })

    contStatuses.sort((a, b) => a.Prty - b.Prty)
    data.ContStses = contStatuses
  })
  return valueData
}