import React, { useEffect, useState } from "react";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { Box, Dialog, DialogContent, Typography, MenuItem,  TextField } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { getISOCd } from "utils";
import { useContext } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { useCallback } from "react";

const initColData = {
  ContSize: "20",
  ContTyp: "",
  ContHq: 1,
  ISOCd: "",
  TlrTypId: 1
}

const generateMenuItem = (list) => (
  list.map(item => (
    <MenuItem key={item.id} value={item.id}>
      {item.label}
    </MenuItem>
  ))
)

const UpdateContSizeDialog = ({ jobOrdIds, oldData, updateColumn, setDialogOpen, dialogOpen }) => {
  const [colData, setColData] = useState(initColData)

  const { msData} = useContext(GlobalStateContext)

  const dataProp = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    label: jobOrderColNm[name],
    fullWidth: true,
    value: colData[name],
    onChange: (e) => {
      if (name === 'ContSize' || name === 'ContTyp' || name === 'ContHq') {
        let isoCd = "";
        if (name === 'ContSize') isoCd = getISOCd(e.target.value, colData.ContTyp, colData.ContHq);
        else if (name === 'ContTyp') isoCd = getISOCd(colData.ContSize, e.target.value, colData.ContHq);
        else if (name === 'ContHq') isoCd = getISOCd(colData.ContSize, colData.ContTyp, e.target.value);

        setColData((oldData) => ({
          ...oldData,
          [name]: e.target.value,
          ISOCd: isoCd
        }))
      } else {
        setColData((oldData) => ({ ...oldData, [name]: e.target.value }))
      }
    }
  }), [colData])


  useEffect(() => {
    if(dialogOpen){
      setColData({
        ContSize: oldData.ContSize,
        ContTyp: oldData.ContTyp,
        ContHq: oldData.ContHq,
        ISOCd: oldData.ISOCd,
        TlrTypId: oldData.TlrTypId
      })
    } else {
      setColData(initColData)
    }
  }, [dialogOpen, oldData])

  const handleUpdateColumn = () => {
    updateColumn( colData, jobOrdIds)
  }
  console.log("jobOrdIds::", jobOrdIds)
  console.log("oldData::", oldData)
  console.log("colData::", colData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`แก้ไขข้อมูล ขนาดตู้`}
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={1} mt={1}>
          {jobOrdIds.length > 1 && <Typography variant="Body" >{`แก้ไขข้อมูลจำนวน ${jobOrdIds.length} แถว`}</Typography>}

          <Box display="flex" gap={2} alignItems="center" justifyContent="center" mt={1}>

            <TextField select {...dataProp("ContSize")} required >{generateMenuItem(msData.contSizeCombo)}</TextField>
            <TextField select {...dataProp("ContTyp")} required >{generateMenuItem(msData.contTypCombo)}</TextField>
            <TextField select {...dataProp("ContHq")} required >
              <MenuItem value={0}>Std</MenuItem>
              <MenuItem value={1}>HQ</MenuItem>
            </TextField>
            <TextField {...dataProp("ISOCd")} >{generateMenuItem(msData.tlrTypCombo)}</TextField>
            <TextField select {...dataProp("TlrTypId")} required >{generateMenuItem(msData.tlrTypCombo)}</TextField>
          </Box>
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleUpdateColumn} />
    </Dialog>
  )
}
export default React.memo(UpdateContSizeDialog, (o, n) => o.dialogOpen === n.dialogOpen)