

let lastControlTm = Date.now()

export const onKeyDownCopy = (e, value) =>{
  if(e.key === "Control"){
    lastControlTm = Date.now()
    return true
  }
  console.log("lastControlTm", lastControlTm)
  if(e.key === "c" && Date.now() - lastControlTm < 1000){
    navigator.clipboard.writeText(value)
    return true
  }
  return false
}