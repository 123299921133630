import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { GridActionsCellItem } from '@mui/x-data-grid';

export const columns = (handleDeleteRow) => [
  {
    field: 'No',
    minWidth: 30,
    width: 30,
    align: 'center',
  },
  {
    field: 'label',
    flex: 2,
  },
  {
    hide: handleDeleteRow === null,
    field: "actions",
    type: "actions",
    width: 20,
    minWidth: 20,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<DeleteForeverIcon />}
        label="Delete"
        onClick={handleDeleteRow(params.row.id)}
      />
    ] ,
  }
]
