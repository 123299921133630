
import { ExitToAppRounded, LocalAtmRounded, PlaylistAddCheckCircleRounded } from "@mui/icons-material";
import { Button, ButtonGroup, IconButton, Paper, Popover, TextField, Tooltip, Typography } from "@mui/material";
import { alertError } from "components/Alert";

import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import { jobOrderApiNm } from "constants/jobOrderConstant";
import dayjs from "dayjs";

import React, { useCallback, useState } from "react";

let curExpData = null
const Expense = ({ expenseData, setExpenseData, msData, user, ax }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [curMoveJobOrdId, setCurMoveJobOrdId] = useState(null);

  const open = Boolean(anchorEl);

  const handleSetIsCash = useCallback((expData, isCash) => () => {
    expData.IsCash = isCash
    setExpenseData(o => [...o])
  }, [setExpenseData])

  const handleIsPaidChange = useCallback((expData, oldValue) => (e) => {
    expData.IsPaid = !oldValue
    setExpenseData(o => [...o])
  }, [setExpenseData])


  const handleClrDteChange = useCallback((expData, oldValue) => (e) => {
    if (oldValue) {
      expData.ClrDte = null
      expData.ClrAccId = null
    } else {
      expData.ClrDte = dayjs().format("YYYY-MM-DD HH:mm:ss")
      expData.ClrAccId = user.AccId
    }
    setExpenseData(o => [...o])
  }, [setExpenseData, user.AccId])

  const handleMoveJobOrderClick = useCallback((expData, oldValue) => (event) => {
    console.log("handleMoveJobOrderClick:: oldValue", oldValue)
    curExpData = expData
    setCurMoveJobOrdId(oldValue || "")
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };
  const handleOKMoveJobOrdId = useCallback(() => {
    ax.post(jobOrderApiNm.getJobOrder, { JobOrdId: curMoveJobOrdId }).then(value => {
      if (value.data) {
        if (value.data.length !== 1) {
          alertError("ไม่พบใบงานนี้")
          return
        }

        curExpData.MoveJobOrdId = `${curMoveJobOrdId}`
        console.log("handleOKMoveJobOrdId:: expenseData", expenseData)
        setExpenseData(o => [...o])
        setAnchorEl(null);
      }
    })
  }, [curMoveJobOrdId, setExpenseData, expenseData, ax])

  console.log("Expense render curMoveJobOrdId", curMoveJobOrdId)
  console.log("Expense render curExpData", curExpData)
  return (
    <BoxFC sx={{ gap: 1, mt: -1 }}>
      {
        expenseData.map((expData, index) => (
          <BoxFR key={expData.DalyExpId}>
            <Typography variant="body" width={15}>{index + 1}.</Typography>
            <Typography variant="body" width={140}>{expData.Dscp}</Typography>

            <ButtonGroup variant="outlined" >
              <Button sx={{ px: 1 }} variant={expData.IsCash === 1 ? "contained" : "outlined"} onClick={handleSetIsCash(expData, 1)}>เงินสด</Button>
              <Button sx={{ px: 1 }} variant={expData.IsCash === 0 ? "contained" : "outlined"} onClick={handleSetIsCash(expData, 0)}>โอน</Button>
            </ButtonGroup>
            <Typography variant="body" width={80} textAlign="right">{expData.Amnt * -1} บาท</Typography>

            <ComboBox options={msData.driverOnlyCombo}
              sx={{ width: 120 }}
              selectedId={expData.DrvId}
              setSelectedId={(id) => {
                setExpenseData(o => {
                  o[index].DrvId = id
                  return [...o]
                })
              }}
            />

            <Tooltip title="จ่ายเงินแล้ว" arrow placement="top">
              <IconButton sx={{ ml: 1, p: 0 }} onClick={handleIsPaidChange(expData, expData.IsPaid)}>
                <LocalAtmRounded fontSize="large" color={expData.IsPaid ? "primary" : "default"} />
              </IconButton>
            </Tooltip>
            <Tooltip title={`ย้ายใบงาน${expData.MoveJobOrdId ? `: #${expData.MoveJobOrdId}` : ""}`} arrow placement="top">
              <IconButton sx={{ p: 0 }} onClick={handleMoveJobOrderClick(expData, expData.MoveJobOrdId)}>
                <ExitToAppRounded fontSize="large" color={expData.MoveJobOrdId ? "primary" : "default"} />
              </IconButton>
            </Tooltip>
            <Tooltip title="เคลียเงิน" arrow placement="top" onClick={handleClrDteChange(expData, expData.ClrDte)}>
              <IconButton sx={{ p: 0 }}>
                <PlaylistAddCheckCircleRounded fontSize="large" color={expData.ClrDte ? "primary" : "default"} />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="ย้ายใบงาน">
              <CheckobxFormControl label="" sx={{width: 30}}
                checked={expData.IsPaid ? true : false}
                onChange={handleCheckboxChange(expData)}
                icon={<ExitToAppRounded fontSize="large" />}
                checkedIcon={<ExitToAppRounded fontSize="large" />} />
            </Tooltip>
            <Tooltip title="จ่ายเงินแล้ว">
              <CheckobxFormControl label="" sx={{width: 30}}
                checked={expData.IsPaid ? true : false}
                onChange={handleCheckboxChange(expData)}
                icon={<LocalAtmRounded fontSize="large" />}
                checkedIcon={<LocalAtmRounded fontSize="large" />} />
            </Tooltip>
            <Tooltip title="เคลียเงิน">
              <CheckobxFormControl label="" sx={{width: 30}}
                checked={expData.IsPaid ? true : false}
                onChange={handleCheckboxChange(expData)}
                icon={<PlaylistAddCheckCircleRounded fontSize="large" />}
                checkedIcon={<PlaylistAddCheckCircleRounded fontSize="large" />} />
            </Tooltip> */}
          </BoxFR>
        ))
      }
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Paper sx={{ p: 1 }}>
          <BoxFC sx={{ gap: 1 }}>
            <TextField size="small" label="เลขที่ใบงาน" sx={{ width: 135 }} value={curMoveJobOrdId} type="number"
              onChange={(e) => { setCurMoveJobOrdId(e.target.value) }} />
            <BoxFR>
              <Button variant="contained" size="small" color="secondary" sx={{ width: 60 }}
                onClick={handlePopOverClose}>Cancel</Button>
              <Button onClick={handleOKMoveJobOrdId} variant="contained" size="small" color="primary" sx={{ width: 60 }}>OK</Button>
            </BoxFR>
          </BoxFC>
        </Paper>
      </Popover>
    </BoxFC>
  )
}

export default React.memo(Expense)