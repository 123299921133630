import { MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";

const YearSelect = ({ rangeYear = 5, numYear = 10, label, sx, dteEn, setDte }) => {

  const handleTextChange = useCallback(e => {
    setDte(dayjs(`${e.target.value - 4}-01-01 00:00:00.000`), dayjs(`${e.target.value}-12-31 23:59:59.997`))
  }, [setDte])

  const generateMenuItems = useMemo(() => {
    const result = []
    const curYear = dayjs().year();
    for (let i = 0; i < numYear; i++) {
      result.push(<MenuItem key={curYear - i} value={curYear - i}>{curYear - i - rangeYear + 1} - {curYear - i}</MenuItem>)
    }
    return result
  }, [rangeYear, numYear])

  return (
    <Box width={140} sx={sx}>
      <TextField label={label || "ปี"} select size="small" variant="outlined" fullWidth
        value={(dteEn && dteEn.year() )|| ""} onChange={handleTextChange}>
        <MenuItem value="">ไม่ระบุ</MenuItem>
        {generateMenuItems}
      </TextField>
    </Box>
  )
}

export default React.memo(YearSelect)