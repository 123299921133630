import { Box, Button, ButtonGroup, Dialog, DialogContent, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import CheckobxFormControl from "components/CheckobxFormControl"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { dailyExpApiNm } from "constants/dailyExpenseConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { UserContext } from "contexts/UserContext"
import dayjs from "dayjs"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

const AddDailyExpenseDialog = ({ exps, dialogOpen, setDialogOpen, onFinish, lastFilter, driverFullName, drvId, selectedRow }) => {

  const { ax, msData } = useContext(GlobalStateContext)

  const { user } = useContext(UserContext)

  const initDialogData = useMemo(() => ({
    IsCash: +(msData.serverData.DefaultValue?.IsCash || 0),
    IsPaid: +(msData.serverData.DefaultValue?.IsPaid || 0)
  }), [msData.serverData])

  const sumExps = useMemo(() => exps.reduce((prev, cur) => prev + +cur.UntPr, 0), [exps])
  const [dialogData, setDialogData] = useState(initDialogData)

  const handleSetIsCash = useCallback((value) => () => {
    setDialogData(oldData => ({ ...oldData, IsCash: value }))
  }, [])

  const handleDialogOK = useCallback(async () => {
    const postData = {
      getArgs: lastFilter
    }
    const argsArr = []
    for (const exp of exps) {
      argsArr.push({
        DalyExpId: 0,
        ExpDte: dayjs().format("YYYY-MM-DD HH:mm:00"),
        DrvId: drvId,
        JobOrdId: selectedRow.JobOrdId,
        Amnt: exp.UntPr * -1,
        IsCash: dialogData.IsCash,
        IsPaid: dialogData.IsPaid,
        ClrDte: dayjs().format("YYYY-MM-DD HH:mm:00"),
        Dscp: exp.ExpNm,
        Rmk: exp.Rmk,
        ClrAccId: user.AccId
      })
    }
    postData.argsArr = argsArr

    ax.post(dailyExpApiNm.insertDailyExpenseBulk, postData).then(value => {
      if (value.data) {
        onFinish(value.data)
        setDialogOpen(false)
      }
    })
  }, [ax, exps, lastFilter, selectedRow, user.AccId, dialogData, drvId, onFinish, setDialogOpen])

  useEffect(() => {
    if (!dialogOpen) {
      setDialogData(initDialogData)
    }
  }, [dialogOpen, initDialogData])

  console.log('AddDailyExpenseDialog', dialogData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"การจ่ายเงิน"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} sx={{gap:1}}>
          <Typography><strong>จ่ายเงินให้: </strong>{driverFullName}</Typography>
          <Typography><strong>ค่าใช้จ่าย: </strong>{exps.length === 1 ? exps[0].ExpNm : `จำนวน ${exps.length} รายการ`}</Typography>
          <Typography><strong>จำนวนเงิน: </strong>{sumExps} บาท</Typography>
          <BoxFR>
            <Box width={16} />
          </BoxFR>
          <BoxFR mt={1} >
            <Typography><strong>วิธีการจ่ายเงิน: </strong></Typography>
            <ButtonGroup variant="outlined" >
              <Button sx={{ px: 1 }} variant={dialogData.IsCash === 1 ? "contained" : "outlined"} onClick={handleSetIsCash(1)}>เงินสด</Button>
              <Button sx={{ px: 1 }} variant={dialogData.IsCash === 0 ? "contained" : "outlined"} onClick={handleSetIsCash(0)}>โอน</Button>
            </ButtonGroup>
            <CheckobxFormControl label="จ่ายเงินแล้ว" checked={dialogData.IsPaid ? true : false} sx={{ width: 160 }}
              onChange={(e) => setDialogData(o => ({ ...o, IsPaid: e.target.checked ? 1 : 0 }))} />
          </BoxFR>
        </BoxFC>

      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default AddDailyExpenseDialog