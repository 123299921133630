import { jobOrderColNm } from "constants/jobOrderConstant"
import dayjs from "dayjs"
import { formatterDateTimeNoYear, getterDateTimeStr } from "utils/datagridColumnDateTm"
import { getPOIPlace } from "utils/getPOIPlace"
export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "CusSNm",
    headerName: jobOrderColNm.CusSNm,
    width: 180,
  },
  {
    field: "Loc",
    headerName: "Location",
    width: 180,
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 130,
  },
  {
    field: "Bkg",
    headerName: jobOrderColNm.Bkg,
    width: 130,
  },
  {
    field: "RtnDteEn",
    headerName: jobOrderColNm.RtnDteEn,
    width: 100,
    type: 'dateTime',
    valueGetter: getterDateTimeStr,
    valueFormatter: formatterDateTimeNoYear,
  },
  {
    field: "AptTm",
    headerName: "Appoint Time",
    width: 100,
    type: 'dateTime',
    valueGetter: getterDateTimeStr,
    valueFormatter: formatterDateTimeNoYear,
  },
  {
    field: "TakePlc",
    headerName: "CY",
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 90,
  },
  {
    field: "RtnPlc",
    headerName: "Retrun",
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 90,
  },
  {
    field: "RtnDteSt",
    headerName: "First Return",
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params)=> params.value? params.value.format("DD/MM HH:mm"): "-",
    width: 100,
  },
  {
    field: "ContNo",
    headerName: "Container No.",
    width: 130,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSizeFull,
    width: 80,
  },
  {
    field: "Sts",
    headerName: "Status",
    width: 100,
  },
  {
    field: "Rmk",
    headerName: "Remark",
    flex: 1,
  }
]