import { yellow } from "@mui/material/colors"

export const sxSumColorClass = {
  '& .total': {
    fontWeight: "bold",
    bgcolor: yellow[100],
    "&:hover": {
      bgcolor: yellow[200]
    }
  }
}

export const getSumColorClassName = (params) => params.id === 0 && "total"

export const addSumRow = ({ dataTable, sumColNms, sumColTotal }) => {
  if(!dataTable || dataTable.length === 0){
    return dataTable
  }
  const result = dataTable.map(item=>({...item}))
  const sumRow = {}
  for (const col of sumColNms) {
    sumRow[col] = 0
  }
  const sumIndex = result.findIndex(row=>row.id === 0)
  if(sumIndex >= 0){
    result.splice(sumIndex, 1)
  }
  for (const row of result) {
      for (const col of sumColNms) {
        sumRow[col] += +(row[col] || 0)
      }
  }
  sumRow.id = 0
  sumRow[sumColTotal] = "รวม"
  result.push(sumRow)
  return result
}