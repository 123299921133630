import { Box, Dialog, DialogContent, TextField } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import DateTimeSelect from "components/DateTimeSelect";
import SearchButton from "components/buttons/SearchButton";
import {  driverIncomeShipmentColumns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { addIdForDataGrid } from "utils";
import CheckobxFormControl from "components/CheckobxFormControl";
import { branchApiNm } from "branch/constant/branchApiNm";

const initFilterData = {
  AptTmSt: null,
  AptTmEn: null,
  IsNoAmnt: true,
  Bkg: null
}
let oDataTable = null
const DriverIncomeShipmentDialog = ({ dialogOpen, setDialogOpen }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [filterData, setFilterData] = useState(initFilterData)

  const getData = useCallback((filter)=>{
    ax.post(branchApiNm.getDriverIncomeShipment, filter).then(value=>{
      if(value.data){
        setDataTable(addIdForDataGrid(value.data, "ShpmId"))
        oDataTable = value.data.map(item=>item)
      }
    })
  }, [ax])

  const handleSearchClick = useCallback(()=>{
    getData(filterData)
  }, [filterData, getData])
  const handleClose = useCallback(()=>{
    setDialogOpen(false)
  }, [setDialogOpen])

  const setSelectAptTm = useCallback((st, en)=>{
    setFilterData(o=>({...o, AptTmSt: st, AptTmEn: en}))
  }, [])
  const handleCheckboxChange = useCallback((e)=>{
    setFilterData(o=>({...o, IsNoAmnt: e.target.checked}))
  }, [])

  const handleTextFieldChange = useCallback((e)=>{
    setFilterData(o=>({...o, Bkg: e.target.value || null}))
  }, [])
  
  const handleCellEdit = useCallback((params) => {
    // if(/[0-9]/.test(params.value)){
      setDataTable(o => {
        const result = [...o]
        const foundRow = result.find(item => item.id === params.id)
        if (foundRow) {
          foundRow[params.field] = params.value
        }
        return result
      })
    // }
  }, [])

  const handleSave = useCallback(()=>{
    setTimeout(()=>{
      const argsArr = []
      for(let i=0; i<oDataTable.length; i++){
        if(+oDataTable[i].Amnt !== +dataTable[i].Amnt){
          argsArr.push({
            ShpmId: dataTable[i].ShpmId,
            Amnt: dataTable[i].Amnt || null
          })
        }
      }
      if(argsArr.length === 0){
        setDialogOpen(false)
        return 
      }
      ax.post(branchApiNm.insertUpdateDriverIncomeShipment, {argsArr}).then(value=>{
        if(value.data !== null){
          setDialogOpen(false)
        }
      })
    }, 100)
  }, [dataTable, ax, setDialogOpen])
  useEffect(() => {
    if(dialogOpen){
      getData(initFilterData)
    } else {
      setDataTable([])
      setFilterData(initFilterData)
    }
  }, [dialogOpen, getData])

  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' >
      <DialogHeader handleDialogClose={handleClose}
        title="กำหนดค่าเที่ยวจาก บุ๊คกิ้ง"
      />
      <DialogContent>
        <BoxFC mt={2} height={700}>
          <BoxFR>
            <CheckobxFormControl label="เฉพาะ บุ๊คกิ้ง ที่ยังไม่มีค่าเที่ยว" 
              checked={filterData.IsNoAmnt} onChange={handleCheckboxChange} />
            <DateTimeSelect
              sx={{ width: 160 }}
              label="วันที่วิ่งงาน"
              start={filterData.AptTmSt}
              end={filterData.AptTmEn}
              setSelectDate={setSelectAptTm} />
            <TextField variant="outlined" size="small" label="บุ๊คกิ้ง" 
              value={filterData.Bkg || ""} onChange={handleTextFieldChange}/>
            <SearchButton onClick={handleSearchClick} />
          </BoxFR>

          <Box width="100%" flexGrow={1} >
            <DataGridCellExpand
              hideFooter
              rows={dataTable}
              columns={driverIncomeShipmentColumns}
              disableSelectionOnClick
              onCellEditCommit={handleCellEdit} 
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={handleClose}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(DriverIncomeShipmentDialog)