import { Autocomplete, TextField } from "@mui/material";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React from "react";
import { useContext } from "react";

const TagBox = ({sx, value, setValue, label, tblAbbr}) => {

  const {msData} = useContext(GlobalStateContext)
  return (

    <Autocomplete
      sx={sx}
      multiple
      options={tblAbbr?msData.tagCombo.filter(tag=>tag.refId===tblAbbr): msData.tagCombo}
      value={value}
      // onChange={(e, newValues) => {setTagData(newValues? newValues.map(item=>item.TagId):[]) }}
      onChange={(e, newValues) => { setValue(newValues ? newValues : []) }}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={((option, value) => {
        // console.log({ option }, { value });
        return option.id === value.id
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          label={label || "Tag"}
        />
      )}
    />
  )
}

export default TagBox