export const modifyPOICombo = (poiCombo, type, shpmTypId) => {
  const ports = []
  const factory = []
  const depot = []
  const other = []
  for(const poi of poiCombo){
    if(poi.refId === 4){
      factory.push(poi)
    } else if(poi.refId === 5){
      depot.push(poi)
    } else if(poi.refId === 6){
      ports.push(poi)
    } else {
      other.push(poi)
    }
  }
  if(type === "LOC"){
    return [...factory, ...depot, ...ports, ...other]
  } 
  if(type === "TAKE"){
    if(shpmTypId === 1 || shpmTypId === 3){
      return [...depot, ...ports, ...factory, ...other]
    } else if(shpmTypId === 2){
      return [...ports, ...depot, ...factory, ...other]
    }
  }
  if(type === "RTN"){
    if(shpmTypId === 1 || shpmTypId === 3){
      return [...ports, ...depot, ...factory, ...other]
    } else if(shpmTypId === 2){
      return [...depot, ...ports, ...factory, ...other]
    }
  }
  return poiCombo
}