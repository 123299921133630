export const locationText = (jobOrderData, option) => {
  let numChar = 0
  if(option){
    numChar = option.numChar
  }
  const msData = JSON.parse(localStorage.getItem("msData"))

  let take = msData.poiObj[jobOrderData.TakePOIId] || jobOrderData.TakePlc
  let loc = msData.poiObj[jobOrderData.LocPOIId] || jobOrderData.Loc
  let rtn = msData.poiObj[jobOrderData.RtnPOIId] || jobOrderData.RtnPlc
  if(numChar){
    take = take.substring(0, numChar)
    loc = loc.substring(0, numChar)
    rtn = rtn.substring(0, numChar)
  }

  return `${take||""}${loc&&loc!=="-"?`-${loc}`:""}${rtn?`-${rtn}`:""}`
}