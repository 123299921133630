
import React from "react";
import { IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { numberFormat } from 'utils';
import TodayIcon from '@mui/icons-material/Today';
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import { Link } from 'react-router-dom';
import { blueGrey, green, red, yellow } from '@mui/material/colors';
import { Box } from "@mui/system";
import { locationText } from "utils/locationText";

const sxGap0 = { gap: 0 }

const DataBoxInner = ({ item, handles }) => {
  const { handleRemoveJobOrder, handleChangeAptTm, handleCheckBoxChange, handleBoxClick } = handles

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <BoxFR
      sx={{
        border: `1px solid ${item.IsAdded ? green[500] : blueGrey[300]}`,
        borderRadius: 2,
        bgcolor: item.Bkg === item.hilightBkg ? yellow[50] : item.TagIds.includes(10) ? green[100] : "none",
        px: 0.5,
        position: "relative"
      }}>
      <BoxFC sx={sxGap0}>
        <BoxFR>
          {!item.IsAdded && <Checkbox sx={{ p: 0 }} checked={item.IsSelected} onChange={handleCheckBoxChange(item)} />}
          <Typography>ID:<Link target="_blank" to={`/emily/DailyJob/${item.JobOrdId}`} style={{ color: "#1976d2" }}>{item.JobOrdId}</Link> ${numberFormat(item.IsAdded ? +item.DXAmnt : +item.DXAmnt * -1)} บาท </Typography>
        </BoxFR>
        <BoxFR>
          <Typography>{item.Agnt}-<Link target="_blank" to={`/emily/DailyJob/Bkg/${item.Bkg}`} style={{ color: "#1976d2" }}>{item.Bkg}</Link> </Typography>
          <Tooltip followCursor title="ไฮไลท์บุ๊คกิ้งเดียวกัน">

            <IconButton sx={{ p: 0, m: 0 }} onClick={handleBoxClick(item.hilightBkg === item.Bkg ? null : item.Bkg)}>
              {
                item.Bkg === item.hilightBkg ?
                  <BookmarkRoundedIcon color="primary" /> :
                  <BookmarkBorderRoundedIcon color="primary" />
              }
            </IconButton>
          </Tooltip>
        </BoxFR>

        <Typography>{item.ContSizeFull} {locationText(item)}</Typography>
      </BoxFC>
      <BoxFC sx={sxGap0}>
        <Tooltip title="ย้ายวัน" followCursor>
          <IconButton onClick={item.IsAdded ? null : (e) => handleChangeAptTm(item.JobOrdId)} sx={{ mb: -1 }}>
            <TodayIcon color={item.IsAdded ? "inherit" : "primary"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="คืนงาน" followCursor>
          <IconButton onClick={(e) => handleRemoveJobOrder(item)}>
            <RemoveCircleRoundedIcon />
          </IconButton>
        </Tooltip>
      </BoxFC>

      {item.Rmk && <Box>
        <Typography
          color={red[500]}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            fontSize: "1.5rem",
            position: "absolute",
            top: -2,
            right: 8,
            cursor: "help"
          }}>*</Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{item.Rmk}</Typography>
        </Popover>
      </Box>}
    </BoxFR>
  )
}
const DataBox = ({ dataArr, handles }) => {

  return (
    <BoxFC sx={{ gap: 1 }} py={0.5} >
      {dataArr.map(item => (
        <DataBoxInner key={item.JobOrdId} item={item} handles={handles} />
      ))}
    </BoxFC>
  )
}

export default DataBox