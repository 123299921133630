import { Dialog, DialogContent, InputAdornment, TextField } from "@mui/material";
import { alertError } from "components/Alert";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import TextFieldWithOption from "components/TextFieldWithOption/TextFieldWithOption";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

const AddDataDialog = ({ dialogOpen, setDialogOpen, confirmSelect, row }) => {

  const { msData } = useContext(GlobalStateContext)
  const [dxAmnt, setDXAmnt] = useState(0)
  const [dxRmk, setDXRmk] = useState("")
  const [qty, setQty] = useState(1)
  const handleOk = useCallback(() => {
    if(!qty){
      alertError("กรุณาระบุจำนวนใบงาน")
      return
    }
    confirmSelect({ ...row, Qty: qty, DXAmnt: dxAmnt, DXRmk: dxRmk })
    setDialogOpen(false)
  }, [row, dxAmnt, confirmSelect, setDialogOpen, dxRmk, qty])

  useEffect(() => {
    if (!dialogOpen) {
      setDXAmnt(0)
      setQty(1)
      setDXRmk("")
    }
  }, [dialogOpen])
  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="จ่ายใบงาน"
      />
      <DialogContent>
        <BoxFC mt={2}>
          <BoxFR>

            <TextField size="small" variant="outlined" type="number" label="จำนวนใบงาน" 
              sx={{ width: 100 }} inputProps={{ style: { textAlign: 'center' } }}
              value={qty} onChange={(e) => setQty(+e.target.value)} />
            <TextField size="small" variant="outlined"
              label="เงินแนบใบงาน" type="number"
              value={dxAmnt} onChange={e => setDXAmnt(e.target.value)}
              autoFocus
              onFocus={(e) => e.target.select()}
              sx={{ width: 110 }}
              inputProps={{ style: { textAlign: 'right' } }}
              InputProps={{ endAdornment: <InputAdornment position="end">บาท</InputAdornment> }} />

            <TextFieldWithOption
              size="small" label="หมายเหตุ" value={dxRmk} sx={{ flex: 1 }}
              setValue={(value) => setDXRmk(value)}
              options={msData.serverData.PreText.DailyExpense} />
          </BoxFR>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleOk} />
    </Dialog>
  )
}

export default React.memo(AddDataDialog)