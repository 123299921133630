import React, { useCallback, useState, useMemo } from "react";
import { Dialog, DialogContent, TextField } from '@mui/material'
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import PaperComponent from "components/PaperComponent";
import { useEffect } from "react";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import dayjs from "dayjs";
import {  BoxFR } from "components/BoxCustom";
import ClearButton from "components/buttons/ClearButton";

const EditContStsTmDialog = ({ dialogOpen, setDialogOpen, initValue, setContStsTm }) => {

  const [value, setValue] = useState(dayjs())

  const dateTimePickerProp = useMemo(() => ({
    label: "วัน-เวลาปล่อยงาน",
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: value,
    onChange: (newValue) => { setValue(newValue) },
    renderInput: (params) => <TextField size="small"  {...params} />,
  }), [value])

  const handleSave = useCallback(() => {
    setContStsTm(value)
    setDialogOpen(false)
  }, [setContStsTm, value, setDialogOpen])

  const handleClose = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])

  const handleClearDate = useCallback(()=>{
    setValue(null);
  }, [])
  useEffect(() => {
    if (dialogOpen) {
      if (initValue) {
        setValue(initValue)
      } else {
        setValue(dayjs())
      }
    }
  }, [dialogOpen, initValue])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm' >

      <DialogHeader handleDialogClose={handleClose} title="จ่ายใบงาน" />
      <DialogContent>
        <BoxFR mt={2} justifyContent="center">
          <DateTimePickerCustomTime {...dateTimePickerProp} />
          <ClearButton onClick={handleClearDate}/>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText="ตกลง"
        handleDialogClose={handleClose}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(EditContStsTmDialog)