import dayjs from "dayjs"
import { numberFormatNoFlotingPoint, thousandFormat } from "utils"
export const monthLabels = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
export const titleProp = {
  textAlign: "center",
  variant: "overline",
  sx: { fontSize: "1rem" }
}
export const initJobByDateFilter = {
  CusId: null,
  JobId: null,
  JobSNm: null,
  DrvId: null,
  AptTmSt: null,
  AptTmEn: null
}
export const initIncomeByDateFilter = {
  CusId: null,
  JobId: null,
  JobSNm: null,
  AptTmSt: null,
  AptTmEn: null
}

export const initSubcontractFilterData = {
  CusId: null,
  JobId: null,
  JobSNm: null,
  AptTmSt: null,
  AptTmEn: null
}

export const initOverDueFilterData = {
  NumDay: 0,
  InvDteSt: dayjs().add(-1, "year").format("YYYY-MM-DD"),
  InvDteEn: dayjs().format("YYYY-MM-DD"),
  CusId: null,
  JobId: null
}

export const initChartData = {
  labels: [],
  datasets: []
}

export const initPieData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: []
    }
  ]
}

export const initJobByDateChartData = {
  jobByDateData: initChartData,
  sumYearData: initChartData
}
export const initIncomeByDateChartData = {
  incomeByDateData: initChartData
}
export const initSubcontractChartData = {
  subcontractByDate: { ...initChartData },
  driverByDate: { ...initChartData },
  subcontractByJob: { ...initChartData },
  subcontractBySub: { ...initChartData }
}

export const initCusSummaryData = {
  customerData: {

  },
  allTimeData: {
    Num: 0,
    MinAptTm: null,
    MaxAptTm: null,
    NumJob: [],
    IncNotTC: [],
    InvNotPayData: []

  },
  oneYearData: {
    DteSt: null,
    DteEn: null,
    NumJobOrd: 0,
    Total: 0.00,
    OwnAmnt: 0.00,
    OtherAmnt: 0.00,
    CusAmnt: 0.00,
    NumSubJobs: 0,
    ConstSizes: [],
    TlrTyps: [],
    ShpmTyps: [],
    JobWeekDayData: [],
    JobHourData: [],
    IncNotTC: [],
    NumJob: []
  },
  twoYearsData: {
    DteSt: null,
    DteEn: null,
    Data: []
  },
  fiveYearsData: {
    DteSt: null,
    DteEn: null,
    Data: []
  },
  jobWeekDayData: [],
  jobHourData: [],
  invNotPayData: []
}


export const initOneYearChartData = {
  shpmTypData: initChartData,
  contSizeData: initChartData,
  tlrTypData: initChartData,
  jobWeekDayData: initChartData,
  jobHourData: initChartData
}

export const initTwoYearChartData = {
  jobOrderData: initChartData,
  incomeData: initChartData,
  fiveYearsJobOrderData: initChartData,
  fiveYearsIncomeData: initChartData
}
export const barChartOption = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 25
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Container Size"
      }
    },
    y: {
      title: {
        display: true,
        text: "Hour(s)"
      }
    }
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
      labels: {
        padding: 20,
      }
    },
    datalabels: {
      display: true,
      formatter: function (value, context) {
        return `${value} Hrs.`
      },
      labels: {
        title: {
          font: {
            weight: 'bold'
          }
        }
      },
      anchor: "end",
      align: 'end',
      offset: -4
    }
  }
}

export const lineChartOption = {
  layout: {
    padding: {
      top: 25
    }
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        padding: 20,
      }
    },
    datalabels: {
      formatter: function (value, context) {
        return numberFormatNoFlotingPoint(value)
      },
      display: "auto",
      labels: {
        title: {
          font: {
            weight: 'bold'
          }
        }
      },
      anchor: "center",
      align: 'center'
    }
  }
}
export const incomeLineChartOption = {
  ...lineChartOption,
  plugins: {
    ...lineChartOption.plugins,
    legend: {
      position: 'right',
      labels: {
        padding: 20,
      }
    },
    datalabels: {
      formatter: function (value, context) {
        return thousandFormat(value)
      },
      display: "auto",
      anchor: "center",
      align: 'top'
    }
  }
}
export const incomeLineChartOptionNoLegend = {
  ...lineChartOption,
  plugins: {
    ...lineChartOption.plugins,
    legend: { display: false },
    datalabels: {
      formatter: function (value, context) {
        return thousandFormat(value)
      },
      display: "auto",
      anchor: "center",
      align: 'top'
    }
  }
}
export const lineChartOptionNoLegend = {
  ...lineChartOption,
  plugins: { legend: { display: false } }
}
const datalabels = {
  display: "auto",
  labels: {
    title: {
      font: {
        weight: 'bold'
      }
    }
  },
  anchor: "center",
  align: 'center'
}
const datalabelsWihtLabel = {
  ...datalabels,
  formatter: function (value, context) {
    return value === 0 ? "" : `${context.chart.data.labels[context.dataIndex]}: ${value}`
  },
  textAlign: "center"
}

export const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "right"
    },
    datalabels: datalabels
  }
}
export const pieChartOptionsWihtLabel = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "right"
    },
    datalabels: datalabelsWihtLabel
  }
}

export const lateChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "right"
    },
    datalabels: {
      ...datalabels,
      formatter: function (value, ctx) {
        return value === 0 ? "" : `${ctx.chart.data.labels[ctx.dataIndex]}`
      },
      textAlign: "center"
    }
  }
}