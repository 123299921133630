import React from "react";
import { Button } from '@mui/material'
import BackspaceIcon from '@mui/icons-material/Backspace';


const ClearButton = ({ onClick, label, sx }) => {

    return (
        <Button
            variant="contained"
            startIcon={<BackspaceIcon />}
            color="secondary"
            onClick={onClick}
            sx={{ minWidth: 100, ...sx }}>
            {label || "ล้างค่า"}
        </Button>
    )
}

export default ClearButton