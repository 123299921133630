

import { BoxFC, BoxFR } from "components/BoxCustom";
import HeaderDataText from "components/HeaderDataText/HeaderDataText";
import dayjs from "dayjs";

import React from "react";
import { getPOIPlace } from "utils/getPOIPlace";

const Shore = ({ joData, msData }) => {
  return (
    <BoxFC sx={{ gap: 1 }}>
      <BoxFR>
        <HeaderDataText header="วันที่จ่ายชอร์:" text={joData.Shore?.ShorDte && dayjs(joData.Shore.ShorDte).format("DD/MM/YYYY")} />
        <HeaderDataText header="เลขที่ใบเสร็จ:" text={joData.Shore?.RcptNo} />
      </BoxFR>
      <BoxFR>
        <HeaderDataText header="ท่าคืน:" text={joData.Shore && getPOIPlace(joData.Shore, "RTN")} />
        <HeaderDataText header="ชื่อเรือ:" text={joData.Shore?.Vssl && `${joData.Shore?.Vssl} V.${joData.Shore?.Voy}`} />
      </BoxFR>
    </BoxFC>
  )
}

export default React.memo(Shore)