import { Box } from "@mui/system"
import { styled } from '@mui/material/styles';

const BoxFR = styled(Box, {skipSx:false})(({theme})=>({
    display: 'flex',
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
}))

export default BoxFR