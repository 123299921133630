import dayjs from "dayjs"

export const clearFilterData = {
  CusId: null,
  JobId: null,
  JobNo: null,
  Bkg: null,
  ContNo: null,
  AptTmSt: null,
  AptTmEn: null,
  InvDteSt: null,
  InvDteEn: null,
  View: "Inv"
}
export const initFilterData = {
  ...clearFilterData,
  InvDteSt: dayjs().format("YYYY-MM-DD 00:00:00.000"),
  InvDteEn: dayjs().format("YYYY-MM-DD 23:59:59.997")
}