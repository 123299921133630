
import {  jobOrderColNm } from "./jobOrderConstant"

export const billingNoteApiNm = {
  getBillingNote: "/billing-note/getBillingNote",
  getBillingNoteTable: "/billing-note/getBillingNoteTable",
  generateBillingNoteNo: "/billing-note/generateBillingNoteNo",
  insertBillingNote: "/billing-note/insertBillingNote",
  insertBillingNoteWithInvoice: "/billing-note/insertBillingNoteWithInvoice",
  updateBillingNote: "/billing-note/updateBillingNote",
  updateBillingNoteColumn: "/billing-note/updateBillingNoteColumn",
  deleteBillingNote: "/billing-note/deleteBillingNote"
}


export const billingNoteColNm =  {
  BNId: "รหัสใบวางบิล",
  BNDte: "วันที่ใบวางบิล",
  RecvPayDte: "วันที่รับเงิน",
  BNNo: "เลขที่ใบวางบิล",
  Rmk: "หมายเหตุ",
  OwnRcptAmnt: "ใบเสร็จบริษัท",
  CusRcptAmnt: "ใบเสร็จลูกค้า",
  CusSNm: jobOrderColNm.CusSNm,
  BillNm: "ชื่อลูกค้า",
  BillAddr: "ที่อยู่",
  BillTaxId: "เลขประจำตัวผู้เสียภาษี",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}