import { BoxFR } from "components/BoxCustom";
import React, { useContext, useEffect, useState } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaidIcon from '@mui/icons-material/Paid';
import { CircularProgress, Typography } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import { Box } from "@mui/system";
import DataItem from "./DataItem";
import DataItem2 from "./DataItem2";
import { invoiceApiNm } from "constants/invoiceConstant";
import dayjs from "dayjs";

const TmrwBox = () => (
  <>
    <CalendarTodayIcon sx={{ fontSize: 28 }} />
    <Typography position="absolute" top={16} left={14} variant="subtitle2" fontSize={12} fontWeight="bold">+1</Typography>
  </>
)
const TargetFinance = () => {
  const { ax, targetTimestamp } = useContext(GlobalStateContext)

  const [targetData, setTargetData] = useState({
    isShowLoading: false,
    numTodayJob: 0,
    numTmrwJob: 0,
    numInvNoRv: 0,
    numAllFinJobOrd: 0,
    numFinJobOrdInv: 0,
  })


  useEffect(() => {
    const getData = () => {
      setTargetData(o => ({ ...o, isShowLoading: true }))
      ax.post(invoiceApiNm.getInvoiceTarget, {}, false).then(value => {
        if (value.data) {
          setTargetData({
            numTodayJob: +value.data.todayJob,
            numTmrwJob: +value.data.tmrwJob,
            numInvNoRv: +value.data.invNoRV,
            numAllFinJobOrd: +value.data.allFinJobOrd,
            numFinJobOrdInv: +value.data.finJobOrdInv,
            isShowLoading: false
          })
        } else {
          setTargetData(o => ({ ...o, isShowLoading: false }))
        }
      })
    }

    getData()
    const id = setInterval(getData, 1000 * 60 * 5)
    console.log("targetTimeStamp::", targetTimestamp)
    return () => clearInterval(id);
  }, [ax, targetTimestamp])
  return (
    <BoxFR justifyContent="center" sx={{ gap: 2, overflow: "hidden", flex: "1 100 0" }}>
      <DataItem2 title="งานทั้งหมดวันนี้"
        link={`/emily/DailyJob/AptTm/${dayjs().format("YYYY-MM-DD_00:00:00.000")}_${dayjs().format("YYYY-MM-DD_23:59:59.997")}`}
        header={<GpsFixedIcon />}
        data={`${targetData.numTodayJob}`} />

      <DataItem title="งานจบครบวางบิลแล้ว (จำนวนใบงาน)"
        value={targetData.numAllFinJobOrd === 0 ? 0 : targetData.numFinJobOrdInv / targetData.numAllFinJobOrd * 100}
        header={<DescriptionIcon />}
        data={`${targetData.numFinJobOrdInv}/${targetData.numAllFinJobOrd}`} />
      <DataItem2 title="วางบิล 30 วันแล้วยังไม่ได้รับเงิน"
        header={<PaidIcon />}
        data={`${targetData.numInvNoRv}`} />
      <DataItem2 title="งานพรุ่งนี้"
        link={`/emily/DailyJob/AptTm/${dayjs().add(1, "day").format("YYYY-MM-DD_00:00:00.000")}_${dayjs().add(1, "day").format("YYYY-MM-DD_23:59:59.997")}`}
        header={<TmrwBox />}
        data={`${targetData.numTmrwJob}`} />
      <Box width={30} display="flex" >
        {targetData.isShowLoading && <CircularProgress size={30} color="inherit" />}
      </Box>
    </BoxFR>
  )
}

export default TargetFinance