import { Switch, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
// import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback } from "react";
// import { useContext } from "react";

const NotificationContent = ({ notificationData, setNotificationData }) => {

  // const {ax} = useContext(GlobalStateContext);

  const handleChange = useCallback((e)=>{
    setNotificationData(o => ({ ...o, ContNo: e.target.checked?1:0 }))
  }, [ setNotificationData])

  return (
    <BoxFC>
      <Typography variant="h6">การรับการแจ้งเตือน</Typography>
      <BoxFC sx={{ gap: 0 }} pl={2}>
        <BoxFR >
          <Typography sx={{ width: 200 }}>การแจ้งเบอร์ตู้</Typography>
          <Switch checked={notificationData.ContNo===1?true:false} onChange={handleChange} />
        </BoxFR>
      </BoxFC>
    </BoxFC>
  )
}

export default React.memo(NotificationContent)
