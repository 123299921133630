import { Button, ButtonGroup, Checkbox, ClickAwayListener, IconButton, Paper, TextField, Tooltip, Grow, Popper } from "@mui/material";
import { Box } from "@mui/system";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback } from "react";
import ComboBox from "components/ComboBox";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import dayjs from "dayjs";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useState, useRef } from "react";
import CheckobxFormControl from "components/CheckobxFormControl";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { red } from "@mui/material/colors";
import TextFieldWithOption from "components/TextFieldWithOption/TextFieldWithOption";
import { alertError } from "components/Alert";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";

export const modifyExpenseItem = (dailyExpenses) =>{
  return dailyExpenses.map(item => ({
    ...item,
    payType: item.Amnt < 0 ? "OUT" : item.Amnt > 0 ? "IN" : null,
    Amnt: Math.abs(+item.Amnt?.replace(".00", "")|| 0)
  }));
}
export const initExpenseObj = {
  DalyExpId: 0,
  DrvId: null,
  Amnt: "0",
  IsCash: 1,
  ExpDte: dayjs(),
  Rmk: "",
}

const calcPayTypeClr = (type) => {
  if (type === "OUT") return "MistyRose"
  else if (type === "IN") return "honeyDew"
  else return null
}

const ExpenseItem = ({ item, index, jobOrdId, setExpenseData, msData, user }) => {

  console.log("render ExpenseItem item::", item)

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [amount, setAmount] = useState("");
  const [isWithdraw, setIsWithDraw] = useState(false);

  const setExpenseDataNewItem = useCallback(() => {
    setExpenseData(o => {
      o[index] = { ...item }
      return [...o]
    })
  }, [setExpenseData, index, item])

  const setPayType = useCallback((type) => {
    item.payType = type
    setExpenseDataNewItem()
  }, [item, setExpenseDataNewItem])

  const setValue = useCallback((name) => (e) => {
    item[name] = e.target.value;
    setExpenseDataNewItem()
  }, [setExpenseDataNewItem, item])

  const datePickerProp = useCallback((name, label) => ({
    label: label,
    inputFormat: "DD/MM HH:mm",
    mask: "__/__ __:__",
    value: item[name],
    onChange: (newValue) => { item[name] = newValue; setExpenseDataNewItem() },
    renderInput: (params) => <TextField size="small"  {...params} sx={{ width: 135 }} />,
  }), [item, setExpenseDataNewItem])

  const buttonPayTypeProps = useCallback((type, color) => ({
    variant: type === item.payType ? "contained" : "outlined",
    sx: {
      bgcolor: type === item.payType ? color : "white",
      color: "primary.main",
      px: 0.5,
      minWidth: 0
    },
    onClick: () => setPayType(type)
  }), [item, setPayType])

  const buttonIsCashProps = useCallback((isCash) => ({
    variant: item.IsCash===isCash?"contained": "outlined", 
    sx: {px: 1, minWidth: 0},
    onClick: () => {item.IsCash = isCash; setExpenseDataNewItem()}
  }), [item,  setExpenseDataNewItem])

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, [anchorRef])
  const buttonAddProps = useCallback(() => ({
    variant: "contained",
    sx: { minWidth: 0 },
    onClick: () => setOpen(o => !o)
  }), [])

  const handleAddItem = useCallback(() => {

    if (amount === "") {
      alertError("กรุณาใส่จำนวนก่อน")
      return
    }
    const addItem = [{
      ...initExpenseObj,
      JobOrdId: jobOrdId,
      ExpDte: dayjs(),
      ClrDte: dayjs(),
      ClrAccId: user.AccId,
      payType: "IN",
      DrvId: item.DrvId,
      Amnt: amount,
      Rmk: "เงินทอน"
    }]
    if (isWithdraw) {
      addItem.push({
        ...initExpenseObj,
        JobOrdId: jobOrdId,
        ExpDte: dayjs(),
        ClrDte: dayjs(),
        ClrAccId: user.AccId,
        payType: "OUT",
        DrvId: item.DrvId,
        Amnt: amount,
        Rmk: "เงินทอนลงเบิก"
      })
    }
    item.ClrDte = dayjs()
    setExpenseData(o => ([...addItem, ...o]))
    setOpen(false)
  }, [amount, isWithdraw, item, jobOrdId, setExpenseData, user.AccId])

  console.log("render ExpenseItem item::", item)
  return (
    <BoxFC mt={1} px={0} >
      <BoxFR px={0} >
        <Box width={20}>
          {item.DalyExpId > 0 && item.ClrDte === null &&
            <Tooltip title="ยังไม่ Clear รายได้">
              <NewReleasesIcon sx={{ color: red[500] }} />
            </Tooltip>
          }
        </Box>
        <DateTimePickerCustomTime {...datePickerProp("ExpDte", "วันที่")} />
        <ButtonGroup variant="outlined" >
          <Button {...buttonPayTypeProps("IN", "honeydew")}>รับ</Button>
          <Button {...buttonPayTypeProps("OUT", "MistyRose")}>จ่าย</Button>
        </ButtonGroup>
        <ButtonGroup variant="outlined" >
          <Button {...buttonIsCashProps(1)}>สด</Button>
          <Button {...buttonIsCashProps(0)}>โอน</Button>
        </ButtonGroup>
        <ComboBox sx={{ width: 110 }} sxInput={{ p: 0 }} options={msData.driverCombo} required={false}
          selectedId={item.DrvId}
          setSelectedId={(id) => {
            item.DrvId = id;
            setExpenseDataNewItem()
            //setExpenseData(oldData => [...oldData])
          }}
        />

        <TextField size="small" value={item.Amnt}
          sx={{ width: 70, backgroundColor: calcPayTypeClr(item.payType) }}
          inputProps={{ style: { textAlign: "right" } }} label="จำนวน"
          type="number"
          onChange={setValue("Amnt")} />

        <TextFieldWithOption
          size="small" label="หมายเหตุ" value={item.Rmk || ""} sx={{ flex: 1 }}
          setValue={(value) => {
            item.Rmk = value;
            setExpenseDataNewItem()
          }}
          options={msData.serverData.PreText.DailyExpense || []} />
        {/* <TextField size="small" label="หมายเหตุ" value={item.Rmk || ""} onChange={setValue("Rmk")} sx={{ flex: 1 }} /> */}
        {item.DalyExpId === 0 ?
          <IconButton sx={{ p: 0 }} onClick={() => {
            console.log("ondelete index::", index)
            setExpenseData(o => {
              /**
               * cannot use  [...o.splice(index, 1)]  directly coz 
               * o.splice(index, 1) will return obj that removed not old obj
               */
              o.splice(index, 1);
              return [...o]
            })
          }} >
            <DeleteRoundedIcon />
          </IconButton>
          :
          <BoxFR>
            <Button {...buttonAddProps()} ref={anchorRef}>ทอน</Button>
            <Popper
              sx={{
                zIndex: 1001,
              }}
              open={open}
              anchorEl={anchorRef.current}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper sx={{ p: 0, borderRadius: 2 }} elevation={6}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <BoxFC sx={{ gap: 1, px: 4, py: 2, backgroundColor: "white", borderRadius: 2 }}>
                        <TextField variant="outlined" type="number" label="จำนวน"
                          value={amount} onChange={(e) => setAmount(+e.target.value)}
                          sx={{ width: 130 }} size="small"
                          inputProps={{ style: { textAlign: "right" } }} />
                        <CheckobxFormControl label="เงินทอนลงเบิก" onChange={(e) => setIsWithDraw(e.target.checked)} checked={isWithdraw} />
                        <Button variant="contained" onClick={() => handleAddItem()}>เพิ่มรายการ</Button>
                      </BoxFC>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            {/* <Button {...buttonAddProps("OUT", item.DrvId, "ลงเบิก")}>ลงเบิก</Button> */}
            <Tooltip title="เคลียรายได้">
              <Checkbox size="medium" sx={{ p: 0 }}
                checked={item.ClrDte != null}
                icon={<PlaylistAddCheckCircleIcon color="disable" />}
                checkedIcon={< PlaylistAddCheckCircleIcon color="success" />}
                onChange={(e) => {
                  e.target.checked ? item.ClrDte = dayjs() : item.ClrDte = null;
                  item.ClrAccId = user.AccId;
                  setExpenseDataNewItem()
                }}
              />
            </Tooltip>
            <DateTimePickerCustomTime {...datePickerProp("ClrDte", "วันที่ Clear")} />
          </BoxFR>
        }
      </BoxFR>
    </BoxFC>
  )
}

export default React.memo(ExpenseItem)