import React from "react";
import { Button, ButtonGroup, LinearProgress, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";

const DataItem = ({ title, header, value, data }) => {
  return (
    <Tooltip title={title} followCursor>
      <ButtonGroup >
        <Button variant="contained" color="secondary" sx={{ p: 1 }}>
          {header}
        </Button>
        {value !== null &&
          <Button variant="outlined" color="secondary" disabled sx={{ p: 1, bgcolor: grey[200] }}>
            <LinearProgress sx={{ width: 100, height: 15, borderRadius: 1.5 }} variant="determinate" color="secondary" value={value} />
          </Button>
        }
        <Button variant={value !== null ? "contained" : "outlined"} color="secondary" sx={{ p: 1, bgcolor: value === null && "white" }}>
          {data}
        </Button>
      </ButtonGroup>
    </Tooltip>
  )
}

export default DataItem