import axios from 'axios';
import Swal from 'sweetalert2'
import { G_QUERY_LIMIT } from "constants/serverConstant"
// const baseURL = 'https://192.168.10.254:3000/'
// const baseURL = 'https://amo-test.klickerlab.com:9051/'


class ApiService {
  constructor(setIsLoading) {
    console.log(`--in ApiService constructor--`)
    this.setIsLoading = setIsLoading;

    this.ax = axios.create()
    this.ax.interceptors.request.use((request) => {
      request.headers.Authorization = "xxx";
      return request;
    })
    this.ax.interceptors.response.use(
      (response) => {
        console.log(`<-----<<${response.config.url}<<::`, response.data);
        if (response.headers['content-disposition']) {
          this.setIsLoading(false)
          return {
            name: response.headers['content-disposition'],
            data: response.data
          };
        }
        if (response.data.error && (response.data.error.code === -1 || response.data.error.code === -100)) {
          Swal.fire({
            text: response.data.error.message,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else if (response.data.error) {
          Swal.fire({
            text: `Unknown Error: ${response.data.error.message}`,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }

        if (response.data && Array.isArray(response.data.data) && response.data.data.length === G_QUERY_LIMIT) {
          Swal.fire({
            text: `ข้อมูลถูกจำกัดที่ ${G_QUERY_LIMIT} แถว
ข้อมูลอาจจะไม่ครบถ้วน
กรุณาค้นหาด้วยตัวเลือกเพิ่มเติม`,
            icon: 'warning',
            confirmButtonText: 'OK'
          })
        }

        this.setIsLoading(false)
        return response.data;
      },
      (err) => {
        console.log("Api error::", err);
        console.log("Api error::", err.response);
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              text: "Session หมดอายุกรุณา Login ใหม่",
              icon: 'error',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/";
              }
            })
          } else if (err.response.status === 500) {
            Swal.fire({
              text: "Emily ไม่สบายเรียกหมอด่วน!!!",
              icon: 'error',
              confirmButtonText: 'OK'
            })
          } else {
            Swal.fire({
              text: err.response.statusText,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
        }
        else {
          this.setIsLoading(false)
          return Promise.reject(err);
        }
        this.setIsLoading(false)
        return Promise.reject(err);
      }
    )
  }
  post(fnName, data, showLoading = true) {
    console.log(`>----->>${fnName}>> post data::`, data)
    if (showLoading) {
      this.setIsLoading(true)
    }
    return this.ax.post(fnName, data)
  }
  get(fnName, showLoading = true) {
    if (showLoading) {
      this.setIsLoading(true)
    }
    return this.ax.get(fnName)
  }
}

export default ApiService