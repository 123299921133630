import { jobOrderColNm } from "./jobOrderConstant"

export const driverIncomeApiNm = {
  getJobDriverPay: "/driver-income/getJobDriverPay",
  getDriverIncome: "/driver-income/getDriverIncome",
  getJobDriverPayFromDailyJob: "/driver-income/getJobDriverPayFromDailyJob",
  getDriverIncomeFromDailyJob: "/driver-income/getDriverIncomeFromDailyJob",
  insertJobDriverPay: "/driver-income/insertJobDriverPay",
  insertJobDriverPayBulk: "/driver-income/insertJobDriverPayBulk",
  updateJobDriverPay: "/driver-income/updateJobDriverPay", 
  deleteJobDriverPay: "/driver-income/deleteJobDriverPay",
  insertDriverIncomeBulk: "/driver-income/insertDriverIncomeBulk",
  updateDriverIncome: "/driver-income/updateDriverIncome", 
  deleteDriverIncomeBulk: "/driver-income/deleteDriverIncomeBulk", 
  deleteDriverIncome: "/driver-income/deleteDriverIncome"
}

export const jobDriverPayColNm =  {
  CusSNm: "ลูกค้า",
  JobSNm: "งาน",
  Amnt: "ค่าเที่ยว",
  ExtAmnt: "เงินพิเศษ",
  ExtTxt: "รายละเอียดเงินพิเศษ",
  ContSize: "ขนาดตู้",
  ContTyp: "ประเภทตู้",
  TlrTypId: "ประเภทหาง",
  TlrTypNm: "ประเภทหาง",
  Prty: "Priority",
  Rmk: "หมายเหตุ",
}

export const driverIncomeColNm = {
  AptTm: "วันที่วิ่งงาน",
  JobOrdId: "เลขที่ใบงาน",
  DrvId: "พนักงานขับรถ",
  NName: "พนักงานขับรถ",
  Amnt: "ค่าเที่ยว",
  ExtAmnt: "ค่าเที่ยวพิเศษ",
  ExtTxt: "รายระเอียดค่าเที่ยวพิเศษ",
  Rmk: "หมายเหตุ",
  TakePlc: jobOrderColNm.TakePlc,
  RtnPlc: jobOrderColNm.RtnPlc,
  Loc: jobOrderColNm.Loc,
  JoAptTm: jobOrderColNm.AptTm,
  JoFinDte: jobOrderColNm.FinDte,
  JoRmk: jobOrderColNm.Rmk,
  ContSize: jobOrderColNm.ContSize,
  ContTyp: jobOrderColNm.ContTyp,
  TlrTyp: jobOrderColNm.TlrTyp,
}
