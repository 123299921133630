import { IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import SwitchFormControl from "components/SwitchFormControl";
import React from "react";
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { moveRow } from "utils";
import { Box } from "@mui/system";

const boxClrSX = {
  border: "1px solid grey", 
  width:"100%", 
  height:30, 
  borderRadius:1
}
const menuItemSX = {
  px:1, 
  py:0.5
}

const DailyTableColWidthContent = ({dailyTableColWidth, setDailyTableColWidth}) => {

  return (
    <BoxFC height="100%" sx={{ gap: 0 }}>
      <Typography variant="h6">ตารางงานประจำวัน</Typography>
      <BoxFC sx={{ gap: 1, overflow: "auto" }} pl={2} pt={2}>
        {dailyTableColWidth.map((item, index) => (
          <BoxFR key={item.field}>
            <Typography sx={{ width: 120 }}>{item.headerName}</Typography>
            <TextField label="ความกว้าง" type="number" variant="outlined" size="small" sx={{ width: 100 }}
              inputProps={{ style: { textAlign: "center" } }}
              value={item.width}
              onChange={(e) => setDailyTableColWidth(o => {
                item.width = e.target.value
                return [...o]
              })} />
            <SwitchFormControl label={item.visible ? "แสดง" : "ซ่อน"}
              sx={{width:100}}
              checked={item.visible}
              onChange={(e) => setDailyTableColWidth(o => {
                item.visible = e.target.checked
                return [...o]
              })} />
            <TextField variant="outlined" size="small" sx={{width:90}} select value={item.colClr || ""} 
              onChange={e=> setDailyTableColWidth(o=>{ 
                item.colClr = e.target.value || null
                return [...o]
              })}>
              <MenuItem sx={menuItemSX} value={null}><Box sx={{...boxClrSX, bgcolor: "white"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="whitesmoke-column-color"><Box sx={{...boxClrSX, bgcolor: "whitesmoke"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="LavenderBlush-column-color"><Box sx={{...boxClrSX, bgcolor: "LavenderBlush"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="Beige-column-color"><Box sx={{...boxClrSX, bgcolor: "Beige"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="Azure-column-color"><Box sx={{...boxClrSX, bgcolor: "Azure"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="Honeydew-column-color"><Box sx={{...boxClrSX, bgcolor: "Honeydew"}}/></MenuItem>
            </TextField>
            <IconButton sx={{ ml: 1 }} onClick={() => setDailyTableColWidth(o => moveRow(o, index, -1))}><ArrowUpwardRoundedIcon /></IconButton>
            <IconButton sx={{ ml: -3 }} onClick={() => setDailyTableColWidth(o => moveRow(o, index, 1))}><ArrowDownwardRoundedIcon /></IconButton>
          </BoxFR>
        ))}
      </BoxFC>
    </BoxFC>
  )
}

export default React.memo(DailyTableColWidthContent)