
import { Box, Button, Typography } from "@mui/material";

import { BoxFC, BoxFR } from "components/BoxCustom";

import React, { useCallback, useContext, useState } from "react";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { AddCircleRounded, SearchRounded } from "@mui/icons-material";
import { dailyExpenseColumns } from "./columns";
import DateTimeSelect from "components/DateTimeSelect";
import DailyExpenseDialog from "pages/Menu1/DailyExpense/DailyExpenseDialog";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { addIdForDataGrid } from "utils";
import { dailyExpApiNm } from "constants/dailyExpenseConstant";


let selectedId = null
// let selectedRow = null
const DailyExpenses = ({ dailyExpenseData, setDailyExpenseData, driverFullName, drvId
  , filterData, setFilterData, lastFilter, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)

  const [dialogDailyExpenseOpen, setDialogDailyExpenseOpen] = useState(false)

  const setSelectDate = useCallback((start, end) => {
    setFilterData(o => ({ ...o, ModDteSt: start, ModDteEn: end }))
  }, [setFilterData])

  const getData = useCallback((filter) => {
    ax.post(dailyExpApiNm.getDailyExpense, filter).then(value => {
      if (value.data) {
        setDailyExpenseData(addIdForDataGrid(value.data.map(item => ({ ...item, Amnt: item.Amnt * -1 })), "DalyExpId"))
      }
    })
  }, [ax, setDailyExpenseData])

  const handleAddDailyExpense = useCallback(() => {
    selectedId = null
    // selectedRow = null
    setDialogDailyExpenseOpen(true)
  }, [])

  const handleRowDoubleClick = useCallback((params) => {
    selectedId = params.id
    // selectedRow = params.row
    setDialogDailyExpenseOpen(true)
  }, [])

  const onDailyExpenseDialogFinish = useCallback((data) => {
    console.log("onDailyExpenseDialogFinish", data)
    /**
     * check expense data that has been modified
     * then update driverExpenseData
     * 
     * do only first row (Order by ModDte Desc) coz it's the latest update data
     * 
     * to prevent multiple update at same time have to check all row but that rarely case
     */


    //find if selectedRow has been deleted

    // setDriverExpenseData(oDriverExpData => {
    //   const result = oDriverExpData.map(item => ({ ...item }))
    //   if (!selectedRow) {// from add button just add it to driverExpenseData
    //     if (data && data.length > 0 && !data[0].ClrDte) {
    //       result.push({ ...data[0], Amnt: +data[0].Amnt * -1 })
    //     }
    //     return addIdForDataGrid(result, "DalyExpId")
    //   }

    //   const found = data.find(dalyExp => dalyExp.DalyExpId === selectedRow.DalyExpId)
    //   if (!found) { // item has been deleted
    //     //find data in driverExpenseData
    //     const index = result.findIndex(drvExp => drvExp.DalyExpId === selectedRow.DalyExpId)
    //     if (index !== -1) {
    //       result.splice(index, 1)
    //     }
    //   } else {//item not deleted find data[0] found in driverExpenseData
    //     if (data && data.length > 0) {
    //       const drvExpIndex = result.findIndex(drvExp => drvExp.DalyExpId === data[0].DalyExpId)
    //       if (drvExpIndex !== -1) { //found in driverExpenseData
    //         if (data[0].ClrDte) { //if data[0] has been cleared then remove it from driverExpenseData
    //           result.splice(drvExpIndex, 1)
    //         } else {// if data[0] not cleared then update it in driverExpenseData
    //           result[drvExpIndex] = { ...data[0], Amnt: +data[0].Amnt * -1 }
    //         }
    //       } else {// not found can be case updated data had cleard date 
    //         if (!data[0].ClrDte) { // if new data not cleared then add it to driverExpenseData
    //           result.push({ ...data[0], Amnt: +data[0].Amnt * -1 })
    //         }
    //       }
    //     }
    //   }
    //   return addIdForDataGrid(result, "DalyExpId")
    // })
    // setDailyExpenseData(addIdForDataGrid(data.map(item => ({ ...item, Amnt: +item.Amnt * -1 })), "DalyExpId"))

    onFinish(data)
    setDialogDailyExpenseOpen(false)
  }, [onFinish])

  // useEffect(() => {
  //   if (dialogOpen) {
  //     lastFilter = initFilterData
  //   }
  // }, [initFilterData, dialogOpen])

  console.log("in render DailyExpenses dailyExpenseData", dailyExpenseData)
  return (
    <BoxFC sx={{ gap: 1 }} height="100%">
      <BoxFR>
        <Typography variant="h6" >รับ-จ่ายเงิน </Typography>
        <Button size="small" variant="contained" onClick={handleAddDailyExpense} sx={{ minWidth: 0, px: 1 }}>
          <AddCircleRounded />
        </Button>
        <Box sx={{ flex: 1 }} />

        <DateTimeSelect
          sx={{ width: 160 }}
          label="วันที่ทำรายการ"
          start={filterData.ModDteSt}
          end={filterData.ModDteEn}
          setSelectDate={setSelectDate}
          getData={() => getData(filterData)}
        />
        <Button size="small" variant="contained" onClick={() => getData(filterData)} sx={{ minWidth: 0, px: 1 }}>
          <SearchRounded />
        </Button>
        <Box flex={1} />
        <Typography variant="body">{driverFullName}</Typography>
      </BoxFR>
      <Box width="100%" height="100%">
        <DataGridCellExpand
          density="compact"
          hideToolbar
          hideFooter
          rows={dailyExpenseData}
          columns={dailyExpenseColumns}
          onRowDoubleClick={handleRowDoubleClick}
        />
      </Box>
      <DailyExpenseDialog
        dialogOpen={dialogDailyExpenseOpen}
        setDialogOpen={setDialogDailyExpenseOpen}
        onFinish={onDailyExpenseDialogFinish}
        {...{ selectedId, lastFilter, selectedDrvId: drvId }} />
    </BoxFC>
  )
}

export default React.memo(DailyExpenses)