import { isSamePlace } from "utils/isSamePlce"


export const addInArray = (arr, data) => {
  const found = arr.find(item => data.ShpmId === item.ShpmId
    // && data.TakePlc === item.TakePlc
    // && data.RtnPlc === item.RtnPlc
    // && data.Loc === item.Loc
    && isSamePlace(data, item)
    && data.ContSizeFull === item.ContSizeFull)
  if (found) {
    found.JobOrdIds.push(data.JobOrdId)
  } else {
    data.No = arr.length + 1
    data.id = data.JobOrdId
    data.JobOrdIds = [data.JobOrdId]
    arr.push(data)
  }
}

export const checkAndAddInArray = (arr, data, tagId) => {
  const tagIds = data.TagIds ? data.TagIds.split(",").map(item => +item) : []
  if (tagIds.includes(tagId)) {
    data.TagId = tagId
    addInArray(arr, data)
    return true
  } else {
    return false
  }
}