export const leftRightComboKeyUp = (e, comboArray, name, setFilterData, getData)=>{
  if(e.key === "ArrowLeft"){
    setFilterData(o => {
      const foundIndex = comboArray.findIndex(item=>item.id === o[name])
      if(foundIndex === 0){
        return o
      }
      const newData =  { ...o, [name]: comboArray[foundIndex -1].id }
      getData(newData)
      return newData
    })
  } else if(e.key === "ArrowRight"){
    setFilterData(o => {
      const foundIndex = comboArray.findIndex(item=>item.id === o[name])
      if(foundIndex === comboArray.length -1){
        return o
      }
      const newData =  { ...o, [name]: comboArray[foundIndex +1].id }
      getData(newData)
      return newData
    })
  } 
}
//msData.driverOnlyCombo