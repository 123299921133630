export const noYearColNms = [
  "AptTm",
  "ArvTm",
  "LevTm",
  "TakeDteSt",
  "TakeDteEn",
  "RtnDteSt",
  "RtnDteEn",
  "ContDoc",
  "ExpDte",
  "FinDte",
  "ModDte",
  "AddTm",
  "FuelTm",
  "MemTm"
]