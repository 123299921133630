

import { Button, MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { branchApiNm } from "branch/constant/branchApiNm";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ClearButton from "components/buttons/ClearButton";
import SearchButton from "components/buttons/SearchButton";
import ComboBox from "components/ComboBox";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import DateTimeSelect from "components/DateTimeSelect";
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import { shipmentColNm } from "constants/shipmentConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import { processConstStatus } from "pages/Menu1/DailyJob/processContStatus"
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { addIdForDataGrid } from "utils";
import { columns } from "./columns";
import { clearFilterData } from "./initData";
import JobOrderDriverIncomeDialog from "./JobOrderDriverIncomeDialog";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import DriverIncomeReportDialog from "./DriverIncomeReportDialog";
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import DriverIncomeShipmentDialog from "./DriverIncomeShipmentDialog";
import UpdateContainerDialog from "./UpdateContainerDialog";
import UpdateTextDialog from "pages/Menu1/DailyJob/UpdateTextDialog";
import UpdateDateTimeDialog from "pages/Menu1/DailyJob/UpdateDateTimeDialog";
import UpdateDriverDialog from "pages/Menu1/DailyJob/UpdateDriverDialog";
import UpdateContSizeDialog from "pages/Menu1/DailyJob/UpdateContSizeDialog";
import { useMemo } from "react";
import DailyExpenseReportDialog from "./DailyExpenseReportDialog";
import { PersonSearchRounded } from "@mui/icons-material";
import JobOrderTransferDialog from "pages/Menu1/DailyJob/JobOrderTransferDialog";

// let selectedJobOrdId = 0
let lastFilter = null
let selectedCol = "";
let selectedData = 0;
let selectedRow = null;
const JobOrderDriverIncome = () => {

  const [selectedId, setSelectedId] = useState(0)
  const { ax, msData } = useContext(GlobalStateContext)
  const [filterData, setFilterData] = useState({ ...clearFilterData })
  const [jobCombo, setJobCombo] = useState(msData.jobCombo)
  const [dataTable, setDataTable] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogReportOpen, setDialogReportOpen] = useState(false)
  const [dialogDXReportOpen, setDialogDXReportOpen] = useState(false)
  const [dialogContainerOpen, setDialogContainerOpen] = useState(false);
  const [dialogTextOpen, setDialogTextOpen] = useState(false);
  const [dialogDateTimeOpen, setDialogDateTimeOpen] = useState(false);
  const [dialogDriverOpen, setDialogDriverOpen] = useState(false)
  const [dialogContSizeOpen, setDialogContSizeOpen] = useState(false)
  const [dialogDrvIncShpmOpen, setDialogDrvIncShpmOpen] = useState(false)
  const [dialogJobOrderTransferOpen, setDialogJobOrderTransferOpen] = useState(false)
  const [drvIdJobOrder, setDrvIdJobOrder] = useState(null)

  const selectedJobOrdIds = useMemo(() => {
    return selectionModel.length > 0 && selectionModel.includes(selectedId) ? selectionModel : [selectedId]
  }, [selectionModel, selectedId])

  const columnsMemo = useMemo(()=>columns(msData.serverData.RefColumnName?.job_order?.Ref2 || "Reference"), [msData.serverData])
  
  const onSearchButtonClick = useCallback((filter) => {
    ax.post(branchApiNm.getDailyJobWithDriverIncome, filter).then(value => {
      if (value.data) {
        processConstStatus(value.data, msData.containerStatuses);
        setDataTable(addIdForDataGrid(value.data, "JobOrdId"))
        lastFilter = { ...filter }
        if (filter.JobOrdId && value.data.length > 0) {
          setSelectedId(filter.JobOrdId)
          // selectedJobOrdId = filter.JobOrdId;
          setDialogOpen(true)
        }
      }
    })
  }, [ax, msData])

  const filterProps = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    sx: { width: 150 },
    label: jobOrderColNm[name],
    value: filterData[name] || "",
    onKeyUp: e => { if (e.code === "Enter" || e.code === "NumpadEnter") { onSearchButtonClick(filterData) } },
    onChange: (e) => { setFilterData(oldState => ({ ...oldState, [name]: e.target.value || null })) }
  }), [filterData, onSearchButtonClick])

  const setSelectDate = useCallback((start, end) => {
    setFilterData(oldState => ({
      ...oldState,
      AptTmSt: start,
      AptTmEn: end
    }))
  }, [])


  const handleCusSelect = useCallback((id) => {
    console.log("cusID::", id);
    setFilterData(oldState => ({ ...oldState, CusId: id, JobId: null }));
    if (id) {
      setJobCombo(msData.jobCombo.filter(item => item.refId === id));
    } else {
      setJobCombo(msData.jobCombo);
    }
  }, [msData])


  const handleJobOrdIdKeyPress = useCallback((e) => {
    if (e.keyCode === 13) {
      onSearchButtonClick({ JobOrdId: filterData.JobOrdId })
    }
  }, [filterData.JobOrdId, onSearchButtonClick])

  const handleCellClick = useCallback((params) => {
    console.log("params.field::", params.field)
    setSelectedId(params.row.JobOrdId)
    selectedCol = params.field;
    selectedData = params.value;
    selectedRow = params.row;

    if (params.field === "JobOrdId") {
      // selectedJobOrdId = params.value
      setDialogOpen(true)
    } else if (["ContNo", "SealNo", "TareWt", "FullWt"].includes(params.field)) {
      setDialogContainerOpen(true);
    } else if (["PlnTxt", "TakePlc", "RtnPlc", "Loc", "Rmk", "JoRef1", "JoRef2"].includes(params.field)) {
      setDialogTextOpen(true);
    } else if (["AptTm", "TakeDteSt", "TakeDteEn", "RtnDteSt", "ArvTm", "LevTm", "RtnDteEn", "FinDte"].includes(params.field)) {
      setDialogDateTimeOpen(true);
    } else if (params.field === 'JobNo' || params.field === 'Bkg') {
      console.log("dataTable ::", dataTable)
      let sameShipment = dataTable.filter(item => item.ShpmId === params.row.ShpmId).map(item => item.JobOrdId)
      console.log("sameShipment ::", sameShipment)
      console.log("selectionModel ::", selectionModel)
      console.log("params.row.JobOrdId ::", params.row.JobOrdId)
      if (selectionModel.includes(params.row.JobOrdId)) {
        setSelectionModel(oldData => {
          return oldData.filter(item => !sameShipment.includes(item))
        })
      } else {
        setSelectionModel(oldData => [...oldData, ...sameShipment])
      }
    } else if (["ContSizeFull", "TlrTyp", "ISOCd"].includes(params.field)) {
      setDialogContSizeOpen(true)
    } else if (params.field === "ContStses") {
      setDialogDriverOpen(true)
    }
  }, [selectionModel, dataTable])

  const handleDriverIncomeReportClick = useCallback(() => {
    setDialogReportOpen(true)
  }, [])

  const handleDailyExpenseReportClick = useCallback(() => {
    setDialogDXReportOpen(true)
  }, [])

  const handleDriverIncomeShipmentClick = useCallback(() => {
    setDialogDrvIncShpmOpen(true)
  }, [])


  const updateColumn = useCallback(async (colName, data, jobOrdIds, withFamily) => {
    // console.log("in updateColumn jobOrdIds::", jobOrdIds)
    // console.log("in updateColumn data::", data)

    const jsondata = {
      JobOrdIds: jobOrdIds,
      ColValues: data,
      WithFamily: withFamily,
      LogRmk: `แก้ไขข้อมูล ${jobOrderColNm[colName] || colName}`
    }
    await ax.post(jobOrderApiNm.updateJobOrderColumn, jsondata)
    onSearchButtonClick(lastFilter);

  }, [ax, onSearchButtonClick])

  const updateText = useCallback(async (colName, data, jobOrdIds, isAppend) => {
    let modColName = colName
    let modData = { ...data }
    if (["JoRef1", "JoRef2"].includes(colName)) {
      modColName = colName.replace("Jo", "")
      let newColValue = {}
      for (const col in data) {
        newColValue[col.replace("Jo", "")] = data[col]
      }
      modData = newColValue
    }
    console.log({ modColName })
    if (!isAppend) {
      updateColumn(modColName, modData, jobOrdIds, null)
    } else {
      const jsondata = {
        JobOrdIds: jobOrdIds,
        ColValues: modData,
        LogRmk: `แก้ไขข้อมูล ${jobOrderColNm[colName] || colName}`
      }
      await ax.post(jobOrderApiNm.appendJobOrderColumn, jsondata)
      onSearchButtonClick(lastFilter);
    }
    setDialogTextOpen(false);
  }, [updateColumn, ax, onSearchButtonClick])

  const updateDateTime = useCallback((colName, data, jobOrdIds) => {
    const withFamily = colName === "FinDte" ? 1 : null
    if (colName === "TakeDteSt") {
      data.IsRdyTake = data[colName] ? 1 : 0
    }
    updateColumn(colName, data, jobOrdIds, withFamily)
    setDialogDateTimeOpen(false);
  }, [updateColumn])

  const updateContSize = useCallback(async (data, jobOrdIds) => {
    const jsondata = {
      JobOrdIds: jobOrdIds,
      ColValues: data,
      WithFamily: 0,
      LogRmk: `แก้ไขข้อมูล ขนาดตู้และประเภทหาง}`
    }
    ax.post(jobOrderApiNm.updateJobOrderColumn, jsondata).then(value => {
      if (value.data) {
        onSearchButtonClick(lastFilter)
        setDialogContSizeOpen(false)
      }
    })
  }, [ax, onSearchButtonClick])

  const updateDriver = useCallback(async (data) => {
    console.log("updateDriver data::", data)
    const result = await ax.post(jobOrderApiNm.insertUpdateJobOrderContainerStatus, data)
    if (result && result.data) {
      setDialogDriverOpen(false)
      onSearchButtonClick(lastFilter)

    }
  }, [ax, onSearchButtonClick])

  const updateContainerData = useCallback((containerData, jobOrdId) => {
    const jsondata = {
      JobOrdId: jobOrdId,
      ColValues: {
        ContNo: containerData.ContNo,
      },
      LogRmk: "แก้ไขหมายเลขมูลตู้"
    }
    ax.post(jobOrderApiNm.updateJobOrderColumn, jsondata).then(value => {
      console.log("in updateJobOrderColumn value::", value)
      if (value.data) {
        setDialogContainerOpen(false)
        onSearchButtonClick(lastFilter);
      }
    })
  }, [ax, onSearchButtonClick])

  const onFinish = useCallback(() => {
    onSearchButtonClick(lastFilter)
  }, [onSearchButtonClick])

  useEffect(() => {
    setFilterData(o => {
      const today = {
        AptTmSt: dayjs(dayjs().format("YYYY-MM-DD 00:00:00")),
        AptTmEn: dayjs(dayjs().format("YYYY-MM-DD 23:59:59.997"))
      }
      const returnData = { ...o, ...today }
      onSearchButtonClick(returnData)
      return returnData
    })
  }, [onSearchButtonClick])

  return (
    <BoxFC height="100%">
      <BoxFR>
        <Box flex={1} />
        <TextField {...filterProps("JobOrdId")} sx={{ width: 120 }} onKeyUp={handleJobOrdIdKeyPress} />
        <DateTimeSelect
          sx={{ width: 160 }}
          label={jobOrderColNm.AptTm}
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={setSelectDate}
        />
        <TextField {...filterProps("JobNo")} label="JobNo(ตรงทุกอักษร)" />
        <TextField {...filterProps("Bkg")} />
        <TextField {...filterProps("ContNo")} />
        <TextField {...filterProps("TakePlc")} />
        <TextField {...filterProps("RtnPlc")} />
        <ComboBox sx={{ width: 120 }} options={msData.driverCombo} label={jobOrderColNm.DrvNm}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <ComboBox sx={{ width: 160 }} options={msData.cusCombo} label={shipmentColNm.CusSNm}
          selectedId={filterData.CusId}
          setSelectedId={handleCusSelect}
        />
        <ComboBox sx={{ width: 160 }} options={jobCombo} label={shipmentColNm.JobSNm}
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(oldState => ({ ...oldState, JobId: id }))}
        />
        <TextField {...filterProps("IsNotFin")} sx={{ width: 120 }} label="สถานะจบงาน" select
          value={filterData.IsNotFin == null ? -1 : filterData.IsNotFin}
          onChange={(e) => setFilterData(oldState => ({ ...oldState, IsNotFin: e.target.value === -1 ? null : e.target.value }))}>
          <MenuItem value={-1}>ไม่ระบุ</MenuItem>
          <MenuItem value={1}>งานไม่จบ</MenuItem>
          <MenuItem value={0}>งานจบแล้ว</MenuItem>
        </TextField>
        <SearchButton onClick={() => { onSearchButtonClick(filterData) }} />
        <ClearButton onClick={() => { setFilterData(clearFilterData) }} />
      </BoxFR>
      <BoxFR>
        <Button variant="contained" onClick={handleDriverIncomeShipmentClick}>
          <BookmarkRoundedIcon sx={{ mr: 1 }} />ค่าเที่ยว-บุ๊คกิ้ง
        </Button>
        <Button variant="contained" onClick={handleDriverIncomeReportClick}>
          <ArticleRoundedIcon sx={{ mr: 1 }} />รายงานค่าเที่ยว
        </Button>
        <Button variant="contained" onClick={handleDailyExpenseReportClick}>
          <ArticleRoundedIcon sx={{ mr: 1 }} />รายงานค่าใช้จ่าย
        </Button>
        <Box flex={1} />
        <ComboBox options={msData.driverOnlyCombo} label="พนักงานขับรถ" sx={{ width: 120 }}
            selectedId={drvIdJobOrder}
            setSelectedId={(id) => setDrvIdJobOrder(id)} />
        <Button variant="contained" sx={{ minWidth: 0, p: 1 }} onClick={() => setDialogJobOrderTransferOpen(true)}>
          <PersonSearchRounded />
        </Button>

      </BoxFR>
      <Box width="100%" flexGrow={1} >
        <DataGridCellExpand
          checkboxSelection
          hideFooter
          rows={dataTable}
          columns={columnsMemo}
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
          selectionModel={selectionModel}
          onCellClick={handleCellClick}
        />
      </Box>
      <JobOrderDriverIncomeDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        jobOrdId={selectedId}
        setDataTable={setDataTable}
        lastFilter={lastFilter} />
      <DriverIncomeReportDialog dialogOpen={dialogReportOpen} setDialogOpen={setDialogReportOpen} />
      <DriverIncomeShipmentDialog dialogOpen={dialogDrvIncShpmOpen} setDialogOpen={setDialogDrvIncShpmOpen} />
      <DailyExpenseReportDialog dialogOpen={dialogDXReportOpen} setDialogOpen={setDialogDXReportOpen} />
      <UpdateContainerDialog
        dialogOpen={dialogContainerOpen}
        setDialogOpen={setDialogContainerOpen}
        jobOrdId={selectedId}
        updateContainerData={updateContainerData} />
      <UpdateTextDialog
        dialogOpen={dialogTextOpen}
        setDialogOpen={setDialogTextOpen}
        colName={selectedCol}
        oldData={selectedData}
        jobOrdIds={selectedJobOrdIds}
        updateColumn={updateText} />
      <UpdateDateTimeDialog
        dialogOpen={dialogDateTimeOpen}
        setDialogOpen={setDialogDateTimeOpen}
        colName={selectedCol}
        oldData={selectedData}
        jobOrdIds={selectedJobOrdIds}
        updateColumn={updateDateTime} />
      <UpdateDriverDialog
        dialogOpen={dialogDriverOpen}
        setDialogOpen={setDialogDriverOpen}
        oldData={selectedData}
        jobOrdIds={selectedJobOrdIds}
        updateColumn={updateDriver}
        dataTable={dataTable} />
      <UpdateContSizeDialog
        dialogOpen={dialogContSizeOpen}
        setDialogOpen={setDialogContSizeOpen}
        oldData={selectedRow}
        jobOrdIds={selectedJobOrdIds}
        updateColumn={updateContSize} />
      <JobOrderTransferDialog
        drvId={drvIdJobOrder}
        dialogOpen={dialogJobOrderTransferOpen}
        setDialogOpen={setDialogJobOrderTransferOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
    </BoxFC>
  )
}

export default JobOrderDriverIncome