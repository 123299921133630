import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent } from "@mui/material"
import PaperComponent from 'components/PaperComponent';
import Memo from '../DailyJobDialogV2Component/Memo';
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { BoxFC } from 'components/BoxCustom';
import { GlobalStateContext } from 'contexts/GlobalStateContext';

const MemoDialog = ({ dialogOpen, setDialogOpen, initMemoData, setJOItem }) => {
  const [memoData, setMemoData] = useState([])
  const {msData} = useContext(GlobalStateContext)

  console.log("init MemoData::", initMemoData )
  const handleOK = useCallback(() => {

    console.log("init MemoData::", initMemoData)
    setJOItem(o=>{
      const memoResult = memoData.map((item)=>{
        const newItem = {...item}
        delete newItem.id
        return newItem
      })
      console.log("memoResult::", memoResult)
      return {...o, Memo: memoResult}
    })
    setDialogOpen(false)
  }, [initMemoData, memoData, setJOItem, setDialogOpen])

  useEffect(()=>{
    if(dialogOpen) {
      setMemoData(initMemoData?.map((item, index)=>({...item, id: index})) || [])
    } else {
      setMemoData([])
    }
  }, [dialogOpen, initMemoData])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"แก้ไข Memo"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} height={300}>
          <Memo {...{memoData, setMemoData, dialogOpen, msData}}/>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleOK} />
    </Dialog>
  );
};

export default MemoDialog;
