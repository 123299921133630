import { Box } from "@mui/system";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import React from "react";
import { calculateRowClosingColor } from "utils";
import closingColor from "utils/closingColor";
import ClientDataQueryFilter from "./ClientDataQueryFilter";
import { columns } from "./columns";
import useClientDataQuery from "./useClientDataQuery";
import JobOrderImageDialog from "../ClientToday/JobOrderImageDialog";
import { useMediaQuery } from "@mui/material";
const ClientDataQuery = () =>{
  const {state, fn} = useClientDataQuery()
  const w800 = useMediaQuery('(min-width:800px)');
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ClientDataQueryFilter onSearchButtonClick={fn.getDailyJob} />
      <Box width="100%" flexGrow={1} mt={2}
        sx={closingColor}
      >
        <DataGridCellExpand
          hideFooter
          density={w800?"standard":"compact"}
          disableSelectionOnClick
          rows={state.dataTable}
          columns={columns(fn.handleImageClick)}
          getRowClassName={calculateRowClosingColor}
        />
      </Box>
      <JobOrderImageDialog 
        dialogOpen={state.dialogImageOpen}
        setDialogOpen={fn.setDialogImageOpen}
        selectedId={state.selectedJobOrdId}
      />
    </Box>
  )
}

export default ClientDataQuery