import React, { useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { UserContext } from "contexts/UserContext";
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import packageJson from '../../package.json';
// import Logo from "resource/Child.svg"
// import Logo from "resource/solo1.png"
// import Logo from "resource/solo2.png"
import Logo from "resource/emily.svg"

import {
  Paper,
  Container,
  Typography,
  TextField,
  Button,
  Box
} from '@mui/material';
import { useEffect } from "react";
import { ACC_TYP_EMP, ACC_TYP_CLI } from "constants/accountConstant";
import { coreApiNm } from "constants/serverConstant";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const Login = () => {
  const history = useHistory()
  const { setUser } = useContext(UserContext);
  const { ax } = useContext(GlobalStateContext);
  const [loginData, setLoginData] = useState({ email: '', pass: '' });

  const { url } = useParams()

  const setLoginValue = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  }

  const submitClick = () => {
    // this.props.history.push(`/emily`)
    // this.props.navigate('emily', { name: 'Jane' })
    ax.post("/no-session/login", { Email: loginData.email, Pass: loginData.pass })
      .then(value => {
        console.log("login value::", value)
        if (value.data) {
          const user = value.data
          setUser(user)
          // context.dispatch({type:UserAction.SET_USER, user:value.data});
          if (user.AccTypId === ACC_TYP_EMP)
            history.push(`/emily`)
          else if (user.AccTypId === ACC_TYP_CLI) {
            if (!url) {
              history.push(`/client/Today`)
            } else {
              console.log("has url")
              // history.push(decodeURIComponent(url))
              window.open(decodeURIComponent(url))
            }
          } else history.push(`/404`)
        }
      }).catch(e => {
        console.log("login error::", e)
      })
  }

  const checkEnter = (e) => {
    console.log("e.key::", e.key)
    if (e.key === 'Enter') {
      submitClick();
    }
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setLoginData({ email: 'bittto@hotmail.com', pass: 'Tester44' })
    }
    ax.post(coreApiNm.checkSession, {}).then(value => {
      if (value.data) {
        window.location.replace('/emily');
      }
    })
  }, [ax])

  useEffect(() => {
    localStorage.setItem("msData", null)
    localStorage.setItem("userData", null)
    localStorage.setItem("badgeData", null)
    localStorage.setItem("fcmToken", null)
  }, [])

  // console.log("url::", url)
  console.log("decoded url::", decodeURIComponent(url))
  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: 100 }}>
      <Paper elevation={3} style={{ padding: '1rem', height: '100%' }} >

        <Box display="flex" justifyContent="center">
          <img src={Logo} alt="Logo" width="50%" />
        </Box>
        <Typography fontWeight="bold" align='center' variant="h5" color="primary">
          Emily
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={loginData.email}
          onChange={setLoginValue}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="pass"
          label="Password"
          type="password"
          id="pass"
          value={loginData.pass}
          autoComplete="current-password"
          onChange={setLoginValue}
          onKeyPress={checkEnter}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={submitClick}>
          Sign In
        </Button>
        <Typography variant="caption" display="block" mt={1} textAlign="center">
          Version {packageJson.version}
        </Typography>
      </Paper>
    </Container>
  )
}

export default Login