export const branchNoText = (name, brchNo)=>{
  const lang = name.charCodeAt(0)< 256?"EN":"TH"
  // console.log("lang::", lang)
  // console.log("name.charCodeAt(0)::", name.charCodeAt(0))
  if(+brchNo=== 0){
    return lang==="EN"?"(HEAD OFFICE)":"(สำนักงานใหญ่)"
  } else {
    const brchNoTxt  = brchNo.toString().padStart(5, '0')
    return lang==="EN"?`(BRANCH\xa0${brchNoTxt})`:`(สาขาที่\xa0${brchNoTxt})`
  }
}