export const clearFilterData = {
  JobNo: null,
  Bkg: null,
  CusId: null,
  JobId: null,
  ContNo: null,
  IsNotFin: null,
  Loc: null
}

export const initFilterData = {
  ...clearFilterData,
}


export const initGroupData = { 1: [], 2: [], 3: [], 4: [] }