import { Button, ButtonGroup, Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const DataItem2 = ({ title, header, data, link }) => {
  return (
    <Tooltip title={title} arrow>
      <Link to={link || "#"}>
        <ButtonGroup >
          <Button variant="contained" color="secondary" sx={{ p: 1 }}>
            {header}
          </Button>
          <Button variant="outlined" color="secondary" sx={{ fontSize: 20, p: 0, bgcolor: "white", "&:hover": { bgcolor: "white" } }}>
            {data}
          </Button>
        </ButtonGroup>
      </Link>
    </Tooltip>
  )
}

export default DataItem2