import { Box, Dialog, DialogContent, Paper, Tooltip, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useContext, useEffect, useState } from "react"
import ContainerStatus from "../ContainerStatus"
import { initPageData, jobOrderApiNm } from 'constants/jobOrderConstant';
import JobOrderDriver from "../DailyJobDialogV2Component/JobOrderDriver"
import { blueGrey } from "@mui/material/colors"
import { getPOIPlace } from "utils/getPOIPlace"
import HeaderDataText from "components/HeaderDataText/HeaderDataText"
import { useCallback } from "react"
import Expense from "../DailyJobDialogV2Component/Expense"
import { useMemo } from "react"
import { BookmarkAdded, BookmarkBorder, CreditCard, CreditScore, Description, DescriptionOutlined, DirectionsBoat, DirectionsBoatFilledOutlined, MailOutline, MarkEmailRead } from "@mui/icons-material"

import { SHIPMENT_TYPE } from "constants/serverConstant";
import PlanColorBox from "components/PlanColorBox"
import dayjs from "dayjs"
import { UserContext } from "contexts/UserContext"
import { checkContStatus } from "../DailyJobDialogV2Component/checkContStatus"
let oContStatusData = []
let prevContStsData = []
let oMemoData = []
let oJOData = { ...initPageData }
let oExpenseData = []

let loaded = false

const iconProps = {
  color: "primary",
  sx: { width: 30, height: 30 }
}
const HandJobOrderDialog = ({ selectedId, dialogOpen, setDialogOpen, drvId, onFinish, lastFilter }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const {user} = useContext(UserContext)
  const [contStsData, setContStsData] = useState([])
  // const [contStsCheck, setContStsCheck] = useState(msData.containerStatuses.map(item => ({ ...item, checked: false })))
  const [memoData, setMemoData] = useState([])
  const [joData, setJOData] = useState({ ...initPageData })
  const [expenseData, setExpenseData] = useState([])
  // const [loaded, setLoaded] = useState(false)

  // const handleCheckChange = useCallback((item) => (e) => {
  //   item.checked = e.target.checked
  //   setContStsCheck([...contStsCheck])
  // }, [])

  const calculateExportIcon = useMemo(() => {
    return (
      <Box display="flex" flexDirection="row" gap={2} alignItems="center" >
        <Tooltip title={`${joData.IsRdyTake ? "ตู้พร้อมรับ" : "รอเช็คบุคกิ้ง"}`}>
          {joData.IsRdyTake === 0 ? <BookmarkBorder {...iconProps} color="disabled" /> : <BookmarkAdded {...iconProps} />}
        </Tooltip>
        <Tooltip title={`${joData.IsSndMail ? "ส่ง Email แล้ว" : "รอส่ง Email"}`} >
          {joData.IsSndMail === 0 ? <MailOutline  {...iconProps} color="disabled" /> : <MarkEmailRead  {...iconProps} />}
        </Tooltip>
        <Tooltip title={`${joData.ContDoc ? "มีใบกำกับตู้แล้ว" : "รอใบกำกับตู้"}`}>
          {joData.ContDoc ? <Description  {...iconProps} /> : <DescriptionOutlined {...iconProps} color="disabled" />}
        </Tooltip>
        <Tooltip title={`${joData.ShorId ? "จ่ายชอร์แล้ว" : "รอจ่ายชอร์"}`}>
          {joData.ShorId ? <DirectionsBoat  {...iconProps} /> : <DirectionsBoatFilledOutlined {...iconProps} color="disabled" />}
        </Tooltip>

      </Box>
    )
  }, [joData])

  const calculateImportIcon = useMemo(() => {
    return (
      <Box display="flex" flexDirection="row" gap={2} alignItems="center">
        <Tooltip title="ตู้พร้อมรับ">
          {joData.IsRdyTake === 0 ? <CreditCard {...iconProps} /> : <CreditScore {...iconProps} />}
        </Tooltip>
      </Box>
    )
  }, [joData])


  const handleDialogOk = useCallback(() => {

    const postData = {
      getArgs: lastFilter
    }
    const expHasChange = JSON.stringify(oExpenseData) !== JSON.stringify(expenseData)
    const joDataHasChange = JSON.stringify(oJOData) !== JSON.stringify(joData)
    const contStsHasChange = JSON.stringify(oContStatusData) !== JSON.stringify(contStsData)
    const joColValue = {}
    if (joDataHasChange) {
      for (const key in joData) {
        if (oJOData[key] !== joData[key]) {
          joColValue[key] = joData[key]
        }
      }
      postData.jobOrdData = {
        JobOrdId: joData.JobOrdId,
        ColValues: joColValue,
        LogRmk: "แก้ไขข้อมูลใบงาน"
      }
    }

    if (expHasChange) {
      const argsArr = []
      for (const data of expenseData) {
        argsArr.push({
          DalyExpId: data.DalyExpId,
          ExpDte: data.ExpDte?.toString("YYYY-MM-DD HH:mm:ss") || null,
          ClrDte: (data.ClrDte && data.ClrDte.toString("YYYY-MM-DD HH:mm:ss")) || null,
          DrvId: data.DrvId,
          JobOrdId: data.JobOrdId,
          Amnt: data.payType === "OUT" ? data.Amnt * -1 : data.Amnt,
          IsCash: data.IsCash,
          IsPaid: data.IsPaid,
          Dscp: data.Dscp,
          Rmk: data.Rmk
        })
      }
      // await ax.post(dailyExpApiNm.insertDailyExpenseBulk, { argsArr, getArgs: null })
      postData.dalyExpData = { argsArr }
    }

    if (contStsHasChange) {
      // await ax.post(jobOrderApiNm.insertUpdateJobOrderContainerStatus, contStatusData)
      postData.contStatusData = contStsData
    }
    if (expHasChange || contStsHasChange || joDataHasChange) {
      ax.post(jobOrderApiNm.updateJobOrderAllDetail, postData).then(value => {
        if (value.data) {
          onFinish && onFinish(value.data)
        }
      })
    }
    setDialogOpen(false)
  }, [joData, contStsData, expenseData, ax, lastFilter, setDialogOpen, onFinish])


  useEffect(() => {
    // console.log("in HandedJobOrderDialog useeffected loaded::", loaded, joData.DrvId)
    if (loaded) {
      setExpenseData(o => {
        const nullDrvId = oExpenseData.filter(item => item.DrvId === null).map(item => item.DalyExpId)
        console.log("nullDrvId::", nullDrvId)
        return o.map(item => {
          if (nullDrvId.includes(item.DalyExpId)) {
            return { ...item, DrvId: joData.DrvId }
          } else {
            return item
          }
        })
      })
    }
  }, [joData.DrvId])

  
  useEffect(() => {
    
    console.log('Old value:', prevContStsData);
    console.log('New value:', contStsData);
    checkContStatus({ contStatusData: contStsData, joData, prevContStsData })
  }, [contStsData, joData])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(jobOrderApiNm.getDailyJobAllDetail, { JobOrdId: selectedId }).then(value => {
        if (value.data) {
          const contStses = value.data.ContStses
          oContStatusData = msData.containerStatuses.filter(item => !item.IsHide).map(contStatus => {
            const foundData = contStses?.find(it => it.ContStsId === contStatus.ContStsId)
            return {
              ...contStatus,
              DrvId: foundData && foundData.DrvId,
              TukId: foundData && foundData.TukId,
              JobOrdId: selectedId,
              StsTm: foundData && foundData.StsTm,
              Rmk: foundData && foundData.Rmk,
              IsCmpt: foundData && foundData.IsCmpt
            }
          })
          delete value.data.ContStses


          oExpenseData = value.data.DailyExpenses.map(item => ({ ...item }))
          delete value.data.DailyExpenses


          !value.data.Memo && (value.data.Memo = [])
          oMemoData = value.data.Memo.map((item, index) => ({ ...item, id: index }))
          delete value.data.Memo

          oJOData = { ...value.data }

          setContStsData(oContStatusData.map(item => ({ ...item })))
          prevContStsData = oContStatusData.map(item => ({ ...item }))  
          setJOData({ ...oJOData })
          setMemoData(oMemoData.map((item) => ({ ...item })))
          setExpenseData(oExpenseData.map(item => ({ ...item })))
          setTimeout(() => {
            loaded = true
          }, 500);

        }
      })
    } else {
      setDialogOpen(false)
      oContStatusData = []
      oMemoData = []
      oJOData = { ...initPageData }
      oExpenseData = []
      setContStsData(oContStatusData.map(item => ({ ...item })))
      setJOData({ ...oJOData })
      setMemoData(oMemoData.map((item) => ({ ...item })))
      setExpenseData(oExpenseData.map(item => ({ ...item })))
      loaded = false
      
    }
  }, [dialogOpen, ax, msData.containerStatuses, selectedId, setDialogOpen])


  console.log("in HandedJobOrderDialog render loaded::", loaded, joData.DrvId)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg'

    // PaperProps={{
    //   style: {
    //     minHeight: '90%',
    //     maxHeight: '90%',
    //   }
    // }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`ปล่อยใบงานเลขที่ ${selectedId}`}
        onDeleteClick={null}
      />
      <DialogContent sx={{ bgcolor: blueGrey[50], p: 1 }}>
        <BoxFC mt={2} sx={{ gap: 1 }}>
          {/* <Paper sx={{ p: 2 }}>
            <BoxFC sx={{ gap: 1 }}>
              <Typography variant="h6">ปล่อยใบงาน</Typography>
              <BoxFR>
                {contStsCheck.map((item) => (
                  <CheckobxFormControl key={item.ContStsId} label={item.Name} checked={item.checked}
                    onChange={handleCheckChange(item)} />
                ))}
              </BoxFR>
            </BoxFC>
          </Paper> */}
          <BoxFR sx={{ alignItems: "stretch", width: "100%", justifyContent: "space-evenly" }}>
            <BoxFC sx={{ gap: 1 }}>

              <Paper sx={{ pl: 2, pr: 0, py: 1, width: "100%" }}>
                {/* <Expense jobOrdId={selectedId} msData={msData} expenseData={expenseData} setExpenseData={setExpenseData} /> */}
                <BoxFC sx={{ height: 140, overflow: "auto", gap: 1 }}>
                  <Typography variant="h6">เงินแนบใบงาน</Typography>
                  <Expense expenseData={expenseData} setExpenseData={setExpenseData} msData={msData} user={user}/>
                </BoxFC>
              </Paper>
              <Paper sx={{ p: 2 }}>
                <BoxFR sx={{ alignItems: "stretch" }} >

                  <BoxFC flex={1} sx={{ gap: 0 }}>
                    <Typography variant="h6">พนักงานขับรถ</Typography>
                    <ContainerStatus {...{ msData, contStsData, setContStsData }}
                      setJobOrderData={setJOData}
                      suggessDrvId={drvId} />
                  </BoxFC>
                  <BoxFC flex={1}>
                    <Typography variant="h6" >ผู้รับผิดชอบ</Typography>
                    <JobOrderDriver joData={joData} setJOData={setJOData} msData={msData} />
                  </BoxFC>
                </BoxFR>
              </Paper>
            </BoxFC>
            <Paper sx={{ px: 2, py: 2, width: 450 }} >
              <BoxFC sx={{ gap: 1 }}>
                <BoxFR>
                  <Typography variant="h6" >รายละเอียดใบงาน</Typography>
                  <Box flex={1} />
                  {
                    joData.ShpmTypId === SHIPMENT_TYPE.EXPORT ?
                      calculateExportIcon : calculateImportIcon
                  }
                </BoxFR>
                <HeaderDataText header="ชื่องาน:" text={<strong>{joData.JobSNm}</strong>} />
                <HeaderDataText header="สถานที่รับ:" text={getPOIPlace(joData, "TAKE")} />
                <HeaderDataText header="สถานที่ส่ง:" text={getPOIPlace(joData, "LOC")} />
                <HeaderDataText header="สถานที่คืน:" text={getPOIPlace(joData, "RTN")} />
                <BoxFR>
                  <HeaderDataText header="เริ่มรับได้:" text={joData.TakeDteSt ? dayjs(joData.TakeDteSt).format("DD/MM HH:mm") : "-"} />
                  <HeaderDataText header="ปิดรับ:" text={joData.TakeDteEn ? dayjs(joData.TakeDteEn).format("DD/MM HH:mm") : "-"} />
                </BoxFR>
                <BoxFR>
                  <HeaderDataText header="เริ่มคืนได้:" text={joData.RtnDteSt ? dayjs(joData.RtnDteSt).format("DD/MM HH:mm") : "-"} />
                  <HeaderDataText header="ปิดคืน:" text={joData.RtnDteEn ? dayjs(joData.RtnDteEn).format("DD/MM HH:mm") : "-"} />
                </BoxFR>
                <HeaderDataText header="หมายเลขตู้:" text={joData.ContNo} />
                <HeaderDataText header="ขนาดตู้:" text={joData.ContSizeFull} />
                <HeaderDataText header="JobNo:" text={joData.JobNo} />
                <HeaderDataText header="Booking:" text={joData.Bkg} />
                <HeaderDataText header="แผนการ:" text={
                  <BoxFR >

                    <PlanColorBox color={joData.PlnClr} />
                    {joData.PlnTxt || "-"}
                  </BoxFR>
                } />
                <HeaderDataText header="หมายเหตุ:" text={joData.Rmk} />
                <HeaderDataText header="Memo:" text={memoData.map(item => item.Memo).join("| ")} />
              </BoxFC>
            </Paper>
          </BoxFR>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default HandJobOrderDialog