
import {  Button, TextField } from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers-pro";

import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import TextFieldChangeLanguage from "components/TextFieldChangeLanguage";
import { jobOrderColNm } from "constants/jobOrderConstant";
import dayjs from "dayjs";

import React from "react";

const JobOrderDriver = ({ joData, setJOData, msData }) => {


  return (
    <BoxFC>
      <BoxFR mt={-1}>
        <ComboBox options={msData.driverOnlyCombo}
          sx={{ flex: 1 }}
          selectedId={joData.DrvId}
          setSelectedId={(id) => {
            setJOData(o => ({
              ...o,
              DrvId: id,
              DrvActTm: id ? o.DrvActTm ? o.DrvActTm : dayjs() : null,
              TukId: msData.drivers.find(drv => drv.DrvId === id)?.TukId
            }))
          }}
        />
        <ComboBox options={msData.truckCombo}
          sx={{ flex: 1 }}
          selectedId={joData.TukId}
          setSelectedId={(id) => {
            setJOData(o => ({
              ...o,
              TukId: id
            }))
          }}
        />
      </BoxFR>
      <BoxFR>
        <DesktopDatePicker
          label={jobOrderColNm.DrvActTm}
          inputFormat="DD/MM/YYYY"
          mask="__/__/____"
          renderInput={(params) => <TextField size="small"  {...params} fullWidth />}
          value={joData.DrvActTm}
          onChange={(newValue) => setJOData(o => ({ ...o, DrvActTm: newValue }))} />
        <Button variant="contained" onClick={(e) => setJOData(o => ({ ...o, DrvId: null, DrvActTm: null, TukId: null }))}>Clear</Button>
      </BoxFR>
      <TextFieldChangeLanguage size="small" label={jobOrderColNm.DrvRmk}
        sx={{ width: "100%" }}
        value={joData.DrvRmk || ""}
        onChange={(e) => setJOData(o => ({ ...o, DrvRmk: e.target.value }))} multiline rows={3} />
    </BoxFC>
  )
}

export default React.memo(JobOrderDriver)