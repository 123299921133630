export const suggessDrvCombo = (drvId, options) => {
  
  const driverCombo = options || JSON.parse(localStorage.getItem("msData"))?.driverOnlyCombo
  // const msData = JSON.parse(localStorage.getItem("msData"))
  if (!drvId) return driverCombo
  const result = []
  for (const drv of driverCombo) {
    if (drv.id === drvId) {
      result.unshift({ ...drv, group: "แนะนำ" })
    } else {
      result.push({ ...drv })
    }
  }
  return result
}