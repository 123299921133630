
import { Dialog, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BoxFC } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, {  useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { summaryColumns } from "./columns";
import { checkAndAddInArray } from "./function";

const SummaryDialog = ({ dialogOpen, setDialogOpen, jobOrderData, aptTm }) => {

  const {msData} = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  
  const numJob = useMemo(()=>{
    return dataTable.reduce((prev, cur)=>{
      return prev + cur.JobOrdIds.length
    }, 0)
  }, [dataTable]) 

  useEffect(()=>{

    if(dialogOpen){
      console.log("jobOrderData:::", jobOrderData)
      const filteredData = jobOrderData.filter(item=> item.DrvSts1 || item.DrvSts2 || item.DrvSts3 || item.DrvSts4 || item.DrvSts5)
      filteredData.forEach(item=>{
        const tagIdNms = (item.TagIdNm && JSON.parse(item.TagIdNm)) || []
        item.TagIds = tagIdNms.map(item=>item[0]).join(",")
      })
      console.log("filteredData::", filteredData)
      const arr1 = []
      const arr2 = []
      const arr3 = []
      const arr4 = []
      const arr5 = []
  
      for (const data of jobOrderData) {
        if (checkAndAddInArray(arr1, data, 1)) continue
        if (checkAndAddInArray(arr2, data, 2)) continue
        if (checkAndAddInArray(arr3, data, 3)) continue
        if (checkAndAddInArray(arr4, data, 4)) continue
        if (checkAndAddInArray(arr5, data, 5)) continue
  
      }
      const tagNm1 = msData.tags.find(tag=>tag.TagId ===  1).Name
      const tagNm2 = msData.tags.find(tag=>tag.TagId ===  2).Name
      const tagNm3 = msData.tags.find(tag=>tag.TagId ===  3).Name
      const tagNm4 = msData.tags.find(tag=>tag.TagId ===  4).Name
      const tagNm5 = msData.tags.find(tag=>tag.TagId ===  5).Name
      arr1.forEach(item=>item.TagNm = tagNm1)
      arr2.forEach(item=>item.TagNm = tagNm2)
      arr3.forEach(item=>item.TagNm = tagNm3)
      arr4.forEach(item=>item.TagNm = tagNm4)
      arr5.forEach(item=>item.TagNm = tagNm5)
      const allData = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5]
      allData.forEach((item, index) =>{
        item.No = index+ 1
      })
      console.log("allData::", allData)
      setDataTable(allData)
    }
  }, [jobOrderData, msData.tags, dialogOpen])
  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายงานสรุปงาน"
      />
      <DialogContent>
        
      <BoxFC height={700} mt={2}>
        <Typography variant="h6">ประจำวันที่ {aptTm.format("DD MMMM YYYY")}</Typography>
        <Typography variant="h6">จำนวน {numJob} เที่ยว</Typography>
        <Box flex={1} >
          <DataGridCellExpand
            hideFooter
            rows={dataTable}
            columns={summaryColumns} />
        </Box>
      </BoxFC>
      </DialogContent>
      <DialogFooter 
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(SummaryDialog)