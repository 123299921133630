import { Collapse, IconButton, List, ListItem, Typography } from "@mui/material";
import React from "react";
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { BoxFC, BoxFR } from "components/BoxCustom";
import NavMenuItem from "./MenuItemExpanded";
import { NavLink } from "react-router-dom";

const renderMenu = (item, url, setOpen) => {
  return (
    <ListItem sx={{ px: 2, pt: 0.3 }} key={item.num} >
      <NavLink to={`${url}/${item.page}`} style={{ width: '100%' }} onClick={() => setOpen(false)}>
        <NavMenuItem num={item.num} text={item.menuName} badgeName={item.badgeName}/>
      </NavLink>
    </ListItem>
  )
}


const HeaderItem = ({userPms, headerItem, childrenOpen, toggleExpand, url, setOpen }) => {
  return (
    <BoxFC sx={{ gap: 0 }}>
      <BoxFR justifyContent="center" sx={{ gap: 0 }}>
        <Typography color="primary" variant="h6" textAlign="center" style={{cursor: "pointer"}} onClick={()=>toggleExpand(headerItem.num)}>
          {`${headerItem.num}. ${headerItem.menuName}`}
        </Typography>
        <IconButton sx={{ color: "primary.main", p: 0 }} onClick={()=>toggleExpand(headerItem.num)}>
          {childrenOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </IconButton>
      </BoxFR>
      <Collapse in={childrenOpen}>
        <List sx={{ pt: 0 }}>
          {headerItem.children.filter(child=>!child.hide).map(subMenu => {
            if(!subMenu.permission){
               return renderMenu(subMenu, url, setOpen)
            }
            return userPms.find(pm=>subMenu.permission.split(";").includes(pm.Code) && pm.Mode > 0) 
            && renderMenu(subMenu, url, setOpen)
          })}
        </List>
      </Collapse>
    </BoxFC>
  )
}

export default HeaderItem