
import { jobOrderColNm } from "constants/jobOrderConstant"

export const dailyExpApiNm = {
  getDailyExpense: "/daily-expense/getDailyExpense",
  insertDailyExpense: "/daily-expense/insertDailyExpense",
  insertDailyExpenseBulk: "/daily-expense/insertDailyExpenseBulk",
  updateDailyExpense: "/daily-expense/updateDailyExpense",
  updateDailyExpenseColumn: "/daily-expense/updateDailyExpenseColumn",
  deleteDailyExpense: "/daily-expense/deleteDailyExpense",
  deleteNotClearExpense: "/daily-expense/deleteNotClearExpense"
}
export const dailyExpenseColNm = {
  JobOrdId: jobOrderColNm.JobOrdId,
  ExpDte: "วันที่จ่ายเงิน",
  DrvId: "พนักงานขับรถ",
  DrvNNm: "พนักงานขับรถ",
  Amnt: "จำนวนเงิน",
  IsCash: "วิธีจ่าย",
  IsPaid: "จ่ายเงินแล้ว",
  ClrDte: "วันที่ Clear",
  ClrAccNNm: "Clear โดย",
  Dscp: "รายละเอียด",
  Rmk: "หมายเหตุ",
  JobOrdRmk: jobOrderColNm.Rmk,
  AptTm: jobOrderColNm.AptTm,
  TakePlc: jobOrderColNm.TakePlc,
  RtnPlc: jobOrderColNm.RtnPlc,
  Loc: jobOrderColNm.Loc,
  ContSizeFull: jobOrderColNm.ContSizeFull,
}

