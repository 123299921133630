import { IconButton, InputAdornment, TextField } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import React from "react";
import { useCallback, useRef } from "react";
import { convertThaiEng } from "utils";
import { alertWarning } from "./Alert";

let isFocus = false;
let selectionStart = 0
let selectionEnd = 0
const TextFieldChangeLanguage = (props) => {
  const textRef = useRef();

  const handleChangeLanguage = useCallback((e) => {
    if (!isFocus) {
      alertWarning("กรุณาคลิกในกล่องข้อความก่อน")
      return
    }
    e.preventDefault()
    e.stopPropagation()
    if(selectionStart === selectionEnd){
      textRef.current.value = convertThaiEng(textRef.current.value)
    } else {
      let newValue = textRef.current.value
      const selectedText = newValue.substring(selectionStart, selectionEnd)
      // console.log("selectedText::", selectedText)
      const converted = convertThaiEng(selectedText)
      newValue = newValue.slice(0, selectionStart) + converted + newValue.slice(selectionEnd, newValue.length)
      // console.log("cut text::", newValue)
      textRef.current.value = newValue
    }
  }, [])

  const handleOnBlur = useCallback((e) => {
    if (props.onBlur) {
      props.onBlur(e)
    }
    isFocus = false
  }, [props])

  const handleOnFocus = useCallback((e) => {
    if (props.onFocus) {
      props.onFocus(e)
    }
    isFocus = true
  }, [props])

  const handleOnSelect = useCallback((e) => {
    if (props.onSelect) {
      props.onSelect(e)
    }
    selectionStart = e.target.selectionStart
    selectionEnd = e.target.selectionEnd
  }, [props])

  return (
    <TextField {...props}
      inputRef={textRef}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onSelect={handleOnSelect}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <IconButton tabIndex={-1} onMouseDown={handleChangeLanguage} sx={{ p: 0 }} disabled={props.disabled}>
            <LanguageIcon />
          </IconButton>
        </InputAdornment>,
      }} />
  )
}


export default React.memo(TextFieldChangeLanguage)