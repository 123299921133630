import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Popover, Select, TextField, Tooltip, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { jobOrderColumns } from "./columns"
import { blueGrey, grey } from "@mui/material/colors"
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import { AddCircleRounded, ChevronLeftRounded, CloudDownloadRounded, DeleteRounded, KeyboardArrowLeftRounded, KeyboardDoubleArrowLeftRounded, SearchRounded } from "@mui/icons-material"
import AddExpenseDialog from "./AddExpenseDialog"
import { alertConfirm, alertError, alertSuccess, alertWarning } from "components/Alert"
import SaveButton from "components/buttons/SaveButton"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import DailyJobDialogV2 from "../DailyJobDialogV2"
import { driverIncomeColNm } from "constants/driverIncomeConstant"
import dayjs from "dayjs"
import { expenseColNm } from "constants/expenseConstant"
import AddDailyExpenseDialog from "./AddDailyExpenseDailog"
import MemoDialog from "./MemoDialog"
import HandJobOrderDialog from "./HandJobOrderDialog"
import CheckobxFormControl from "components/CheckobxFormControl"
import { PLAN_COLORS } from "constants/serverConstant"
import PlanColorBox from "components/PlanColorBox"

let selectedRow = null
let deletedIds = []
let oJOItem = null
let oExpData = []
let oDIItem = null
let selectedJobOrdId = null
let selectedExps = []

const initJOItem = {
  FinDte: null,
  ContNo: "",
  SealNo: "",
  Rmk: "",
  PlnClr: "#FFFFFF",
  PlnTxt: "",
  Memo: null,
  ArvTm: null,
  LevTm: null,
  TakeAptTm: null,
  RtnAptTm: null,
  DrvId: null,
  TukId: null,
  DrvActTm: null,
  FullWt: "",
  TareWt: "",
}

const initDIItem = {
  JobOrdId: null,
  DrvId: null,
  AptTm: null,
  FinDte: null,
  Amnt: 0,
  ExtAmnt: 0,
  ExtTxt: "",
}

const JobOrderData = forwardRef(({ joData, openDailyExpenseDialog, dialogOpen, drvId, onFinish, lastFilter, driverFullName }, ref) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [joItem, setJOItem] = useState({ ...initJOItem })
  const [expData, setExpData] = useState([])
  const [dialogAddExpenseOpen, setDialogAddExpenseOpen] = useState(false)
  const [dailyJobDialogOpen, setDailyJobDialogOpen] = useState(false)
  const [dialogAddDailyExpenseOpen, setDialogAddDailyExpenseOpen] = useState(false)
  const [dialogMemoOpen, setDialogMemoOpen] = useState(false)
  const [dialogHandJobOrderOpen, setDialogHandJobOrderOpen] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [diItem, setDIItem] = useState({ ...initDIItem })
  const [handJobOrdId, setHandJobOrdId] = useState("")
  const [searchContNoText, setSearchContNoText] = useState("")
  const [searchContNoData, setSearchContNoData] = useState([])
  const [isToUpper, setIsToUpper] = useState(true)

  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);

  const memoText = useMemo(() => {
    console.log("in memoText::", joItem.Memo)
    return joItem.Memo ? joItem.Memo.map(item => item.Memo).join("| ") : ""
  }, [joItem.Memo])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    variant: "outlined",
    label: jobOrderColNm[name],
    value: joItem[name],
    onChange: (e) => setJOItem(o => ({ ...o, [name]: e.target.value })),
    onBlur: (e) => {
      if (name === "Memo") return
      if (isToUpper && e.target.value !== "") {
        setJOItem(o => ({ ...o, [name]: e.target.value.toUpperCase() }))
      }
    }
  }), [joItem, setJOItem, isToUpper])

  const textFieldExpProps = useCallback((exp, name) => ({
    size: "small",
    variant: "outlined",
    label: expenseColNm[name],
    value: exp[name],
    type: name === "UntPr" ? "number" : "text",
    inputProps: name === "UntPr" ? { style: { textAlign: "right" } } : {},
    onChange: (e) => {
      exp[name] = e.target.value
      setExpData(oldState => [...oldState])
    }

  }), [])
  const textFieldDIProps = useCallback((name, isMoney) => ({
    size: "small",
    variant: "outlined",
    label: driverIncomeColNm[name],
    value: diItem[name],
    type: isMoney ? "number" : "text",
    sx: isMoney ? { width: 120 } : {},
    inputProps: isMoney ? { style: { textAlign: "right" } } : {},
    InputProps: isMoney ? { endAdornment: <InputAdornment position="end">บาท</InputAdornment> } : {},
    onChange: (e) => { setDIItem(o => ({ ...o, [name]: e.target.value })) }
  }), [diItem])

  const datePickerJobOrderProp = useCallback((name) => ({
    label: jobOrderColNm[name],
    inputFormat: "DD/MM HH:mm",
    mask: "__/__ __:__",
    value: joItem[name],
    onChange: (newValue) => { setJOItem(oldState => ({ ...oldState, [name]: newValue && newValue.format("YYYY-MM-DD HH:mm:00.00") })) },
    renderInput: (params) => <TextField size="small"  {...params} fullWidth />,
  }), [joItem, setJOItem])


  const toggleContSts = useCallback((contSts) => {
    // const newJOData = {...joData}
    // const newExpData = joData.Exps.map(item => ({...item}))
    // delete newJOData.Exps

    // setJOData(newJOData)
    // setExpData(newExpData)

  }, [])

  const handleMemoClick = useCallback(() => {
    console.log("selectedRow::", selectedRow)
    if (!selectedRow) {
      alertError("กรุณาเลือกใบงานก่อน")
      return
    }
    setDialogMemoOpen(true)
  }, [])
  const handleSave = useCallback((rowClick) => async () => {

    if (!selectedRow) {
      alertWarning("กรุณาเลือกแถวทีจะทำการแก้ไขและบันทึกก่อน")
      return
    }
    //check apttm b4 save coz they may have problem later espectaially with driver
    //2023-11-20 dont check apttm coz they might only go for take container
    // if (!selectedRow?.AptTm) {
    //   alertError("ไม่สารถบันทึกได้ เนื่องจากไม่มีเวลานัด")
    // }
    let hasChange = false
    const postData = {
      getArgs: lastFilter
    }

    //---------------------------------------------- Expense ----------------------------------------------
    if (selectedRow && (JSON.stringify(oExpData) !== JSON.stringify(expData))) {
      hasChange = true
      postData.expData = {}
      if (deletedIds.length > 0) {
        postData.expData.deletedIds = deletedIds
      }
      if (expData && expData.length > 0) {
        const argsArr = []
        for (const data of expData) {
          argsArr.push({
            JobOrdId: selectedRow.JobOrdId,
            ExpId: data.ExpId,
            VatPrct: data.VatPrct,
            WhtPrct: data.WhtPrct,
            UntPr: data.UntPr,
            IsCost: data.IsCost,
            Qty: 1,
            IsOwnRcpt: data.IsOwnRcpt,
            Rmk: data.Rmk,
          })
        }
        postData.expData.insertUpdateData = {
          argsArr: argsArr,
          // getArgs: { JobOrdIds: [selectedRow.JobOrdId] },
          NoUpdate: true
        }
      }
    }

    //---------------------------------------------- JobOrder ----------------------------------------------
    if (selectedRow && (JSON.stringify(oJOItem) !== JSON.stringify(joItem))) {
      hasChange = true
      // let isFinishedJob = false
      const colValue = {}
      for (const key in joItem) {
        if (oJOItem[key] !== joItem[key]) {
          colValue[key] = joItem[key]
          selectedRow[key] = joItem[key]
          // if (key === "FinDte" && joItem[key]) {
          //   isFinishedJob = true
          // }
        }
      }
      postData.jobOrdData = {
        JobOrdId: selectedRow.JobOrdId,
        ColValues: colValue,
        LogRmk: "ลงจบใบงาน"
      }
    }

    //---------------------------------------------- Driver Income ----------------------------------------------
    if (selectedRow && (JSON.stringify(oDIItem) !== JSON.stringify(diItem))) {
      hasChange = true
      postData.drvIncData = {
        argsArr: [{
          ...diItem,
          JobOrdId: selectedRow.JobOrdId,
          DrvId: drvId,
          AptTm: dayjs(selectedRow.AptTm).format("YYYY-MM-DD HH:mm:ss"),
          FinDte: selectedRow.FinDte ? dayjs(selectedRow.FinDte).format("YYYY-MM-DD HH:mm:ss") : null
        }]
      }
    }

    if (hasChange) {
      ax.post(jobOrderApiNm.updateJobOrderAllDetail, postData).then(value => {
        onFinish(value.data)

        if (value.data?.jobOrders?.find(jo => jo.JobOrdId === joItem.JobOrdId)) {

          if (postData.expData) {
            oExpData = expData.map(item => ({ ...item }))
          }
          if (postData.jobOrdData) {
            oJOItem = { ...joItem }
          }
          if (postData.drvIncData) {
            oDIItem = { ...diItem }
          }
        } else {
          setJOItem({ ...initJOItem })
          setExpData([])
          setDIItem({ ...initDIItem })
          oExpData = []
          oJOItem = { ...initJOItem }
          oDIItem = { ...initDIItem }
        }
        alertSuccess("บันทึกเรียบร้อยแล้ว")
        rowClick && rowClick()
      })
    }

    // selectedRow.Exps = result.data.map(item => ({ ...item, UntPr: +item.UntPr }))
    // oExpData = expData.map(item => ({ ...item, UntPr: +item.UntPr }))
    // if (isFinishedJob) {
    //   setJOItem({ ...initJOItem })
    //   setJOData(o => o.filter(item => item.JobOrdId !== selectedRow.JobOrdId).map((item, index) => ({ ...item, No: index + 1 })))
    //   setExpData([])
    //   oExpData = []
    // }
    // oJOItem = { ...joItem }
    // selectedRow.DrvInc = { ...diItem }
    // oDIItem = { ...diItem }

    if (hasChange) {

    }

    //---------------------------------------------- Expense ----------------------------------------------
    // if (selectedRow && (JSON.stringify(oExpData) !== JSON.stringify(expData))) {
    //   hasChange = true
    //   if (deletedIds.length > 0) {
    //     await ax.post(expenseJobOrderApiNm.deleteExpenseJobOrder, { ExpJobOrdIds: deletedIds })
    //   }
    //   if (expData && expData.length > 0) {
    //     const argsArr = []
    //     for (const data of expData) {
    //       argsArr.push({
    //         JobOrdId: selectedRow.JobOrdId,
    //         ExpId: data.ExpId,
    //         VatPrct: data.VatPrct,
    //         WhtPrct: data.WhtPrct,
    //         UntPr: data.UntPr,
    //         IsCost: data.IsCost,
    //         Qty: 1,
    //         IsOwnRcpt: data.IsOwnRcpt,
    //         Rmk: data.Rmk,
    //       })
    //     }
    //     const postData = {
    //       argsArr: argsArr,
    //       getArgs: { JobOrdIds: [selectedRow.JobOrdId] },
    //       NoUpdate: true
    //     }
    //     const result = await ax.post(expenseJobOrderApiNm.insertUpdateExpenseJobOrderBulk, postData)
    //     selectedRow.Exps = result.data.map(item => ({ ...item, UntPr: +item.UntPr }))
    //     oExpData = expData.map(item => ({ ...item, UntPr: +item.UntPr }))
    //   }
    // }


    // //---------------------------------------------- JobOrder ----------------------------------------------
    // if (selectedRow && (JSON.stringify(oJOItem) !== JSON.stringify(joItem))) {
    //   hasChange = true
    //   let isFinishedJob = false
    //   const colValue = {}
    //   for (const key in joItem) {
    //     if (oJOItem[key] !== joItem[key]) {
    //       colValue[key] = joItem[key]
    //       selectedRow[key] = joItem[key]
    //       if (key === "FinDte" && joItem[key]) {
    //         isFinishedJob = true
    //       }
    //     }
    //   }
    //   const postData = {
    //     JobOrdId: selectedRow.JobOrdId,
    //     ColValues: colValue,
    //     LogRmk: "ลงจบใบงาน"
    //   }

    //   await ax.post(jobOrderApiNm.updateJobOrderColumn, postData)
    //   if (isFinishedJob) {
    //     setJOItem({ ...initJOItem })
    //     setJOData(o => o.filter(item => item.JobOrdId !== selectedRow.JobOrdId).map((item, index) => ({ ...item, No: index + 1 })))
    //     setExpData([])
    //     oExpData = []
    //   }
    //   oJOItem = { ...joItem }
    // }

    // //---------------------------------------------- Driver Income ----------------------------------------------
    // if (selectedRow && (JSON.stringify(oDIItem) !== JSON.stringify(diItem))) {
    //   hasChange = true
    //   const postData = {
    //     argsArr: [{
    //       ...diItem,
    //       JobOrdId: selectedRow.JobOrdId,
    //       DrvId: drvId,
    //       AptTm: dayjs(selectedRow.AptTm).format("YYYY-MM-DD HH:mm:ss"),
    //       FinDte: selectedRow.FinDte ? dayjs(selectedRow.FinDte).format("YYYY-MM-DD HH:mm:ss") : null
    //     }]
    //   }
    //   await ax.post(driverIncomeApiNm.insertDriverIncomeBulk, postData)
    //   selectedRow.DrvInc = { ...diItem }
    //   oDIItem = { ...diItem }
    // }


    // if (hasChange) {
    //   alertSuccess("บันทึกเรียบร้อยแล้ว")
    //   rowClick && rowClick()
    // }

  }, [ax, expData, joItem, diItem, drvId, lastFilter, onFinish])

  const rowClick = useCallback((row) => {
    selectedRow = row
    setSelectionModel([row.JobOrdId])
    const newJOItem = {
      FinDte: selectedRow.FinDte,
      ContNo: selectedRow.ContNo,
      SealNo: selectedRow.SealNo,
      PlnClr: selectedRow.PlnClr,
      PlnTxt: selectedRow.PlnTxt,
      Rmk: selectedRow.Rmk,
      Memo: selectedRow.Memo,
      RcptNm: selectedRow.RcptNm,
      RcptAddr: selectedRow.RcptAddr,
      RcptTaxId: selectedRow.RcptTaxId,
      ArvTm: selectedRow.ArvTm,
      LevTm: selectedRow.LevTm,
      TakeAptTm: selectedRow.TakeAptTm,
      RtnAptTm: selectedRow.RtnAptTm,
      DrvId: selectedRow.DrvId,
      TukId: selectedRow.TukId,
      DrvActTm: selectedRow.DrvActTm,
      FullWt: selectedRow.FullWt,
      TareWt: selectedRow.TareWt,
    }
    oJOItem = { ...newJOItem }
    const newExpData = selectedRow?.Exps?.map(item => ({ ...item, UntPr: +item.UntPr })) || []
    oExpData = newExpData.map(item => ({ ...item }))
    const newDIItem = selectedRow?.DrvInc ? selectedRow.DrvInc : { ...initDIItem }
    oDIItem = { ...newDIItem }

    setJOItem(newJOItem)
    setExpData(newExpData)
    setDIItem(newDIItem)
  }, [])

  const handleRowDoubleClick = useCallback((params) => {
    selectedJobOrdId = params.row.JobOrdId
    setDailyJobDialogOpen(true)
  }, [])

  const handleRowClick = useCallback((params) => {
    console.log("in handleRowClick params::", params)
    console.log({ oExpData }, { expData })


    if (selectedRow &&
      (JSON.stringify(oJOItem) !== JSON.stringify(joItem)
        || JSON.stringify(oExpData) !== JSON.stringify(expData)
        || JSON.stringify(oDIItem) !== JSON.stringify(diItem)
      )) {
      alertConfirm("ต้องการบันทึกข้อมูลก่อนเปลี่ยนใบงานหรือไม่?", handleSave(() => rowClick(params.row)), () => rowClick(params.row), "บันทึก")
      return
    } else {
      rowClick(params.row)
    }

  }, [joItem, expData, diItem, handleSave, rowClick])

  const handleOpenAddExpenseDialog = useCallback(() => {
    if (!selectedRow) {
      alertError("กรุณาเลือกใบงานก่อน")
      return
    }
    setDialogAddExpenseOpen(true)
  }, [])

  const handleOpenAddDailyExpenseDialog = useCallback(() => {
    if (!selectedRow) {
      alertError("กรุณาเลือกใบงานก่อน")
      return
    }

    openDailyExpenseDialog(selectedRow.JobOrdId)
  }, [openDailyExpenseDialog])

  const onJobOrderDataFinish = useCallback((data) => {
    // console.log("onFinish::", data)
    // setExpData(o => ([...o, data]))
    onFinish(data)
  }, [onFinish])

  const addExpenseToJobOrder = useCallback((data) => {
    setExpData(o => ([...o, data]))
  }, [])

  const handleAddExpenseJob = useCallback(() => {
    if (!selectedRow) {
      alertError("กรุณาเลือกใบงานก่อน")
      return
    }
    if (!selectedRow.ExpJobs) {
      alertError("ไม่พบรายการที่บันทึกไว้")
      return
    }
    const expsData = []
    for (const data of selectedRow.ExpJobs) {
      if (data.IsOprHide) continue;
      if (expsData.find(item => item.ExpId === data.ExpId && item.JobOrdId === data.JobOrdId)) continue;

      // console.log("jobOrdData::", jobOrdData)
      // console.log("expdata::", data)
      if ((data.ContSize && data.ContSize !== selectedRow.ContSize)
        || (data.ContTyp && data.ContTyp !== selectedRow.ContTyp)
        || (data.TlrTypId && data.TlrTypId !== selectedRow.TlrTypId)
        || (data.ShpmTypId && data.ShpmTypId !== selectedRow.ShpmTypId)) continue;
      // expsData.push([id,data.ExpId,data.VatPrct,data.WhtPrct,data.UntPr,1,data.IsOwnRcpt,""])
      expsData.push({
        ExpJobOrdId: null,
        ExpNm: data.ExpNm,
        JobOrdId: selectedRow.JobOrdId,
        ExpId: data.ExpId,
        VatPrct: data.VatPrct,
        WhtPrct: data.WhtPrct,
        UntPr: data.UntPr,
        IsCost: data.IsCost,
        Qty: 1,
        IsOwnRcpt: data.IsOwnRcpt,
        Rmk: ""
      })
    }
    setExpData(o => {
      const result = o.map(item => ({ ...item }))
      for (const exp of expsData) {
        const found = result.find(item => item.ExpId === exp.ExpId)
        if (!found) {
          result.push(exp)
        }
      }
      return result
    })
  }, [])


  const handleDeleteExpItem = useCallback((index) => () => {
    setExpData(o => {
      if (o[index].ExpJobOrdId) {
        deletedIds.push(o[index].ExpJobOrdId)
      }

      const result = o.map(item => ({ ...item }))
      result.splice(index, 1)
      return result
    })
  }, [])

  const handleAddDailyExpense = useCallback((exp) => () => {
    selectedExps = [exp]
    setDialogAddDailyExpenseOpen(true)
  }, [])

  const handleAddAllDailyExpense = useCallback(() => {
    if (!selectedRow) {
      alertError("กรุณาเลือกใบงานก่อน")
      return
    }
    if (!selectedRow.Exps || selectedRow.Exps.length === 0) {
      alertError("ไม่พบรายการค่าใช้จ่าย")
      return
    }
    selectedExps = expData
    setDialogAddDailyExpenseOpen(true)
  }, [expData])

  const handleHandJobOrdIdKeyUp = useCallback((e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      setDialogHandJobOrderOpen(true)
    }
  }, [])

  const handleCheckBoxChange = useCallback((e) => {
    // console.log("e.target.check::",e.target.checked)
    if (!selectedRow) {
      alertError("กรุณาเลือกใบงานก่อน")
      return
    }
    if (e.target.checked) {
      setJOItem(o => ({
        ...o,
        DrvId: oJOItem.DrvId,
        TukId: oJOItem.TukId,
        DrvActTm: oJOItem.DrvActTm
      }))
    } else {
      setJOItem(o => ({ ...o, DrvId: null, TukId: null, DrvActTm: null }))
    }
  }, [])

  const handleSearchJobOrder = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleSearchContNoTextKeyUp = useCallback((e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      const postData = {
        ContNo: searchContNoText,
        IsNotFin: 1,
        cols: ["JobOrdId", "ContNo"]
      }
      ax.post(jobOrderApiNm.getJobOrder, postData).then(value => {
        if (value.data) {
          if (value.data.length === 0) {
            alertError("ไม่พบข้อมูล")
            return
          }
          if (value.data.length === 1) {
            setHandJobOrdId(value.data[0].JobOrdId)
            setDialogHandJobOrderOpen(true)
          } else {
            setSearchContNoData(value.data)
          }
        }
      })
    }
  }, [ax, searchContNoText])

  const handleOpenHandJobOrdIdFromSearch = useCallback((jobOrdId) => () => {
    setHandJobOrdId(jobOrdId)
    setDialogHandJobOrderOpen(true)
  }, [])

  const extraFinDteData = useMemo(() => {
    if (msData.serverData?.CompanyData?.Abbr === "TL"){
      return (
        <BoxFC sx={{ gap: 1 }}>
          <BoxFR>
            <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeAptTm")} />
            <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnAptTm")} />
          </BoxFR>
          <BoxFR>
            <TextField {...textFieldProps("ContNo")} />
            <TextField {...textFieldProps("SealNo")} />
          </BoxFR>
          <BoxFR>
            <TextField {...textFieldProps("FullWt")} />
            <TextField {...textFieldProps("TareWt")} />
          </BoxFR>
          <BoxFR >
            <FormControl sx={{ width: 75, minWidth: 75, bgcolor: "white" }} size="small">
              <InputLabel id="plnClrLabel">{jobOrderColNm.PlnClr}</InputLabel>
              <Select label={jobOrderColNm.PlnClr} labelId="plnClrLabel"
                value={joItem.PlnClr}
                onChange={(e) => setJOItem(oldData => ({ ...oldData, PlnClr: e.target.value }))}>
                {PLAN_COLORS.map(item => (
                  <MenuItem key={item} value={item}>
                    <PlanColorBox color={item} /></MenuItem>
                ))}
              </Select>
            </FormControl>
  
            <TextField {...textFieldProps("PlnTxt")} fullWidth />
          </BoxFR>
          <TextField {...textFieldProps("Rmk")} multiline rows={4} />
          <TextField {...textFieldProps("Memo")} multiline rows={1}
            value={memoText} onChange={null} onClick={handleMemoClick} />
        </BoxFC>
      )
    }
    return (
      <BoxFC sx={{ gap: 1 }}>
        <BoxFR>
          <DateTimePickerCustomTime {...datePickerJobOrderProp("ArvTm")} />
          <DateTimePickerCustomTime {...datePickerJobOrderProp("LevTm")} />
        </BoxFR>
        <BoxFR>
          <TextField {...textFieldProps("ContNo")} />
          <TextField {...textFieldProps("SealNo")} />
        </BoxFR>
        <BoxFR>
          <TextField {...textFieldProps("FullWt")} />
          <TextField {...textFieldProps("TareWt")} />
        </BoxFR>
        <BoxFR >
          <FormControl sx={{ width: 75, minWidth: 75, bgcolor: "white" }} size="small">
            <InputLabel id="plnClrLabel">{jobOrderColNm.PlnClr}</InputLabel>
            <Select label={jobOrderColNm.PlnClr} labelId="plnClrLabel"
              value={joItem.PlnClr}
              onChange={(e) => setJOItem(oldData => ({ ...oldData, PlnClr: e.target.value }))}>
              {PLAN_COLORS.map(item => (
                <MenuItem key={item} value={item}>
                  <PlanColorBox color={item} /></MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField {...textFieldProps("PlnTxt")} fullWidth />
        </BoxFR>
        <TextField {...textFieldProps("Rmk")} multiline rows={4} />
        <TextField {...textFieldProps("Memo")} multiline rows={1}
          value={memoText} onChange={null} onClick={handleMemoClick} />
      </BoxFC>
    )
  }, [msData.serverData.CompanyData, joItem, datePickerJobOrderProp, textFieldProps, memoText, handleMemoClick])
  useImperativeHandle(ref, () => {
    return {
      onRowClick: (row) => {
        if (row) {
          rowClick(row)
        } else {
          setSelectionModel([])
          setJOItem({ ...initJOItem })
          setExpData([])
          selectedRow = null
        }
      }
    }
  })

  useEffect(() => {
    if (!dialogOpen) {
      selectedRow = null
      oJOItem = null
      oExpData = null
      deletedIds = []
      setJOItem({ ...initJOItem })
      setExpData([])
    }
  }, [dialogOpen])

  console.log("in JobOrderData render:: expData:::", expData)
  return (
    <BoxFC height="100%" sx={{ gap: 1 }}>
      <BoxFR>
        <BoxFR sx={{ alignItems: "baseline" }}>
          <Typography variant="h6">งานที่รับผิดชอบ</Typography>
          <Typography variant="body" >(จำนวน {joData.length} ใบงาน)</Typography>
        </BoxFR>
        <TextField
          size="small" variant="outlined" label="เลขที่ใบงาน" sx={{ width: 140 }} type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <Tooltip title="ค้นหาด้วยหมายเลขตู้" arrow placement="top" followCursor>
                <IconButton sx={{ pr: 0 }} onClick={handleSearchJobOrder}>
                  <SearchRounded sx={{ cursor: "pointer" }} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }}
          value={handJobOrdId} onChange={e => setHandJobOrdId(e.target.value)} onKeyUp={handleHandJobOrdIdKeyUp} />
        <Popover
          anchorEl={anchorEl}
          open={popOverOpen}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}>
          <Paper elevation={8} sx={{ p: 1 }}>
            <TextField label="หมายเลขตู้ (เฉพาะยังไม่จบ)" size="small" helperText="กด Enter เพื่อค้นหา"
              value={searchContNoText} onKeyUp={handleSearchContNoTextKeyUp}
              onChange={(e) => setSearchContNoText(e.target.value)} />
            <Divider />

            <BoxFC sx={{ gap: 1 }} mt={1}>
              {searchContNoData.map((item) => (
                <Button key={item.JobOrdId} variant="outlined" size="small"
                  onClick={handleOpenHandJobOrdIdFromSearch(item.JobOrdId)}>
                  {item.ContNo} #{item.JobOrdId}
                </Button>
              ))}
            </BoxFC>
          </Paper>
        </Popover>
        <Button variant="contained" size="small" onClick={() => setDialogHandJobOrderOpen(true)}>
          <AddCircleRounded sx={{ mr: 1 }} />เพิ่มใบงาน
        </Button>
        <Box flex={1} />
        {/* <Button variant="contained" size="small"><AccessTimeRounded sx={{ mr: 1 }} />งานแก้ไขล่าสุด</Button> */}
      </BoxFR>
      <Box height={160}>
        <DataGridCellExpand
          density="compact"
          disableSelectionOnClick={true}
          hideToolbar
          hideFooter
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => setSelectionModel(ids)}
          columns={jobOrderColumns(toggleContSts)}
          rows={joData}
          onRowClick={handleRowClick}
          onRowDoubleClick={handleRowDoubleClick}
        />
      </Box>
      <BoxFC flex={1} bgcolor={blueGrey[50]} p={1} borderRadius={2}>
        <BoxFR sx={{ alignItems: "stretch" }} height="100%">
          <BoxFC height="100%" sx={{ gap: 1 }}>
            <BoxFR>
              <Typography variant="h6">ค่าใช้จ่าย</Typography>
              <Button variant="contained" size="small"
                onClick={handleOpenAddDailyExpenseDialog}><ChevronLeftRounded sx={{ mr: 1 }} /> จ่ายเงิน พขร.</Button>
              <Button variant="contained" size="small"
                onClick={handleOpenAddExpenseDialog}><AddCircleRounded sx={{ mr: 1 }} /> เพิ่มค่าใช้จ่าย</Button>
              <Button variant="contained" size="small" onClick={handleAddExpenseJob}
                disabled={!selectedRow || !selectedRow.ExpJobs || selectedRow.ExpJobs.length === 0}>
                <CloudDownloadRounded sx={{ mr: 1 }} /> ค่าใช้จ่ายที่ควรมี</Button>
            </BoxFR>
            <BoxFC flex={1} bgcolor="white" borderRadius={2} p={1} width={500} sx={{ gap: 1 }} >

              <Button variant="contained" onClick={handleAddAllDailyExpense} sx={{ py: 0 }}>
                <KeyboardDoubleArrowLeftRounded sx={{ mr: 1 }} /> จ่ายทุกค่าใช้จ่ายให้ พขร.
              </Button>
              <BoxFC sx={{ gap: 1, pt: 1, overflowY: "auto" }}>
                {expData?.map((exp, index) => {
                  return (
                    <BoxFR key={index} sx={{ gap: 1 }}>
                      <Tooltip title={`จ่าย ${exp.ExpNm} ให้พขร.`} arrow placement="top" followCursor>
                        <Button variant="contained" size="small" onClick={handleAddDailyExpense(exp)} sx={{ px: 0.5, py: 0, minWidth: 0 }}>
                          <KeyboardArrowLeftRounded />
                        </Button>
                        {/* <IconButton onClick={handleAddDailyExpense(exp)} sx={{ p: 0 }}>
                        <ArrowCircleLeftRounded color="primary" />
                      </IconButton> */}
                      </Tooltip>

                      <Typography sx={{ width: 20 }}>{index + 1}.</Typography>
                      <Typography sx={{ width: 190 }}>{exp.ExpNm}</Typography>
                      <Box flex={1} />
                      <TextField {...textFieldExpProps(exp, "UntPr")} sx={{ width: 120 }} label="ราคา(บาท)"></TextField>
                      <TextField {...textFieldExpProps(exp, "Rmk")}></TextField>
                      <IconButton onClick={handleDeleteExpItem(index)} sx={{ p: 0 }}>
                        <DeleteRounded />
                      </IconButton>
                    </BoxFR>
                  )
                })}
              </BoxFC>

              <Box flex={1} />
            </BoxFC>
            {
              msData.serverData.Feature?.JobOrderTransFerDriverIncome ?
                <BoxFC sx={{ gap: 0 }} height={75}>
                  <Typography variant="h6">ค่าเที่ยว</Typography>
                  <BoxFR>
                    <TextField {...textFieldDIProps("Amnt", true)} />
                    <TextField {...textFieldDIProps("ExtAmnt", true)} />
                    <TextField {...textFieldDIProps("ExtTxt")} />
                  </BoxFR>
                </BoxFC>
                : null
            }
          </BoxFC>
          <Box borderLeft={1} height={365} borderColor={grey[400]} />
          <BoxFC width="100%" height="100%" sx={{ gap: 1 }}>
            <BoxFR>
              <Typography variant="h6">ข้อมูลใบงาน</Typography>
              <Box flex={1} />
              <CheckobxFormControl checked={isToUpper} onChange={(e) => setIsToUpper(e.target.checked)} label="ใช้ตัวพิมพ์ใหญ่" />
            </BoxFR>
            <BoxFC sx={{ gap: 1 }}>
              <BoxFR>
                <Box flex={1}>
                  <DateTimePickerCustomTime {...datePickerJobOrderProp("FinDte")} />
                </Box>
                <Box flex={1}>
                  <CheckobxFormControl label="รับผิดชอบใบงาน" checked={joItem.DrvId !== null} onChange={handleCheckBoxChange} />
                </Box>
              </BoxFR>
              {extraFinDteData}
              <BoxFR sx={{ gap: 0 }}>
                <Typography variant="caption" mt={-1} width={285} noWrap textOverflow="ellipsis">
                  <strong>ใบเสร็จ: </strong>{joItem.RcptNm}
                </Typography>

              </BoxFR>
              <Typography variant="caption" mt={-1.5} width={285} noWrap textOverflow="ellipsis">
                <strong>ที่อยู่: </strong>{joItem.RcptAddr}
              </Typography>
              <Typography variant="caption" mt={-1.5} width={150} noWrap textOverflow="ellipsis">
                <strong>TaxID: </strong>{joItem.RcptTaxId}
              </Typography>
            </BoxFC>
          </BoxFC>
        </BoxFR>
        <SaveButton onClick={handleSave(null)} sx={{ mt: -1 }} />
      </BoxFC>
      <AddExpenseDialog
        dialogOpen={dialogAddExpenseOpen}
        setDialogOpen={setDialogAddExpenseOpen}
        onFinish={addExpenseToJobOrder}
        selectedRow={selectedRow}
        expData={expData} />

      <DailyJobDialogV2
        dialogOpen={dailyJobDialogOpen}
        setDialogOpen={setDailyJobDialogOpen}
        selectedId={selectedJobOrdId}
        onFinish={onJobOrderDataFinish}
        lastFilter={lastFilter} />
      <AddDailyExpenseDialog
        dialogOpen={dialogAddDailyExpenseOpen}
        setDialogOpen={setDialogAddDailyExpenseOpen}
        exps={selectedExps}
        onFinish={onJobOrderDataFinish}
        lastFilter={lastFilter}
        drvId={drvId}
        driverFullName={driverFullName}
        selectedRow={selectedRow} />
      <MemoDialog
        dialogOpen={dialogMemoOpen}
        setDialogOpen={setDialogMemoOpen}
        initMemoData={joItem.Memo}
        setJOItem={setJOItem} />
      <HandJobOrderDialog
        dialogOpen={dialogHandJobOrderOpen}
        setDialogOpen={setDialogHandJobOrderOpen}
        selectedId={handJobOrdId}
        onFinish={onJobOrderDataFinish}
        drvId={drvId}
        lastFilter={lastFilter} />
    </BoxFC>
  )
}, [])

export default React.memo(JobOrderData)