import dayjs from "dayjs";
import React, { createContext, useState, useMemo } from "react"
import ApiService from "service/ApiService"
// import { globalStateReducer } from "../reducers/GlobalStateReducer";

export const GlobalStateContext = createContext();

export const GSAction = {
  SET_IS_LOADING: "SET_IS_LOADING"
}

const GlobalStateContextProvider = (props) => {

  // const [gs, dispatch] = useReducer(globalStateReducer, {
  //     isLoading:false,
  //     customers: [],
  //     cusCombo: [],
  //     jobs: [],
  //     jobCombo: []
  // })
  const [isLoading, setIsLoading] = useState(false)
  const [badge, setBadge] = useState({
    booking: 0
  })
  const [isEditing, setIsEditing] = useState(false)
  const ax = useMemo(() =>  new  ApiService(setIsLoading), [setIsLoading]);
  const [msData, setMSData] = useState(null)
  const [targetTimestamp, setTargetTimestamp] = useState(dayjs())


  console.log(`--in GlobalStateContextProvider---`)
  return (
    <GlobalStateContext.Provider value={{ 
      ax,  
      isLoading, setIsLoading, 
      isEditing, setIsEditing, 
      msData, setMSData, 
      targetTimestamp, setTargetTimestamp,
      badge, setBadge}}>
      {props.children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContextProvider
