
import { Divider, Paper, Popover, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const SubMenuPopover = ({ anchorEl, subMenu, handleMenuClose, pms }) => (
  <Popover 
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
    transformOrigin={{ vertical: "top", horizontal: "left" }}
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
    <Paper sx={{ p: 2, bgcolor: blue[600] }}>
      <BoxFC>
        {subMenu
          .filter(item => !item.pms || item.pms?.some(pm => pms.includes(pm)))
          .map((item, index) => (
            <BoxFC sx={{ gap: 0 }} key={index}>
              <BoxFR
                key={index}
                component={item.subMenu ? "div" : NavLink}
                to={item.path}
                onClick={ handleMenuClose}
              >
                <Typography color="white">{item.label}</Typography>
              </BoxFR>
              <Divider sx={{ pt: 1, borderColor: "white" }} width="100%" />
            </BoxFC>
          ))}
      </BoxFC>
    </Paper>
  </Popover>
)

export default SubMenuPopover;