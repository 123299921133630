import { TextField, Typography } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import React, { useCallback } from "react";

const EmailDataContent = ({ emailData, setEmailData }) => {

  const textFieldProp = useCallback((name) => ({
    size: "small",
    variant: "outlined",
    sx: {width: 600},
    value: emailData[name],
    onChange: (e) => setEmailData(o => ({ ...o, [name]: e.target.value }))
  }), [emailData, setEmailData])
  return (
    <BoxFC>
      <Typography variant="h6">ข้อมูลการส่ง E-mail</Typography>
      <BoxFC pl={2}>
        <TextField {...textFieldProp("Cc")}  label="Cc"/>
        <TextField {...textFieldProp("Bcc")}  label="Bcc"/>
        <TextField {...textFieldProp("ReplyTo")} label="ReplyTo"/>
        <TextField {...textFieldProp("Signature")} label="Signature" multiline rows={5}/>
      </BoxFC>
    </BoxFC>
  )
}

export default React.memo(EmailDataContent)