import { Route } from "react-router-dom"
import JobOrderDriverIncome from "./JobOrderDriverIncome/JobOrderDriverIncome"
import JobPlanning from "./JobPlaning/JobPlaning"


export const branchApiNm = {
  getJobOrderAllDetail: "/branch/getJobOrderAllDetail"
}
export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobOrderDriverIncome: "ลงเบอร์ตู้-ค่าเทียว"
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "7.1", menuName: extraPageName.JobPlaning, prNum: 7 },
  { page: "JobOrderDriverIncome", num: "7.2", menuName: extraPageName.JobOrderDriverIncome, prNum: 7 },
]

export const extraRoute = (path)=> [
  <Route key="JobPlaning" path={`${path}/JobPlaning`} ><JobPlanning /> </Route>,
  <Route key="JobOrderDriverIncome" path={`${path}/JobOrderDriverIncome`} ><JobOrderDriverIncome /> </Route>
]
