import React, { useEffect, useState, useCallback, useContext } from "react";
import { Box, Dialog, DialogContent, Typography } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import ContainerStatus from "./ContainerStatus";
import { BoxFC } from "components/BoxCustom";
import { alertWarning } from "components/Alert";
import dayjs from "dayjs";


const initDialogData = (msData) => msData.containerStatuses.map(contStatus => ({
  ...contStatus,
  DrvId: null,
  JobOrdId: 0,
  StsTm: null,
  Rmk: ""
}))
const UpdateDriverDialog = ({ updateColumn, setDialogOpen, dialogOpen, jobOrdIds, dataTable }) => {

  const { msData } = useContext(GlobalStateContext)
  // const initDialogData = useMemo(()=>msData.containerStatuses.map(contStatus =>({
  //   ...contStatus,
  //   DrvId: null,
  //   JobOrdId: 0,
  //   StsTm: null,
  //   Rmk: ""
  // })), [msData])

  const [dialogData, setDailogData] = useState(initDialogData(msData))


  const handleUpdateColumn = useCallback(() => {
    const postData = []
    for (const id of jobOrdIds) {
      dialogData.forEach(item => {
        postData.push({ ...item, JobOrdId: id })
      })
    }
    updateColumn(postData)
  }, [updateColumn, jobOrdIds, dialogData])

  useEffect(() => {
    if (!dialogOpen) {
      setDailogData(initDialogData(msData))
    } else {
      console.log("jobOrdIds::", jobOrdIds)
      const contStses = dataTable.filter(row => jobOrdIds.includes(row.JobOrdId)).map(item => item.ContStses)
      const drvData = contStses[0].reduce((prev, cur) => {
        prev[cur.ContStsId] = cur.DrvId
        return prev
      }, {})
      console.log("drvData::", drvData);
      let hasDiff = false
      for (const row of contStses) {
        for(const sts of row){
          if (drvData[sts.ContStsId] !== sts.DrvId) {
            alertWarning("ข้อมูลพนักงานขับรถทั้งหมดที่เลือกไม่เหมือนกัน การแก้ไขจะทับข้อมูลที่เลือกทั้งหมด")
            setDailogData(initDialogData(msData));
            hasDiff = true
            break
          }
        }
        if(hasDiff) break;
      }
      if (!hasDiff) {
        setDailogData(msData.containerStatuses.map(contStatus => {
          
          return {
          ...contStatus,
          DrvId: drvData[contStatus.ContStsId],
          JobOrdId: 0,
          StsTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          Rmk: ""
        }}))
      }
    }
  }, [dialogOpen, msData, dataTable, jobOrdIds])

  console.log("in UpdateDriverDialog ::", dialogData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`จ่ายใบงานรถร่วม`}
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} mt={2}>
          {jobOrdIds.length > 1 && <Typography variant="Body" >{`แก้ไขข้อมูลจำนวน ${jobOrdIds.length} แถว`}</Typography>}
          <BoxFC>
            <ContainerStatus msData={msData} contStsData={dialogData} setContStsData={setDailogData}
              direction="row" options={msData.subContractCombo} hideDriverActTm={true} hideIsCmpt={true} />
          </BoxFC>
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleUpdateColumn} />
    </Dialog>
  )
}

export default React.memo(UpdateDriverDialog, (o, n) => o.dialogOpen === n.dialogOpen)