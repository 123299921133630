
import { BoxFC, BoxFR } from "components/BoxCustom";
import HeaderDataText from "components/HeaderDataText/HeaderDataText";

import React from "react";

const Shipment = ({ joData, msData }) => {
  return (
    <BoxFC sx={{gap:1}}>
      <BoxFR>
        <HeaderDataText header="ประเภทงาน:" text={msData.ShpmTypObj[joData.ShpmTypId]} />
        <HeaderDataText header="จำนวนตู้:" text={joData.NumJob} />
      </BoxFR>
      <BoxFR>
        <HeaderDataText header="JobNo:" text={joData.JobNo} />
        <HeaderDataText header="Booking:" text={joData.Bkg} />
      </BoxFR>
      <BoxFR>
        <HeaderDataText header="Vessel:" text={`${joData.Vssl} V.${joData.Voy}`} />
        <HeaderDataText header="Port:" text={joData.Port} />
      </BoxFR>
      <BoxFR width="100%">
        <HeaderDataText header="ลูกค้า:" text={joData.CusSNm} />
        <HeaderDataText header="งาน:" text={joData.JobSNm} />
      </BoxFR>
      <BoxFR>
        <HeaderDataText header={`${msData.serverData.RefColumnName?.job_order?.Ref1}:` || "อ้างอิง1:"} text={joData.ShRef1} />
        <HeaderDataText header={`${msData.serverData.RefColumnName?.job_order?.Ref2}:` || "อ้างอิง2:"} text={joData.ShRef2} />
      </BoxFR>
      <HeaderDataText header="หมายเหตุ:" text={joData.ShRmk} />
    </BoxFC>
  )
}

export default React.memo(Shipment)