import { Button, IconButton, InputAdornment, List, ListItem, Popover, TextField } from "@mui/material";
import React from "react";
import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import { useState } from "react";
import { useCallback } from "react";
import { BoxFR } from "components/BoxCustom";

const TextFieldWithOption = ({ label, value, setValue, options, sx, variant, size, onDrop }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleItemClick = useCallback((item)=>{
    setValue(item)
    setAnchorEl(null)
  }, [setValue])
  return (
    <BoxFR sx={sx}>
      <TextField
        onDrop={onDrop}
        label={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={{flex:1}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" >
              <IconButton disableRipple={true} sx={{ p: 0 }} edge="end" onClick={e => setAnchorEl(e.currentTarget)}>
                <ArrowDropDownCircleRoundedIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        variant={variant || "outlined"}
        size={size || "small"}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}>
        <List dense>
          {options.map((item, index) => (
            <ListItem key={index}>
              <Button variant="outlined" fullWidth onClick={()=>handleItemClick(item)}>
                {item}
              </Button>
            </ListItem>
          ))}
        </List>
      </Popover>

    </BoxFR>
  )
}

export default React.memo(TextFieldWithOption)