import React, {useEffect, useState, useCallback } from "react";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { Box, Dialog, DialogContent, FormControlLabel, RadioGroup, Typography, Radio, Checkbox } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import { alertInfo } from "components/Alert";
import PaperComponent from "components/PaperComponent";
import TextFieldChangeLanguage from "components/TextFieldChangeLanguage";

const UpdateTextDialog = ({ jobOrdIds, colName, oldData, updateColumn, setDialogOpen, dialogOpen }) => {
  const [colData, setColData] = useState("");
  const [isReplace, setIsReplace] = useState(null);
  const [isToUpper, setIsToUpper] = useState(true);

  const dialogDataProp = useCallback((name) => ({
    sx:{mt:1},
    variant: 'outlined',
    size: 'small',
    name: name,
    label: jobOrderColNm[name] || "",
    value: colData || "",
    onChange: (e) => {
      
      setColData(e.target.value)
      // if(isToUpper){
      //   setColData(e.target.value.toUpperCase())
      // } else {
      // }
    },
    onBlur: (e) => { 
      
      if(isToUpper && e.target.value !== ""){
        setColData(e.target.value.toUpperCase())
      }
      // const value = isToUpper===true?e.target.value.toUpperCase():e.target.value
      // setColData(value) 
    }
  }), [colData, isToUpper])

  useEffect(()=>{
    if(dialogOpen){
      console.log("in useEffect UpdateTExt oldData ::", oldData)
      setColData(oldData)
    } else {
      setColData("")
    }
  }, [oldData, dialogOpen])
  useEffect(()=>{
    setIsReplace(jobOrdIds.length===1?"REPLACE":null)
  }, [jobOrdIds])

  const handleUpdateColumn = () =>{
    if(isReplace == null){
      alertInfo(`กรุณาเลือก "แทนที่" หรือ "ต่อท้าย"`);
      return;
    }
    updateColumn(colName, {[colName]:colData}, jobOrdIds, isReplace ==="ADD")
  }
  // console.log("in update text jobOrdIds::", jobOrdIds)
  console.log("in update text render::", colData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader  handleDialogClose={() => setDialogOpen(false)}
        title={`แก้ไขข้อมูล ${jobOrderColNm[colName]}`}
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={1} mt={1}>
        <FormControlLabel sx={{m:0}} 
            label="ใช้อักษรพิมพ์ใหญ่(ภาษาอังกฤษ)"
            control={<Checkbox sx={{p:0}} checked={isToUpper} onChange={(e) => setIsToUpper(e.target.checked)} />}
          />
          {jobOrdIds.length > 1 && <Typography variant="Body" >{`แก้ไขข้อมูลจำนวน ${jobOrdIds.length} แถว`}</Typography>}
          {jobOrdIds.length > 1 &&
            <RadioGroup row value={isReplace} onChange={e=>setIsReplace(e.target.value)} error="xx">
              <FormControlLabel label="แทนที่" value={"REPLACE"} control={<Radio />} />
              <FormControlLabel label="ต่อท้าย" value={"ADD"} control={<Radio />} />
            </RadioGroup>
          }
          <TextFieldChangeLanguage autoFocus {...dialogDataProp(colName)} />
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleUpdateColumn} />
    </Dialog>
  )
}

export default React.memo(UpdateTextDialog, (o, n) => o.dialogOpen === n.dialogOpen)