import { Paper } from "@mui/material";
import React from "react";
import Draggable from 'react-draggable';

const  PaperComponent = (props) => {
  
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default PaperComponent