
import { Dialog, DialogContent, TextField } from "@mui/material";
import { alertError } from "components/Alert";
import { BoxFC } from "components/BoxCustom";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";
import { useState } from "react";

const ChangeDateDialog = ({ dialogOpen, setDialogOpen, confirmChangeAptTm, jobOrdId }) => {

  const [aptTm, setAptTm] = useState(dayjs(dayjs().add(1, "day").format("YYYY-MM-DD 00:00:00")))



  const datePickerProp = useMemo(() => ({
    label: "วันที่นัด",
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: aptTm,
    onChange: (newValue) => { setAptTm(newValue) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 200 }} />,

  }), [aptTm])

  const handleOk = useCallback(() => {
    if (!aptTm) {
      alertError("กรุณาระบุวันที่นัด")
      return
    }
    confirmChangeAptTm(jobOrdId, aptTm)
    setDialogOpen(false)
  }, [aptTm, confirmChangeAptTm, jobOrdId, setDialogOpen])

  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="เปลี่ยนวันที่นัด"
      />
      <DialogContent>
        <BoxFC mt={2} alignItems="center">
          <DateTimePickerCustomTime {...datePickerProp} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleOk} />
    </Dialog>
  )
}

export default React.memo(ChangeDateDialog)