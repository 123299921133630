import React from "react";
import { Button, DialogTitle, DialogActions } from '@mui/material'
import { Box } from "@mui/system";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

/**
 * In dialog title must use onTuchEnd and onClick 
 * because onTouchEnd is for mobile and onClick is for desktop
 * Mobile on click not working because we use draggable-dialog-title
 * that will fire only onTouchEnd not fire onClick
 * In the other hand, when in desktop onClick will fire but not onTouchEnd
 */
export const DialogHeader = ({ title, onDeleteClick, children, handleDialogClose }) => {
  return (
    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" sx={{
      bgcolor: 'primary.light',
      display: 'flex'
    }}>
      {title}
      {children}

      {
        onDeleteClick &&
        <Button
          sx={{ ml: 1, minWidth:20, width:50 }}
          variant='contained'
          color='error'
          onTouchEnd={onDeleteClick}
          onClick={onDeleteClick}>
          <DeleteForeverRoundedIcon />
        </Button>
      }
      <Box flexGrow={1} />
      <Button variant="contained" color='inherit' onTouchEnd={handleDialogClose} onClick={handleDialogClose}>
        <CloseRoundedIcon />
      </Button>
    </DialogTitle>
  )
}

export const DialogFooter = ({ okText, cancelText, handleDialogOk, handleDialogClose, children }) => {
  return (

    <DialogActions sx={{
      backgroundColor: theme => theme.palette.primary.light,
      gap: 3
    }}>
      <Button onClick={handleDialogClose} sx={{ width: 100 }} color='inherit' variant='contained'>{cancelText || "ยกเลิก"}</Button>
      {handleDialogOk &&
        <Button type='submit' sx={{ ml: 10, minWidth: 100 }} onClick={handleDialogOk} color='primary' variant='contained'>
          {okText}
        </Button>
      }
      {children}
    </DialogActions>
  )
}