
// import { alertWarning } from 'components/Alert';
import { accountApiNm } from 'constants/accountConstant';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";



const firebaseConfig = {
  apiKey: "AIzaSyDgvxihvJPXifWy6F1T3lY5uF2vjctF4XU",
  authDomain: "voltaic-tooling-337412.firebaseapp.com",
  projectId: "voltaic-tooling-337412",
  storageBucket: "voltaic-tooling-337412.appspot.com",
  messagingSenderId: "1046883101680",
  appId: "1:1046883101680:web:b63704bfaadbcc44e04b35",
  measurementId: "G-DZV0V0L47X"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getFirebaseToken = ( ax) => {
  return getToken(messaging, {vapidKey: 'BMS_VR53krOZip11XQUX3JymNQM5TZqQWIVdlB7tWg9cnR9bkOGdnlwxbr91Fg2i0xbjI2Gtk0j5L7mBECcyuXA'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      ax.post(accountApiNm.updateFCMToken, {FCMToken: currentToken})
      localStorage.setItem("fcmToken", currentToken)
      // setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // alertWarning("Emily ไม่สามารถทำงานได้สมบูรณ์ หากไม่เปิดรับ Notification")
    // catch error while creating client token
  });
}
export const onMessageListener= (callback)=> onMessage(messaging, (payload) => {
  //  console.log("on messaging payLoad::", payload)
   callback(payload);
});
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     resolve("xxxxx");
//     // onMessage(messaging, (payload) => {
      
//     //   resolve(payload);
//     // });
// });