
import { Button, Dialog, DialogContent } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { shipmentApiNm } from "constants/shipmentConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useEffect } from "react";
import { useContext } from "react";
import { FOLDER_SHIPMENT_FILE } from "constants/serverConstant";
import { useState } from "react";

const buttonLinkStyle = {textTransform: 'none'}
const ShipmentDocDialog = ({dialogOpen, setDialogOpen, shpmId, headerText}) =>{

  const {ax} = useContext(GlobalStateContext)

  const [files, setFiles] = useState([])
  
  const handleOpenLink = (fileNm) => () =>{
    window.open(`${window.location.origin}/${FOLDER_SHIPMENT_FILE}${shpmId}/${fileNm}`)
  }
  useEffect(()=>{
    if(dialogOpen){
      ax.post(shipmentApiNm.getShipment, {ShpmId: shpmId}).then(value=>{
        if(value.data && value.data.length === 1){
          setFiles(value.data[0].Files.map(file=>file))
        }
      })
    }
  }, [ax, shpmId, dialogOpen])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`รายการเอกสาร ${headerText}`}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC bgcolor="#f6f6f6" mt={2} p={2}  height={300}>
          
        {files.map(file=>(
            <Button key={file.FileNm} variant="outlined" 
              style={buttonLinkStyle}
              onClick={handleOpenLink(file.FileNm)}>
              {file.Label}    
            </Button>
          ))}
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(ShipmentDocDialog)