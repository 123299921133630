import React, { useContext, useEffect, useState, useCallback } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import { Box, CircularProgress, Dialog, DialogContent, TextField } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import ModAccName from "components/ModAccName";
import { containerNoCheck } from "utils";
import PaperComponent from "components/PaperComponent";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { useMemo } from "react";
import { alertWarning } from "components/Alert";

const initContainerData = {
  loaded: false,
  ContNo1: "",
  ContNo2: "",
}
const UpdateContainerDialog = ({ jobOrdId, updateContainerData, setDialogOpen, dialogOpen, firstLoad }) => {

  const { ax } = useContext(GlobalStateContext)
  const [containerData, setContainerData] = useState(initContainerData)
  const [isShowLoading1, setIsShowLoading1] = useState(false)
  const [isShowLoading2, setIsShowLoading2] = useState(false)

  const updateData = useMemo(() => {
    // let contNo = ""
    // if (!containerData.ContNo2) {
    //   contNo = containerData.ContNo1
    // } else if (!containerData.ContNo1) {
    //   contNo = containerData.ContNo2
    // } else {
    //   contNo = `${containerData.ContNo1}, ${containerData.ContNo2}`
    // }

    const contNos1 = containerData.ContNo2.split(",").map(item => item.trim());
    const contNos2 = containerData.ContNo1.split(",").map(item => item.trim());
    const returnData = []
    for(const contNo of contNos1){
      if(contNo.trim().length > 0) returnData.push(contNo)
    }
    for(const contNo of contNos2){
      if(contNo.trim().length > 0) returnData.push(contNo)
    }
    return { ContNo: returnData.length > 0 ? returnData.join(", ") : "" }
  }, [containerData])
  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: jobOrderColNm[name] || "",
    defaultValue: containerData[name] || "",
    onBlur: async (e) => {
      const value = e.target.value.toUpperCase()
      containerNoCheck(value)
      setContainerData(o => ({ ...o, [name]: value }))

      if (name === "ContNo1") setIsShowLoading1(true)
      else setIsShowLoading2(true)
      const contNos = value.split(",");
      for (const contNo of contNos) {
        if (contNo.trim().length !== 11) continue
        const postData = {
          JobOrdId: jobOrdId,
          ContNo: contNo.trim()
        }

        const result = await ax.post(jobOrderApiNm.CheckDuplicateContNoInBooking, postData, false)
        if (result.data !== null) {
          if (result.data) {
            alertWarning(`มีหมายเลขตู้ ${contNo} ซ้ำใน Booking นี้แล้ว`)
          }
        }
      }
      if (name === "ContNo1") setIsShowLoading1(false)
      else setIsShowLoading2(false)
    }
  }), [ax, jobOrdId, containerData])

  useEffect(() => {
    console.log("in UpdateContainerDialog", dialogOpen, jobOrdId)
    if (dialogOpen && jobOrdId > 0) {
      ax.post(jobOrderApiNm.getContainerData, { JobOrdId: jobOrdId }).then(value => {
        if (value.data.ContNo) {
          const contNos = value.data.ContNo.split(",")
          let contNoObj = {1: [], 2:[]};
          let num = 1;
          for(const contNo of contNos){
            contNoObj[num].push(contNo.trim())
            if(num === 1) num =2
            else num = 1
          }
          value.data.ContNo1 = contNoObj[1].join(", ")
          value.data.ContNo2 = contNoObj[2].join(", ")
        } else {
          value.data.ContNo1 = ""
          value.data.ContNo2 = ""
        }



        // let contNo1 = ""
        // let contNo2 = ""
        // const contNos = value.data.ContNo.split(",")
        // if (contNos.length > 0) {
        //   contNo1 = contNos[0]
        // }
        // if (contNos.length > 1) {
        //   contNo2 = contNos[1]
        // }
        // value.data.loaded = true
        // value.data.ContNo1 = contNo1.trim()
        // value.data.ContNo2 = contNo2.trim()
        setContainerData(value.data)
      })
    }
  }, [ax, jobOrdId, dialogOpen])

  useEffect(() => {
    if (!dialogOpen) {
      setContainerData(initContainerData)
    }
  }, [dialogOpen])
  console.log("in updateContainer")
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="แก้ไขข้อมูลตู้"
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} mt={3} key={containerData.loaded}>
          <BoxFR position="relative">
            <TextField autoFocus {...dialogDataProp("ContNo1")} label="หมายเลขตู้1" sx={{ flex: 1 }} />
            <TextField {...dialogDataProp("ContNo2")} label="หมายเลขตู้2" sx={{ flex: 1 }} />
            {isShowLoading1 &&
              <BoxFR position="absolute" width="100%">
                <BoxFC flex={1} alignItems="flex-end" ><CircularProgress color="secondary" /></BoxFC>
                <BoxFC flex={1}></BoxFC>
              </BoxFR>}
            {isShowLoading2 &&
              <BoxFR position="absolute" width="100%">
                <BoxFC flex={1}></BoxFC>
                <BoxFC flex={1} alignItems="flex-end" ><CircularProgress color="secondary" /></BoxFC>
              </BoxFR>}
          </BoxFR>
          {
            jobOrdId > 0 && <ModAccName modDte={containerData.ModDte} modAcc={containerData.ModAcc} />
          }
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => updateContainerData(updateData, jobOrdId)} />
    </Dialog>
  )
}

export default React.memo(UpdateContainerDialog, (o, n) => o.dialogOpen === n.dialogOpen)