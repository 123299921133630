import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { alertConfirm, alertError, alertWarning } from "components/Alert";
import { BoxFC, BoxFR } from "components/BoxCustom";
import SaveButton from "components/buttons/SaveButton";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { dailyExpApiNm } from "constants/dailyExpenseConstant";
import { jobOrderApiNm } from "constants/jobOrderConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { numberFormat, numberFormatNoFlotingPoint, sortObj } from "utils";
import AddDataDialog from "./AddDataDialog";
import ChangeDateDialog from "./ChangeDateDialog";
import { driverColumns, printColumnHeader, printJobColumnHeader } from "./columns";
import JobBox from "./JobBox";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import { shipmentApiNm } from "constants/shipmentConstant";
import { jobApiNm } from "constants/jobConstant";
import { printJobOrder } from "branch/functions/printJobOrder";
import { UserContext } from "contexts/UserContext";
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectAllRoundedIcon from '@mui/icons-material/SelectAllRounded';
import DeselectRoundedIcon from '@mui/icons-material/DeselectRounded';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import { checkAndAddInArray, addInArray } from './function'
import SummaryDialog from "./SummaryDialog";
import printDataTable from "utils/printDataTable";
import CheckobxFormControl from "components/CheckobxFormControl";
import { blueGrey } from "@mui/material/colors";
import TagBox from "components/TagBox";
import { branchApiNm } from "branch/constant/branchApiNm";
import { locationText } from "utils/locationText";
import { isSamePlace } from "utils/isSamePlce";



let originalDailyJobData = []
let deleteList = []
let addList = []
let selectedRow = {}
let changeAptTmJobOrdId = 0
let originalJobData = {}
const sxHover = {
  "& .MuiDataGrid-row:hover": { backgroundColor: blueGrey[50] }
}
//------------------------------------------------------------------------------------------------------
const initJobData = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }

const JobPlanning = () => {

  const { ax, msData, setIsEditing } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)
  const [aptTm, setAptTm] = useState(dayjs())
  const [selectionModel, setSelectionModel] = useState([])
  const [jobData, setJobData] = useState(initJobData)
  const [driverData, setDriverData] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogChangeDateOpen, setDialogChangeDateOpen] = useState(false)
  const [dialogSummaryOpen, setDialogSummaryOpen] = useState(false)
  const [groupLocJobData, setGroupLocJobData] = useState(false)
  const [tagData, setTagData] = useState(msData.tagCombo.filter(tag => tag.refId === "DV"))

  const filteredDriverData = useMemo(() => {
    const tagDataIds = tagData.map(tag => tag.id)
    if (tagDataIds.length > 0) {
      return driverData.filter(drv => drv.TagIds.some((tagId) => tagDataIds.includes(tagId)))
    } else {
      return driverData.filter(drv => drv.TagIds.length === 0)
    }
  }, [tagData, driverData])

  const handleSelect = useCallback((row) => {
    console.log("row::", row)

    if (row.JobOrdIds.length === 0) {
      return
    }

    if (selectionModel.length === 0) {
      alertWarning("กรุณาเลือก พันกงานขับรถอย่างน้อย 1 คน")
      return
    }

    if (selectionModel.length > row.JobOrdIds.length) {
      alertWarning(`กรุณาเลือก พนักงานขับรถไม่เกิน ${row.JobOrdIds.length} คน`)
      return
    }

    selectedRow = row
    setDialogOpen(true)
  }, [selectionModel])

  const confirmSelect = useCallback((row) => {

    console.log("row::", row)
    console.log("row.Qty::", row.Qty)
    console.log("selectionModel.length::", selectionModel.length)
    console.log("row.JobOrdIds.length::", row.JobOrdIds.length)
    console.log("selectedRow::", selectedRow)
    console.log("selectionModel::", selectionModel)
    if (row.Qty * selectionModel.length > row.JobOrdIds.length) {
      alertError("จำนวนใบงานมากกว่าที่มีอยู่")
      return
    }

    let jobOrdIndex = 0;
    for (const drvId of selectionModel) {
      const foundDriver = driverData.find(drv => drv.DrvId === drvId)
      console.log("foundDriver", foundDriver)
      console.log("row.tagId", row.TagId)
      for (let i = 0; i < row.Qty; i++) {

        const addData = {
          ...row,
          IsAdded: true,
          JobOrdId: row.JobOrdIds[jobOrdIndex],
          DrvId: drvId,
          TukId: msData.drivers.find(drv => drv.DrvId === drvId)?.TukId,
          DXAmnt: (row.DXAmnt && numberFormat(row.DXAmnt)) || "0.00",
          DXRmk: row.DXRmk,
          Selected: false
          // Text: `0.00 บาท\n(${row.Agnt}-${row.Bkg}) ${row.ContSizeFull}\n${row.TakePlc}-${row.Loc}-${row.RtnPlc}`,
        }
        const tagId = row.TagId === 5 ? 2 : row.TagId
        foundDriver[tagId].push(addData)
        addList.push(addData)
        jobOrdIndex++;
      }
    }
    const numJobOrd = row.Qty * selectionModel.length
    if (numJobOrd > 0) {
      row.JobOrdIds.splice(0, numJobOrd)
    }
    setDriverData(o => [...o])
    setJobData(o => ({ ...o }))
    setSelectionModel([])
    setIsEditing(true)
  }, [driverData, selectionModel, setIsEditing, msData.drivers])

  /**
   * #Note
   * SetJobData call twice coz this
   * https://stackoverflow.com/questions/62106596/reactjs-setstate-being-called-twice-in-a-function-called-once-why
   * but still dont know how to handle it 
   * Then just prevent push duplicate
   */
  const handleRemoveJobOrder = useCallback((item) => {
    console.log("in handleRemove item::", item)
    if (item.FinDte) {
      alertError("ลงข้อมูลจบงานแล้ว ไม่สามารถดึงข้อมูลกลับได้")
      return
    }
    setDriverData(o => {
      const found = o.find(drv => drv.DrvId === item.DrvId)
      console.log("handleRemove found::", found)
      const tagId = item.TagId === 5 ? 2 : item.TagId
      found[tagId] = found[tagId].filter(jo => jo.JobOrdId !== item.JobOrdId)
      console.log("found[tagId]::", found[tagId])
      return o.map(item => ({ ...item }))
    })

    if (!item.IsAdded) {
      deleteList.push(item.JobOrdId)
    }
    // const foundOriginalJobOrder = originalDailyJobData.find(jo => jo.JobOrdId === item.JobOrdId)
    // if (foundOriginalJobOrder) {
    //   deleteList.push(foundOriginalJobOrder.JobOrdId)
    // }

    // console.log("addList::", addList)
    setJobData(o => {
      console.log("setJobData o::", o)
      //Make new Obj ------------------------------------
      const result = { ...o }
      for (const id of [1, 2, 3, 4, 5, 6]) {
        result[id] = result[id].map(item => ({ ...item }))
      }
      //End make new Obj ---------------------------------

      console.log("item.TagId::", item.TagId)
      const found = result[item.TagId].find(jo => jo.Bkg === item.Bkg
        && jo.Agnt === item.Agnt
        && jo.ContSizeFull === item.ContSizeFull
        && isSamePlace(item, jo))
      console.log("result[item.TagId]::", result[item.TagId])
      console.log("item::", item)
      console.log("setJobData found::", found)
      if (found) {
        found.JobOrdIds.push(item.JobOrdId)
        found.JobOrdIds = Array.from(new Set(found.JobOrdIds))
        addList = addList.filter(addData => addData.JobOrdId !== item.JobOrdId)
      } else {
        console.log("o[item.TagId]", o[item.TagId])
        result[item.TagId].push({
          ...item,
          No: result[item.TagId].length + 1,
          id: item.JobOrdId,
          JobOrdIds: [item.JobOrdId]
        })
      }
      console.log("result::", result)
      return result
    })
    setIsEditing(true)
  }, [setIsEditing])

  const getJobOrderNoDriver = useCallback(() => {
    ax.post(jobOrderApiNm.getJobOrderNoDriver, {}).then(value => {
      if (value.data) {
        const arr1 = []
        const arr2 = []
        const arr3 = []
        const arr4 = []
        const arr5 = []
        const arr6 = []
        for (const data of value.data) {
          if (checkAndAddInArray(arr1, data, 1)) continue
          if (checkAndAddInArray(arr2, data, 2)) continue
          if (checkAndAddInArray(arr3, data, 3)) continue
          if (checkAndAddInArray(arr4, data, 4)) continue
          if (checkAndAddInArray(arr5, data, 5)) continue

          addInArray(arr6, data)
        }
        sortObj(arr1, "Bkg")
        sortObj(arr2, "Bkg")
        sortObj(arr3, "Bkg")
        sortObj(arr4, "Bkg")
        sortObj(arr5, "Bkg")
        sortObj(arr6, "Bkg")
        setJobData({ 1: arr1, 2: arr2, 3: arr3, 4: arr4, 5: arr5, 6: arr6 })
      }
    })
  }, [ax])

  const doGetDriverData = useCallback((aptTm) => {
    getJobOrderNoDriver()

    const aptTmSt = aptTm.format("YYYY-MM-DD 00:00:00.000")
    const aptTmEn = aptTm.format("YYYY-MM-DD 23:59:59.997")

    ax.post(jobOrderApiNm.getDailyJob, { AptTmSt: aptTmSt, AptTmEn: aptTmEn }).then(value => {
      if (value.data) {
        originalDailyJobData = value.data.map(item => ({ ...item }))

        deleteList = []
        addList = []

        const drvData = msData.drivers.filter(item => item.IsSub === 0).map(item => ({
          id: item.DrvId,
          DrvId: item.DrvId,
          TukCd: msData.trucks.find(t => t.TukId === item.TukId)?.Code,
          NName: item.NName,
          TagIds: item.TagIds ? item.TagIds.split(",").map(tagId => +tagId) : [],
          1: [],
          2: [],
          3: []
        }))
        sortObj(drvData, "NName")
        for (const data of value.data) {
          const drvSts = data.DrvSts1 || data.DrvSts2 || data.DrvSts3 || data.DrvSts4 || data.DrvSts5
          if (!drvSts || !data.TagIdNm) continue
          const drvId = +drvSts.split(";")[0]
          const driver = drvData.find(item => item.DrvId === drvId)
          if (!driver) continue
          const tagIdNm = JSON.parse(data.TagIdNm)
          const foundTag = tagIdNm.find(item => [1, 2, 3, 5].includes(item[0]))
          const tagIds = tagIdNm.map(item => item[0])
          // console.log("foundTag::", foundTag)
          // console.log("driver::", driver)
          if (!foundTag) continue
          const tabId = foundTag[0] === 5 ? 2 : foundTag[0]
          driver[tabId].push({
            ...data,
            DrvId: drvId,
            TagId: foundTag[0],
            TagIds: tagIds,
            IsAdded: false,
            IsSelected: false,
          })
        }
        setDriverData(drvData)
      }
    })
  }, [ax, msData.drivers, msData.trucks, getJobOrderNoDriver])

  const getDriverData = useCallback((aptTm) => {
    if (addList.length > 0 || deleteList.length > 0) {
      alertConfirm("มีข้อมูลยังไม่ได้บันทึก ต้องการทำรายการต่อหรือไม่?", () => doGetDriverData(aptTm))
      return
    } else {
      doGetDriverData(aptTm)
    }
  }, [doGetDriverData])

  const handleSave = useCallback(async (isReload = true) => {
    if (deleteList.length > 0) {
      //----------------------------------------- delete ContSts Data ----------------------------------------------
      console.log("deleteList::", deleteList)
      const deleteConsStsData = deleteList.reduce((prev, cur) => {
        for (const id of [1, 2, 3, 4, 5]) {
          prev.push({
            JobOrdId: cur,
            DrvId: null,
            ContStsId: id,
            Rmk: "",
            StsTm: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS")
          })
        }
        return prev
      }, [])
      await ax.post(jobOrderApiNm.insertUpdateJobOrderContainerStatus, deleteConsStsData)

      //----------------------------------------- delete Not Clear Expense ----------------------------------------
      const deleteNotClearExpData = {
        JobOrdIds: deleteList
      }
      await ax.post(dailyExpApiNm.deleteNotClearExpense, deleteNotClearExpData)

      //----------------------------------------- remove ย้ายวัน Tag ------------------------------------------------
      const deleteTagData = {
        JobOrdIds: deleteList,
        TagIds: [10]
      }
      await ax.post(jobOrderApiNm.deleteJobOrderTag, deleteTagData)

      //----------------------------------------- jobOrder Data ---------------------------------------------------
      const updateJobOrdData = {
        JobOrdIds: deleteList,
        ColValues: { AptTm: null },
        LogRmk: `แก้ไขข้อมูล เวลาเข้าโรงงาน`
      }
      await ax.post(jobOrderApiNm.updateJobOrderColumn, updateJobOrdData)

    }

    if (addList.length > 0) {
      const jobOrdIds = addList.map(item => item.JobOrdId)
      console.log("jobOrdIds::", jobOrdIds)
      //----------------------------------------- jobOrder Data ---------------------------------------------------
      // const updateJobOrdData = {
      //   JobOrdIds: jobOrdIds,
      //   ColValues: { AptTm: aptTm.format("YYYY-MM-DD 00:00:00") },
      //   LogRmk: `แก้ไขข้อมูล เวลาเข้าโรงงาน`
      // }
      // await ax.post(jobOrderApiNm.updateJobOrderColumn, updateJobOrdData)

      const jobOrderArgsArr = addList.map(item => ({
        JobOrdId: item.JobOrdId,
        DrvId: item.DrvId,
        TukId: item.TukId,
        DrvActTm: dayjs().format("YYYY-MM-DD HH:mm"),
        AptTm: aptTm.format("YYYY-MM-DD 00:00:00")
      }))
      // console.log("jobOrderArgsArr::", jobOrderArgsArr)
      // return
      await ax.post(branchApiNm.updateJobOrderDriverTruck, { argsArr: jobOrderArgsArr })

      //----------------------------------------- pushContSts Data ------------------------------------------------
      const pushContStsData = (arr, data, drvId) => {
        arr.push(
          {
            JobOrdId: data.JobOrdId,
            DrvId: drvId,
            TukId: data.TukId,
            ContStsId: 1,
            Rmk: "",
            StsTm: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS")
          },
          {
            JobOrdId: data.JobOrdId,
            DrvId: drvId,
            TukId: data.TukId,
            ContStsId: 5,
            Rmk: "",
            StsTm: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS")
          }
        )
      }
      const updateContStsData = driverData.reduce((prev, cur) => {
        for (const id of [1, 2, 3]) {
          for (const jo of cur[id]) {
            if (jobOrdIds.includes(jo.JobOrdId)) {
              pushContStsData(prev, jo, cur.DrvId)
            }
          }
        }
        return prev
      }, [])
      await ax.post(jobOrderApiNm.insertUpdateJobOrderContainerStatus, updateContStsData)

      //------------------------------------------ DX Data ----------------------------------------------
      const pushDXData = (arr, data, drvId) => {
        if (+data.DXAmnt !== 0) {
          arr.push({
            ExpDte: dayjs().toString("YYYY-MM-DD HH:mm:ss"),
            DrvId: drvId,
            JobOrdId: data.JobOrdId,
            Amnt: +data.DXAmnt * -1,
            Rmk: data.DXRmk
          })
        }
      }
      const argsArr = driverData.reduce((prev, cur) => {
        for (const id of [1, 2, 3]) {
          for (const jo of cur[id]) {
            if (jobOrdIds.includes(jo.JobOrdId)) {
              pushDXData(prev, jo, cur.DrvId)
            }
          }
        }
        return prev
      }, [])
      if (argsArr.length > 0) {
        const dxData = {
          argsArr: argsArr,
          getArgs: null
        }
        await ax.post(dailyExpApiNm.insertDailyExpenseBulk, dxData)
      }

    }
    // console.log("deleteConsStsData::", deleteConsStsData)
    // console.log("updateJobOrdData", updateJobOrdData)
    // console.log("updateContStsData", updateContStsData)
    // console.log("updateJobOrdData", dxData)

    if (isReload && (deleteList.length > 0 || addList.length > 0)) {
      // getJobOrderNoDriver()
      // getDriverData(aptTm)
      doGetDriverData(aptTm)
      setIsEditing(false)
    }
  }, [ax, doGetDriverData, aptTm, driverData, setIsEditing])

  const handlePrint = useCallback(async (printData) => {
    const jobOrdIds = printData.map(item => item.JobOrdId)
    console.log("printData::", printData)
    const shpmIds = new Set(printData.map(item => item.ShpmId))
    console.log("shpmIds::", Array.from(shpmIds))
    const shipmentsPm = ax.post(shipmentApiNm.getShipment, { ShpmIds: Array.from(shpmIds) })
    const jobIds = new Set(printData.map(item => item.JobId))
    const jobsPm = ax.post(jobApiNm.getJob, { JobIds: Array.from(jobIds) })
    const jobOrdersPm = ax.post(jobOrderApiNm.getDailyJob, { JobOrdIds: jobOrdIds })
    const [jobs, shipments, jobOrders] = await Promise.all([jobsPm, shipmentsPm, jobOrdersPm])
    if (!jobOrders.data || jobOrders.data.length === 0) return
    for (const sh of shipments.data) {
      sh.Job = jobs.data.find(item => item.JobId === sh.JobId)
    }
    for (const jo of jobOrders.data) {
      jo.Shipment = shipments.data.find(item => item.ShpmId === jo.ShpmId)
    }
    const printDataArr = []
    const modData = jobOrders.data.map(jo => {
      const drvSts = jo.DrvSts1 || jo.DrvSts2 || jo.DrvSts3 || jo.DrvSts4 || jo.DrvSts5 || ""
      const drvNm = drvSts ? drvSts.split(";")[1] : ""
      const tagIdNms = JSON.parse(jo.TagIdNm)
      jo.TagId = tagIdNms[0][0]
      jo.DrvNm = drvNm
      return jo
    })
    sortObj(modData, "DrvNm", "TagId")
    console.log("modData::", modData)
    for (const jobOrderData of modData) {
      printDataArr.push({ jobOrderData, shipmentData: jobOrderData.Shipment, jobOrdIds: [jobOrderData.JobOrdId], fName: user.FName })
    }
    console.log("printDataArr::", printDataArr)
    printJobOrder({ printDataArr, msData })
  }, [ax, msData, user.FName])

  const handleSaveAndPrint = useCallback(async () => {
    await handleSave(false)
    if (addList.length === 0) {
      alertWarning("ไม่มีการพิมพ์เนื่องจาก ไม่พบข้อมูบเพิ่มใหม่")
      return
    }

    const filteredData = driverData.reduce((prev, cur) => {
      for (const id of [1, 2, 3]) {
        for (const jo of cur[id]) {
          if (jo.IsAdded) {
            prev.push(jo)
          }
        }
      }
      return prev
    }, [])
    await handlePrint(filteredData)

    // getJobOrderNoDriver()
    // getDriverData(aptTm)
    doGetDriverData(aptTm)
  }, [handleSave, driverData, aptTm, doGetDriverData, handlePrint])

  const handleDateTimeChange = useCallback((newValue) => {
    setAptTm(newValue)
    getDriverData(newValue)
  }, [getDriverData])


  const handleChangeAptTm = useCallback((jobOrdId) => {
    changeAptTmJobOrdId = jobOrdId
    setDialogChangeDateOpen(true)
  }, [])

  const confirmChangeAptTm = useCallback(async (jobOrdId, selectedAptTm) => {
    if (aptTm.format("YYYY-MM-DD") === selectedAptTm.format("YYYY-MM-DD")) {
      return;
    }
    const updateJobOrdData = {
      JobOrdIds: [jobOrdId],
      ColValues: { AptTm: selectedAptTm.format("YYYY-MM-DD 00:00:00") },
      LogRmk: `แก้ไขข้อมูล เวลาเข้าโรงงาน`
    }
    const insertTagData = {
      JobOrdIds: [jobOrdId],
      TagIds: [10]
    }
    const jobOrdPm = ax.post(jobOrderApiNm.updateJobOrderColumn, updateJobOrdData)
    const tagPm = ax.post(jobOrderApiNm.insertJobOrderTag, insertTagData)
    Promise.all([jobOrdPm, tagPm]).then(values => {
      if (values[0].data && values[1].data) {
        getDriverData(aptTm)
      }
    })
  }, [ax, aptTm, getDriverData])


  const handleCheckboxChange = useCallback((item) => (e) => {
    console.log("item::", item)

    e.stopPropagation()
    item.IsSelected = e.target.checked
    setDriverData(o => [...o])
  }, [])

  const setSelectAll = useCallback((dataArr, isSelected, noMoveDay) => {

    const tagDataIds = tagData.map(tag => tag.id)

    for (const drv of dataArr) {
      if ((tagDataIds.length > 0 && drv.TagIds.some((tagId) => tagDataIds.includes(tagId)))
        || (tagDataIds.length === 0 && drv.TagIds.length === 0))
        for (const id of [1, 2, 3]) {
          for (const item of drv[id])
            if (!noMoveDay) {
              item.IsSelected = isSelected
            } else {
              if (!item.TagIds.includes(10))
                item.IsSelected = true
            }
        }
    }
    return dataArr
  }, [tagData])

  const handleSelectAll = useCallback(() => {
    setDriverData(o => {
      return setSelectAll([...o], true)
    })
  }, [setSelectAll])

  const handleSelectNoMoveAll = useCallback(() => {
    // setDriverData(o => {
    //   const result = [...o]
    //   for (const drv of result) {
    //     for (const id of [1, 2, 3]) {
    //       for (const item of drv[id])
    //         if(!item.TagIds.includes(10))
    //           item.IsSelected = true
    //     }
    //   }
    //   return result
    // })
    setDriverData(o => {
      return setSelectAll([...o], true, true)
    })
  }, [setSelectAll])

  const handleDeselectAll = useCallback(() => {
    setDriverData(o => {
      return setSelectAll([...o], false)
    })
  }, [setSelectAll])

  const handlePrintSelected = useCallback(() => {
    const filteredData = driverData.reduce((prev, cur) => {
      for (const id of [1, 2, 3]) {
        for (const jo of cur[id]) {
          if (jo.IsSelected) {
            prev.push(jo)
          }
        }
      }
      return prev
    }, [])
    if (filteredData.length === 0) {
      alertError("กรุณาเลือกอย่างน้อย 1 ใบงาน")
      return
    }
    handlePrint(filteredData)
  }, [driverData, handlePrint])

  const handlePrintDriverTable = useCallback(async () => {
    let i = 0;
    // const substr2 = (str)=>str.length>2?str.substr(0, 2): str
    const substr3 = (str) => str.length > 3 ? str.substr(0, 3) : str
    const substr4 = (str) => str.length > 5 ? str.substr(0, 4) : str
    // const substr6 = (str)=>str.length>5?str.substr(0, 6): str
    const readItem = (item) => {
      // console.log("item.ContSizeFull::", item.ContSizeFull)
      return `${item.TagIds.includes(10) ? "(ย)" : ""}${substr3(item.Agnt)} ${locationText(item, { numChar: 6 })} ${substr4(item.ContSizeFull)}`
    }

    let minusDay = -1
    if (aptTm.day() === 1) {
      minusDay = -2
    }
    const dxData = await ax.post(dailyExpApiNm.getDailyExpense, {
      ExpDteSt: aptTm.add(minusDay, "day").format("YYYY-MM-DD 00:00:00"),
      ExpDteEn: aptTm.add(minusDay, "day").format("YYYY-MM-DD 23:59:59.997"),
      DscpExat: "ทางด่วน"
    })
    console.log("dxData::", dxData)
    if (!dxData.data) {
      alertWarning("ไม่สามารถหาค่าทางด่วนได้");
    } else {
      for (const drv of driverData) {
        const found = dxData.data.find(dx => dx.DrvId === drv.DrvId)
        drv.Toll = found ? numberFormatNoFlotingPoint(+found.Amnt * -1) : " "

      }
    }

    const selectedTagIds = tagData.map(tag => tag.id)

    const dataRows = driverData.reduce((prev, cur) => {
      if (selectedTagIds.some(id => cur.TagIds.includes(id)) || (selectedTagIds.length === 0 && cur.TagIds.length === 0)) {
        i++;
        let dxAmnt = 0
        for (const id of [1, 2, 3]) {
          for (const jo of cur[id])
            if (!jo.TagIds.includes(10))
              dxAmnt += +jo.DXAmnt
        }
        const data = {
          id: i,
          No: `${i}`,
          NName: `${cur.NName} ${cur.TukCd}`,
          BL: cur[1].map(item => readItem(item)).join("\n") || " ",
          BB: cur[2].map(item => readItem(item)).join("\n") || " ",
          LB: cur[3].map(item => readItem(item)).join("\n") || " ",
          DX: dxAmnt === 0 ? " " : numberFormatNoFlotingPoint(dxAmnt * -1),
          Toll: cur.Toll,
          isBold: false
        }
        prev.push(data)
      }
      return prev
    }, [])

    const extraData = {
      title: [
        { header: "ประจำวันที่ :", value: aptTm.format("DD/MM/YYYY"), headerWidth: 1 },
      ],
      top: [],
      bottom: []
    }
    printDataTable({
      title: "รายงานการวิ่งตู้เปล่า",
      dataTable: dataRows,
      companyData: msData.serverData.CompanyData,
      columnHeader: printColumnHeader(msData),
      extraData: extraData,
      maxPageSize: 27,
      tableFontSize: 14,
      marginLeft: -0.25
    })
  }, [driverData, aptTm, msData, ax, tagData])

  const handlePrintJobOrderNoDriver = useCallback(() => {
    const dataRows = []

    let no = 0;
    for (const id of [1, 2, 3, 4, 5]) {
      let sumTag = 0;
      let tagStr = msData.tags.find(tag => tag.TagId === id)?.Name || "-"
      for (const data of jobData[id]) {
        no++
        sumTag += data.JobOrdIds.length
        dataRows.push({
          No: no,
          id: data.JobOrdId,
          Tag: tagStr,
          Bkg: data.Bkg,
          Agnt: data.Agnt,
          ContSizeFull: data.ContSizeFull,
          Loc: locationText(data),
          TakeDteSt: data.TakeDteSt ? dayjs(data.TakeDteSt).format("DD/MM") : "-",
          TakeDteEn: data.TakeDteEn ? dayjs(data.TakeDteEn).format("DD/MM") : "-",
          IsRdyTake: data.IsRdyTake ? "พร้อม" : "-",
          Count: `${data.JobOrdIds.length}`
        })
      }
      no++
      dataRows.push({
        No: no,
        id: id * 10 * -1,
        Tag: tagStr,
        Bkg: "-",
        Agnt: "-",
        ContSizeFull: "-",
        Loc: "รวม ",
        TakeDteSt: "-",
        TakeDteEn: "-",
        IsRdyTake: "-",
        Count: `${sumTag}`,
        isBold: true
      })
    }
    const extraData = {
      title: [
        { header: "อัพเดทล่าสุด :", value: dayjs().format("DD/MM/YYYY HH:mm"), headerWidth: 1 },
      ],
      top: [],
      bottom: []
    }
    printDataTable({
      title: "รายงานตู้คงเหลือ",
      dataTable: dataRows,
      companyData: msData.serverData.CompanyData,
      columnHeader: printJobColumnHeader,
      extraData: extraData,
      maxPageSize: 27
    })
  }, [jobData, msData.serverData.CompanyData, msData.tags])

  const handlePrintEmptyJobOrder = useCallback(() => {
    const printDataArr = []
    const isPrintDateTime = { date: false, time: false }
    const jobOrderData = {
      JobOrdId: "",
      ContNo: "",
      SealNo: "",
      ContSize: "",
      TakePlc: "",
      RtnPlc: "",
      Loc: "",
      Rmk: "",
      DXAmnt: "",
    }
    const shipmentData = {
      ShpmTypNm: "",
      CusNm: "",
      JobNo: "",
      Bkg: "",
      Agnt: "",
      Job: { RcptTaxId: "", RcptNm: "", RcptAddr: "" }
    }
    printDataArr.push({ jobOrderData, shipmentData, jobOrdIds: [""], isPrintDateTime, fName: " " })
    printJobOrder({ printDataArr, msData })
  }, [msData])

  const handleGroupLocJobDataChange = useCallback((e) => {
    setGroupLocJobData(e.target.checked)
    if (!e.target.checked) {
      setJobData(originalJobData)
    } else {
      setJobData(o => {
        originalJobData = { ...o }
        const returnData = { ...o }

        for (const id of [1, 2, 3, 4, 5]) {
          const result = []
          for (const jo of returnData[id]) {
            const found = result.find(item => isSamePlace(item, jo))
            if (found) {
              found.Bkg = jo.Bkg === found.Bkg ? jo.Bkg : `${found.Bkg}, ${jo.Bkg}`
              found.Agnt = jo.Agnt === found.Agnt ? jo.Agnt : `${found.Agnt}, ${jo.Agnt}`
              found.ContSizeFull = jo.ContSizeFull === found.ContSizeFull ? jo.ContSizeFull : `${found.ContSizeFull}, ${jo.ContSizeFull}`
              found.TakeDteSt = null
              found.TakeDteEn = null
              found.IsRdyTake = jo.IsRdyTake && found.IsRdyTake
              found.JobOrdIds = [...found.JobOrdIds, ...jo.JobOrdIds]
              // result.push( =
              //   ...jo,
              //   Bkg: jo.Bkg === found.Bkg? jo.Bkg: `${found.Bkg}, ${jo.Bkg}`,
              //   Agnt: jo.Agnt === found.Agnt? jo.Agnt: `${found.Agnt}, ${jo.Agnt}`,
              //   ContSizeFull: jo.ContSizeFull === found.ContSizeFull? jo.ContSizeFull: `${found.ContSizeFull}, ${jo.ContSizeFull}`,
              //   TakeDteSt: "-",
              //   TakeDteEn: "-",
              //   IsRdyTake: jo.IsRdyTake && found.IsRdyTake,
              //   JobOrdIds: [...found.JobOrdIds, ...jo.JobOrdIds]
              // })
            } else {
              result.push({ ...jo, disableArrow: true })
            }
          }
          returnData[id] = result
        }
        return returnData
      })
    }

  }, [])

  const handleBoxClick = useCallback((bkg) => (e) => {
    setDriverData(o => {
      const result = [...o]
      for (const drv of result) {
        for (const id of [1, 2, 3]) {
          for (const jo of drv[id]) {
            jo.hilightBkg = bkg
          }
        }
      }
      return result
    })
  }, [])

  useEffect(() => {
    doGetDriverData(dayjs())
  }, [ax, doGetDriverData])

  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();

      console.log('beforeunload event triggered');

      if (addList.length > 0 || deleteList.length > 0)
        return (event.returnValue = 'Changes you made may not be saved !!');
      else {
        window.removeEventListener('beforeunload', handleTabClose);
        return ''
      }
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  console.log("on render jobData::", jobData)
  console.log("on render driverData::", driverData)
  console.log("tagData::", tagData)
  return (
    <BoxFC px={2} height="100%">
      <BoxFR height="100%" sx={{ alignItems: "stretch" }}>
        <BoxFC sx={{ gap: 1, overflowY: "scroll", pr: 1, width: 750 }}>
          <BoxFR>
            <Button variant="contained" onClick={handlePrintJobOrderNoDriver} sx={{ width: 220 }}>
              <PrintRoundedIcon sx={{ mr: 1 }} /> พิมพ์รายงานตู้คงเหลือ
            </Button>
            <CheckobxFormControl checked={groupLocJobData} onChange={handleGroupLocJobDataChange} label="รวมสถานที่" />
            <Box flex={1} />
            <Button variant="contained" onClick={handlePrintEmptyJobOrder} sx={{ width: 220 }}>
              <PrintRoundedIcon sx={{ mr: 1 }} /> พิมพ์ใบงานเปล่า
            </Button>
          </BoxFR>
          {/* <Box width={300} height={500} border="1px solid black" >asfl;kj<br />asdfasdf</Box>
          <Box width={300} height={500} border="1px solid black" >asfl;kj<br />asdfasdf</Box>
          <Box width={300} height={500} border="1px solid black" >asfl;kj<br />asdfasdf</Box> */}
          <JobBox tagId={1} data={jobData} handleSelect={handleSelect} />
          <JobBox tagId={2} data={jobData} handleSelect={handleSelect} />
          <JobBox tagId={3} data={jobData} handleSelect={handleSelect} />
          <JobBox tagId={5} data={jobData} handleSelect={handleSelect} />
          <JobBox tagId={4} data={jobData} handleSelect={handleSelect} disableArrow={true} />
          {
            jobData[6].length > 0 &&
            <JobBox tagId={6} data={jobData} handleSelect={handleSelect} disableArrow={true} />
          }
        </BoxFC>
        <Paper sx={{ flex: 1, p: 1 }}>
          <BoxFC height="100%" sx={{ gap: 1 }}>
            <BoxFR>
              <Typography variant="h6">ตารางงานประจำวันที่: </Typography>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                mask="__/__/____"
                value={aptTm}
                onChange={handleDateTimeChange}
                renderInput={(params) => <TextField size="small" {...params} sx={{ width: 150 }} />} />
              <Button variant="contained" onClick={() => getDriverData(aptTm)}>
                <RefreshIcon />
              </Button>

              <TagBox sx={{ flex: 1 }} value={tagData} setValue={setTagData} tblAbbr="DV" />
            </BoxFR>
            <BoxFR>
              <Typography>พนักงานขับรถที่เลือก {selectionModel.length} คน</Typography>

              <Button variant="contained" size="small" onClick={handleSelectNoMoveAll}>
                <SelectAllRoundedIcon />
                เลือกยกเว้นย้ายวัน
              </Button>
              <Button variant="contained" size="small" onClick={handleSelectAll}>
                <SelectAllRoundedIcon />
                เลือกทั้งหมด
              </Button>
              <Button variant="contained" size="small" onClick={handleDeselectAll}>
                <DeselectRoundedIcon />
                ไม่เลือกทั้งหมด
              </Button>

              <Button variant="contained" size="small" onClick={handlePrintSelected}>
                <PrintRoundedIcon />
                พิมพ์ใบงานที่เลือก
              </Button>


              <Button variant="contained" onClick={handlePrintDriverTable}>
                <PrintRoundedIcon /> พิมพ์งานประจำวัน
              </Button>
              <Button variant="contained" onClick={() => setDialogSummaryOpen(true)}>
                <SummarizeRoundedIcon /> รายงานสรุปงาน
              </Button>
            </BoxFR>
            <Box flex={1} minWidth={1000} sx={sxHover}>
              <DataGridCellExpand
                getRowHeight={() => 'auto'}
                disableSelectionOnClick
                selectionModel={selectionModel}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                hideFooter
                rows={filteredDriverData}
                columns={driverColumns(handleRemoveJobOrder, handleChangeAptTm, handleCheckboxChange, handleBoxClick, msData)} />
            </Box>
            <BoxFR>
              <Button sx={{ width: 200 }} variant="contained" onClick={handleSaveAndPrint}>
                <PrintRoundedIcon sx={{ mr: 1 }} />
                บันทึกและพิมพ์ใบงาน
              </Button>
              <Box flex={1} />
              <SaveButton sx={{ width: 200 }} onClick={handleSave} />
            </BoxFR>
          </BoxFC>
        </Paper>
      </BoxFR>
      <AddDataDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        row={selectedRow}
        confirmSelect={confirmSelect} />
      <ChangeDateDialog
        dialogOpen={dialogChangeDateOpen}
        setDialogOpen={setDialogChangeDateOpen}
        jobOrdId={changeAptTmJobOrdId}
        confirmChangeAptTm={confirmChangeAptTm} />

      <SummaryDialog dialogOpen={dialogSummaryOpen} setDialogOpen={setDialogSummaryOpen} aptTm={aptTm} jobOrderData={originalDailyJobData} />
    </BoxFC>
  )
}

export default JobPlanning