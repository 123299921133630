import { Switch, FormControlLabel } from "@mui/material";
import React from "react";

const SwitchFormControl = ({sx, label, checked, onChange}) => {

  return (
    <FormControlLabel sx={{...sx, m:0, verticalAlign:"middle"}}
      label={label}
      control={
        <Switch 
          checked={checked}
          onChange={onChange}
        />
      }
    />
  )
}

export default React.memo(SwitchFormControl)