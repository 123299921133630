import { accountApiNm } from "constants/accountConstant";
import { useEffect, useContext, useState, useCallback } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { initDialogData } from "./initData";
import { alertConfirmDelete, alertError, alertSuccess } from "components/Alert";
import { UserContext } from "contexts/UserContext";
import { addIdForDataGrid, addNoDuplicate } from "utils";

let lastAccountDataStr = "";
let lastPmDataStr = "";
let lastSelectedJobDataStr = "";

const useAccountDialog = ({ dialogOpen, selectedAccId, onFinish, mode }) => {
  const { ax, msData } = useContext(GlobalStateContext);
  const { user, setUser} = useContext(UserContext)

  const [dialogData, setDialogData] = useState(initDialogData)
  const [pmData, setPmData] = useState([])
  const [password, setPassword] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [jobCombo, setJobCombo] = useState([])
  const [selectedCusId, setSelectedCusId] = useState(null) 
  const [selectedJobId, setSelectedJobId] = useState(null) 
  const [selectedJobData, setSelectedJobData] = useState([])

  const pmValue = useCallback((pmId, mode) => {
    const found = pmData.find(item => item.PmId === pmId)
    if (!found) return false
    if (found.Mode >= mode) return true
    else return false
  }, [pmData])

  const pmChange = useCallback((pmId, mode, value) => {
    setPmData(o => {
      const found = o.find(item => item.PmId === pmId)
      if (!found) {
        return [...o,{PmId: pmId, AccId: selectedAccId, Mode: value?mode:0}]
      }
      let tmpMode = 0
      if (mode === 2 && value === true) tmpMode = 2
      else if (mode === 2 && value === false) {
        if (found.Mode >= 1) tmpMode = 1
        else tmpMode = 0
      } else if (mode === 1 && value === true) tmpMode = 1
      else tmpMode = 0
      found.Mode = tmpMode
      return [...o]
    })
  }, [selectedAccId])

  const insertAccount = useCallback(() => {
    const postData = { ...dialogData }
    postData.Pms = pmData.map(item => ({ PmId: item.PmId, AccId: item.AccId, Mode: item.Mode }))
    postData.JobIds = selectedJobData.map(item => item.id)
    postData.AccTypId = mode
    ax.post(accountApiNm.addNewUserAccount, postData).then(value => {
      if (value.data) {
        alertSuccess(value.data)
        onFinish()
      }
    })
  }, [ax, mode, selectedJobData, dialogData, pmData, onFinish])

  const UpdateAccount = useCallback(async () => {
    const dialogDataStr = JSON.stringify(dialogData)
    let done = true
    if(dialogDataStr !== lastAccountDataStr){
      const result = await ax.post(accountApiNm.updateAccount, dialogData)
      if(!result) done = false
      if(user.AccId === selectedAccId){
        setUser(o=>({...o, ...dialogData}))
      }
    } 
    const pmDataStr = JSON.stringify(pmData)
    if(pmDataStr !== lastPmDataStr){
      const postData = {AccId: selectedAccId, Pms: pmData}
      const result  = await ax.post(accountApiNm.updateAccountPermission, postData)
      
      if(!result) done = false
    }
    const selectedJobDataStr = JSON.stringify(selectedJobData)
    if(selectedJobDataStr !== lastSelectedJobDataStr){
      const postData = {AccId: selectedAccId, JobIds: selectedJobData.map(job=>job.id)}
      const result  = await ax.post(accountApiNm.updateAccountJob, postData)
      if(!result) done = false
    }

    if(done) onFinish()

  }, [ax, dialogData, pmData, selectedJobData, selectedAccId, onFinish, setUser, user.AccId])
  const insertOrUpdate = useCallback(() => {
    if (selectedAccId === 0) insertAccount()
    else UpdateAccount()
  }, [selectedAccId, insertAccount, UpdateAccount])


  const deleteAccount = useCallback(() => {
    alertConfirmDelete(() => {
      ax.post(accountApiNm.deleteAccount, { AccId: selectedAccId }).then(value => {
        if (value.data) {
          onFinish()
        }
      })
    })
  }, [ax, onFinish, selectedAccId])

  const handleChangePassword = useCallback(()=>{
    if(password !== confirmPassword){
      alertError("ยืนยันพาสเวิร์ดไม่ตรง")
      return
    }
    
    ax.post(accountApiNm.changePassword, {AccId: selectedAccId, OldPass: oldPassword, NewPass: password}).then(value=>{
      if(value.data){
        alertSuccess("เปลี่ยนพาสเวิร์ดใหม่เรียบร้อยแล้ว")
        setPassword("")
        setOldPassword("")
        setConfirmPassword("")
      }
    })
  }, [ax, password, confirmPassword, oldPassword, selectedAccId])

  const handleSetNewPassword = useCallback(()=>{
    ax.post(accountApiNm.setNewPassword, {AccId: selectedAccId, Pass: password}).then(value=>{
      if(value.data){
        alertSuccess("กำหนดพาสเวิร์ดใหม่เรียบร้อยแล้ว")
        setPassword("")
      }
    })
  }, [ax, password, selectedAccId])

  const handleAddJob = useCallback(()=>{
    setSelectedJobData(o=>{
      const job = jobCombo.find(item=>item.id === selectedJobId)
      return addIdForDataGrid(addNoDuplicate(o, [job], "id"), "id")
    })
  }, [selectedJobId, jobCombo])

  const handleAddJobAll = useCallback(()=>{
    setSelectedJobData(o => addIdForDataGrid(addNoDuplicate(o, jobCombo, "id"), "id"))
  }, [jobCombo])

  const handleDeleteRow = useCallback((id) => (e) => {
    e.stopPropagation();
    setSelectedJobData(o => addIdForDataGrid(o.filter(item=>item.id !== id), "id"))
  }, [])

  useEffect(() => {
    if (dialogOpen && selectedAccId > 0) {
      ax.post(accountApiNm.getAccount, { AccId: selectedAccId, WithDtl: 1 }).then(value => {
        if (value.data) {
          const account = value.data[0]
          const pms = [...(account.AccPms || [])]
          setPmData(pms)
          const jobs = (account.AccJobs && account.AccJobs.map(item=>({id:item.JobId, label: item.JobSNm, refId: item.CusId}))) || []
          const jobTable = addIdForDataGrid(jobs, "id")
          setSelectedJobData(jobTable)
          delete account.AccPms
          lastAccountDataStr = JSON.stringify(account)
          lastPmDataStr = JSON.stringify(pms)
          lastSelectedJobDataStr = JSON.stringify(jobTable)
          setDialogData(account)
        }
      })
    } 
  }, [ax, selectedAccId, dialogOpen])

  useEffect(() =>{
    if(!dialogOpen){
      setDialogData(initDialogData)
      setPmData([])
      setSelectedJobData([])
      setPassword("")
      setOldPassword("")
      setConfirmPassword("")
    }
  }, [dialogOpen])
  return {
    state: { msData, dialogData, password, oldPassword, confirmPassword, selectedCusId, selectedJobId
      , selectedJobData, jobCombo },
    fn: { setDialogData, pmValue, pmChange, insertOrUpdate, deleteAccount, setSelectedCusId, setSelectedJobId
      , setJobCombo , setPassword, setOldPassword, setConfirmPassword, handleChangePassword, handleSetNewPassword
      , handleAddJob, handleAddJobAll, handleDeleteRow }
  }
}

export default useAccountDialog