const closingColor = {
  "& .container-closing-red": {
    bgcolor: "salmon!important",
    "&:hover": {
      bgcolor: "darkSalmon!important"
    }
  },
  "& .container-closing-yellow": {
    bgcolor: "khaki!important",
    "&:hover": {
      bgcolor: "darkKhaki!important"
    }
  },
}

export default closingColor