
import React, { useContext, useEffect, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import { loadMSData } from "utils";
import { Box } from "@mui/system";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import ClientToday from "pages/Client/ClientToday/ClientToday";
import ClientDataQuery from "pages/Client/ClientDataQuery/ClientDataQuery";
import ClientContainerStatus from "pages/Client/ClientContainerSummary/ClientContainerStatus";
import { Redirect } from "react-router-dom";
import Loading from "components/Loading";
import { Container, AppBar } from '../appBarStyle'
import ContainerClosingSummary from "pages/Menu1/ContainerClosingSummary/ContainerClosingSummary";
import { extraRoute } from "branch/pages/Client/extraMenuData";
import FiveYearReport from "pages/Client/ClientReports/FiveYearReport/FiveYearReport";
import ClientInvoice from "pages/Client/ClientInvoice/ClientInvoice";
import InvoiceReport from "pages/Client/ClientReports/InvoiceReport/InvoiceReport";
import DesktopToolbar from "./DesktopToolbar";
import MobileToolbar from "./MobileToolbar";
import MonthlyReport from "pages/Client/ClientReports/MonthlyReport/MonthlyReport";
import dayjs from "dayjs";

const menuData = [
  { path: "/client/Today", label: "Today" },
  { path: "/client/DataQuery", label: "Data Query" },
  { path: "/client/ClientInvoice", label: "Invoice", pms: ["FIN"] },
  {
    path: "/client/Reports", label: "Reports", subMenu: [
      { path: "/client/Reports/ContainerStutus", label: "Container Status" },
      { path: "/client/Reports/ContainerClosingSummary", label: "Container Cut-Off" },
      { path: "/client/Reports/MonthlyReport", label: "Monthly Report" },
      { path: "/client/Reports/FiveYearReport", label: "5 Years Report" },
      { path: "/client/Reports/InvoiceReport", label: "Invoice Report", pms: ["FIN"] },
    ]
  }
]
const ClientLayout = () => {

  const { user, setUser } = useContext(UserContext);
  const { isLoading, ax, msData, setMSData } = useContext(GlobalStateContext);

  const w800 = useMediaQuery('(min-width:800px)');


  const pms = useMemo(() => {
    return (user?.Pms?.map(pm => pm.Code)) || []
  }, [user])


  useEffect(() => {
    loadMSData(ax, setMSData, setUser)
  }, [setMSData, setUser, ax])

  useEffect(() => {
    dayjs.locale('en')
  }, [])
  console.log("ClientLayout pms::", pms)
  return (

    msData &&
    <BoxFC>
      <AppBar position="fixed">
        {w800?<DesktopToolbar menuData={menuData} />:<MobileToolbar menuData={menuData} />}
      </AppBar>
      <Container>
        <Box component="main" height="100%" width="100%" sx={{  p: 1, mt: 1 }}>
          <Switch>
            <Route path={`/client/Today`}><ClientToday /></Route>
            <Route path={`/client/DataQuery/:mField/:mValue`} ><ClientDataQuery /> </Route>
            <Route path={`/client/DataQuery`} ><ClientDataQuery /> </Route>
            <Route path={`/client/ClientInvoice/:mField/:mValue`} ><ClientInvoice /> </Route>
            <Route path={`/client/ClientInvoice`} ><ClientInvoice /> </Route>
            <Route path={`/client/Reports/ContainerStutus`} ><ClientContainerStatus /> </Route>
            <Route path={`/client/Reports/ContainerClosingSummary`} ><ContainerClosingSummary /> </Route>
            <Route path={`/client/Reports/MonthlyReport`} ><MonthlyReport /> </Route>
            <Route path={`/client/Reports/FiveYearReport`} ><FiveYearReport /> </Route>
            <Route path={`/client/Reports/InvoiceReport`} ><InvoiceReport /> </Route>
            {
              extraRoute.map(item => item)
            }
            <Route path={`*`}><Redirect to="/404" /></Route>
          </Switch>
        </Box>
      </Container>
      {
        isLoading && <Loading />
      }
    </BoxFC>
  )
}

export default ClientLayout