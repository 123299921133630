import { IconButton, Tooltip } from "@mui/material"

const { ArticleRounded } = require("@mui/icons-material")
const dayjs = require("dayjs")
const { numberFormat } = require("utils")

export const columns = (handleOpenDialog) => [
  {
    field: "Detail",
    headerName: "",
    width: 30,
    renderCell: (params) => params.id === 0? "": (
      <Tooltip title="Detail" followCursor placement="top">
        <IconButton onClick={() => handleOpenDialog(params.row)}>
          <ArticleRounded color="primary"/>
        </IconButton>
      </Tooltip>
    )
  },
  {
    field: 'No',
    headerName: "No.",
    width: 60,
    align: 'center',
  },
  {
    field: 'JobSNm',
    headerName: "Job Name",
    width: 110,
  },
  {
    field: 'InvDte',
    headerName: "Inv.Date",
    width: 110,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value).format("YYYY-MM-DD"),
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-",
  },
  {
    field: 'InvNo',
    headerName: "Inv. No.",
    width: 120,
  },
  {
    field: 'BNNo',
    headerName: "BN No.",
    width: 140,
  },
  {
    field: 'JobNo',
    headerName: "Job No.",
    width: 120,
  },
  {
    field: 'Bkg',
    headerName: "Booking",
    width: 120,
  },
  {
    field: 'ContNo',
    headerName: "Container No.",
    width: 120,
    align: "center"
  },
  {
    field: 'TrnsAmnt',
    headerName: "Trans. Charge",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'OthChrg',
    headerName: "Other charge",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'CusRcptAmnt',
    headerName: "Advance",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'Total',
    headerName: "Total",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",

  },
  {
    field: 'WhtPr',
    headerName: "WHT.",
    width: 80,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'PayTotal',
    headerName: "Payment Total",
    width: 110,
    align: "right",
    valueGetter: (params) => +params.row.Total - +params.row.WhtPr,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'SndDocDte',
    headerName: "Send Doc. Date",
    width: 120,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value).format("YYYY-MM-DD"),
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-",
  },
  {
    field: "NumFile",
    headerName: "Files",
    align: "center",
    valueFormatter: (params) =>  params.value== null?"": params.value.toString(),
    width: 60,
  },
  {
    field: 'Rmk',
    headerName: "Remark",
    minWidth: 150,
    flex: 1,
  }
]

export const expenseColumns = [
  {
    field: 'No',
    headerName: "No.",
    width: 60,
    align: 'center',
  },
  {
    field: 'ExpNmEn',
    headerName: "Detail",
    width: 180,
  },
  {
    field: 'UntPr',
    headerName: "Unit Price",
    width: 120,
  },
  {
    field: 'Qty',
    headerName: "Qty",
    align: "center",
    width: 80,
  },
  {
    field: 'Total',
    headerName: "Total",
    width: 120,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'Rmk',
    headerName: "Remark",
    flex: 1,
  }
]