import React from "react";
import { Button } from '@mui/material'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';


const SearchButton = props => {

    return (
        <Button
            variant="contained"
            startIcon={<SearchRoundedIcon />}
            color="primary"
            sx={{minWidth: 80}}
            onClick={props.onClick}>
            ค้นหา
        </Button>
    )
}

export default SearchButton