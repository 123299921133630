import { Box } from "@mui/system"
import { styled } from '@mui/material/styles';

const BoxFilter = styled(Box)({
    marginLeft: 8,
    display: 'flex',
    gap: 8,
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    minWidth: 0
})

export default BoxFilter