import { useEffect, useContext } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";

const useDialogOpen = (dialogOpen, selectedId, apiName, key, setDialogData, initDialogData, modifyData) =>{

  const { ax } = useContext(GlobalStateContext);

  
  useEffect(() => {
    console.log('[dialogOpen, selectedId]', dialogOpen, selectedId)
    if (dialogOpen ) {
      if(selectedId > 0){
        ax.post(apiName, {[key]: selectedId}).then(value=>{
          if(modifyData) {
            setDialogData(modifyData(value.data[0]))
          } else {
            setDialogData(value.data[0])
          }
        })
      } else {
        setDialogData({...initDialogData})
      }
    }  else {
      setDialogData({...initDialogData})
    }
  }, [dialogOpen, selectedId, apiName, key, setDialogData, initDialogData, ax, modifyData])

}

export default useDialogOpen