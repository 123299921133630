import { Box } from "@mui/system"
import { styled } from '@mui/material/styles';

const BoxFRmt1 = styled(Box, {skipSx:false})(({theme})=>({
    display: 'flex',
    flexDirection: "row",
    gap: theme.spacing(2),
    marginTop: theme.spacing(1)
}))

export default BoxFRmt1