import dayjs from "dayjs"

export const clearFilterData = {
  AptTm: null,
  DrvId: null,
  TlrTyp: null,
  JobId: null,
  CusId: null,
}
export const initFilterData = {
  ...clearFilterData,
  AptTm: dayjs(dayjs().add(-1, "month").format("YYYY-MM-01 00:00:00.000"))
}

export const initJobDriverPayFilterData = {
  JobId: null,
  CusId: null
}

export const initDialogData = {
  JobDrvPayId: null,
  JobId: null,
  Amnt: null,
  ExtAmnt: null,
  ExtTxt: "",
  ContSize: null,
  ContTyp: null,
  TlrTypId: null,
  Prty: 100,
  Rmk:""
}

export const initDriverIncomeDialogData = {
  DrvIncId: 0,
  JobOrdId: null,
  DrvId: null,
  AptTm: null,
  JoAptTm: null,
  FinDte: null,
  JoFinDte: null,
  TakePlc: "",
  Loc: "",
  RtnPlc: "",
  ContSize: "",
  ContTyp: "",
  TlrTypId: null,
  JoRmk: "",
  Amnt: null,
  ExtAmnt: null,
  ExtTxt: null,
  Rmk: null,
}