import React, { useEffect, useState, useCallback } from "react";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { Box, Dialog, DialogContent, TextField, Typography } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import ClearButton from "components/buttons/ClearButton";
import PaperComponent from "components/PaperComponent";
import dayjs from "dayjs";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";

const UpdateDateTimeDialog = ({ jobOrdIds, colName, oldData, updateColumn, setDialogOpen, dialogOpen }) => {
  const [colData, setColData] = useState("")


  const datePickerProp = useCallback((name, isRequire) => ({
    label: jobOrderColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: colData,
    onChange: (newValue) => { setColData(newValue) },
    renderInput: (params) => <TextField size="small" {...params} required={isRequire} error={isRequire && colData == null} sx={{ width: 200 }} />,

  }), [colData])

  useEffect(() => {
    if (dialogOpen) {
      if (oldData) {
        setColData(oldData)
      } else {
        setColData(dayjs())
      }
    }
  }, [oldData, dialogOpen])

  const handleUpdateColumn = () => {
    console.log("colData::", colData);
    const dateTime = colData && colData.format("YYYY-MM-DD HH:mm:00.000")
    updateColumn(colName, { [colName]: dateTime }, jobOrdIds)
  }
  console.log("in updateDateTime::")
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader  handleDialogClose={() => setDialogOpen(false)}
        title={`แก้ไขข้อมูล ${jobOrderColNm[colName]}`}
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} mt={2}>
          {jobOrdIds.length > 1 && <Typography variant="Body" >{`แก้ไขข้อมูลจำนวน ${jobOrdIds.length} แถว`}</Typography>}
          <Box display="flex" gap={2} justifyContent="center">
            <DateTimePickerCustomTime {...datePickerProp(colName, false)} />
            <ClearButton onClick={() => setColData(null)} />
          </Box>
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleUpdateColumn} />
    </Dialog>
  )
}

export default React.memo(UpdateDateTimeDialog, (o, n) => o.dialogOpen === n.dialogOpen)