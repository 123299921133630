import { Dialog, DialogContent, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";

import { BoxFC } from "components/BoxCustom";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { jobOrderApiNm } from "constants/jobOrderConstant";
import dayjs from "dayjs";

const JobOrderImageDialog = ({ dialogOpen, setDialogOpen, selectedId }) => {

  const { ax} = useContext(GlobalStateContext)

  const [jobOrdFiles, setJobOrdFiles] = useState([])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(jobOrderApiNm.getJobOrderFileTable, { JobOrdId: selectedId }).then(value => {
        if (value.data && value.data.length > 0) {
          const files = value.data[0].Files
          setJobOrdFiles(files)
        }
      })
    } else {
      setJobOrdFiles([])
    }
  }, [ax, selectedId, dialogOpen])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`รูปภาพ (${jobOrdFiles.length} รูป)`}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={1} sx={{ gap: 0 }}>
          
          <ImageList cols={2} rowHeight={600} sx={{ width: "100%", maxHeight: 630 }}>
            {jobOrdFiles.map(file => {
              
              const src = `/file/job-order/${selectedId}/${file.FileNm}`
              const linkUrl = src.replaceAll("/", "~")
              console.log("linkUrl::", linkUrl)
              return (
                <ImageListItem key={file.FileNm} sx={{ position: "relative" }} >
                  <img alt={file.Label} src={src} loading="lazy" onClick={() => window.open(`/image/${linkUrl}`)} style={{ height: 600 }} />
                  
                  <ImageListItemBar
                    sx={{ px: 6 }}
                    title={`${file.Label}`}
                    subtitle={
                      <BoxFC sx={{ gap: 0.5 }}>
                        <span>by: {file.DrvNNm} {dayjs(file.AddTm).format("DD/MM/YYYY HH:mm")}</span>
                      </BoxFC>
                    }
                  />
                </ImageListItem>
              )
            })}
          </ImageList>
          
        </BoxFC>
      </DialogContent>
      <DialogFooter okText=""
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(JobOrderImageDialog)