import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ComboBox from "components/ComboBox";
import DateTimeSelect from "components/DateTimeSelect";
import SearchButton from "components/buttons/SearchButton";
import { generateDriverIncomeReportColumns, printColumnHeader } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import { addIdForDataGrid, numberFormatNoFlotingPoint } from "utils";
import dayjs from "dayjs";
import { driverIncomeApiNm } from "constants/driverIncomeConstant";
import { alertConfirmDelete, alertError } from "components/Alert";
import printDataTable from "utils/printDataTable";
import { useMemo } from "react";
import AddButton from "components/buttons/AddButton";
import DriverIncomDialog from "pages/Menu3/DriverIncome/DriverIncomDialog";
import { getPOIPlace } from "utils/getPOIPlace";
import { locationText } from "utils/locationText";

let lastFilter = null
const DriverIncomeReportDialog = ({ dialogOpen, setDialogOpen }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [finDte, setFinDte] = useState({ st: null, en: null })
  const [aptTm, setAptTm] = useState({ st: null, en: null })
  const [selectedDrvId, setSelectedDrvId] = useState(null)
  const [dialogDriverIncomeOpen, setDialogDriverIncomeOpen] = useState(false)

  const driverIncomeReportColumns = useMemo(()=>{
    const handleDelete = (id) =>{
      alertConfirmDelete(()=>{
        const postData = {
          DrvIncId: id,
          getArgs: lastFilter
        }
        ax.post(driverIncomeApiNm.deleteDriverIncome, postData).then((value)=>{
          if(value.data && Array.isArray(value.data)){
            setDataTable(addIdForDataGrid(value.data, "DrvIncId"))
          }
        })
      })
    }
    return generateDriverIncomeReportColumns(handleDelete)
  }, [ax])

  const sumAmnt = useMemo(() => {
    const result = dataTable.reduce((prev, cur) => {
      return prev + +cur.Amnt + +cur.ExtAmnt
    }, 0)
    return numberFormatNoFlotingPoint(result)
  }, [dataTable])

  const getData = useCallback(({finDte, aptTm, selectedDrvId}) => {
    const postData = {
      AptTmSt: aptTm.st,
      AptTmEn: aptTm.en,
      FinDteSt: finDte.st,
      FinDteEn: finDte.en,
      DrvId: selectedDrvId
    }
    ax.post(driverIncomeApiNm.getDriverIncome, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "DrvIncId"))
        lastFilter = postData
      }
    })
  }, [ax])

  const handleClose = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])

  const setSelectAptTm = useCallback((start, end) => {
    setAptTm({ st: start ? dayjs(start) : null, en: end ? dayjs(end) : null })
  }, [])

  const setSelectFinDte = useCallback((start, end) => {
    setFinDte({ st: start ? dayjs(start) : null, en: end ? dayjs(end) : null })
  }, [])
  const handleDriverSelect = useCallback((id) => {
    setSelectedDrvId(id)
  }, [])

  const handlePrint = useCallback(() => {

    if (!selectedDrvId) {
      alertError("กรุณาเลือกพนักงานขับรถก่อน")
      return
    }
    const drv = msData.drivers.find(drv => drv.DrvId === selectedDrvId)
    const drvFullName = drv ? `${drv.FName} ${drv.LName}(${drv.NName})` : "-"
    const extraData = {
      title: [
        { header: "พนักงานขับรถ :", value: drvFullName, headerWidth: 1 },
        { header: "จากวันที่ :", value: finDte.st.format("DD/MM/YYYY"), headerWidth: 1 },
        { header: "ถึงวันที่ :", value: finDte.en.format("DD/MM/YYYY"), headerWidth: 1 },
        // { header: "รวมยอดค่าเที่ยว :", value: `${sumAmnt} บาท`, headerWidth: 1 },
      ],
      top: [],
      bottom: []
    }
    let sumAmntOnly = 0
    let sumExtAmnt = 0
    const printData = dataTable.map((row, index) => {
      let aptTm = dayjs(row.AptTm).format("DD/MM/YYYY")
      let finDte = row.FinDte ? dayjs(row.FinDte).format("DD/MM/YYYY") : "-"
      // if(index > 0){
      //   const lastAptTm =dayjs(dataTable[index-1].AptTm).format("DD/MM/YYYY")
      //   if(lastAptTm === aptTm) aptTm = ""
      // }
      sumAmntOnly += +row.Amnt
      sumExtAmnt += +row.ExtAmnt
      row.TakePlc = getPOIPlace(row, "TAKE")
      row.RtnPlc = getPOIPlace(row, "RTN")
      row.TakePlc = row.TakePlc.substr(0,7)
      row.RtnPlc = row.RtnPlc.substr(0,7)
      return {
        id: row.id,
        No: row.No.toString(),
        AptTm: aptTm,
        FinDte: finDte,
        Loc: locationText(row, {numChar: 7}),
        ContSizeFull: row.ContSizeFull || "-",
        ContNo: row.ContNo || "-",
        Amnt: numberFormatNoFlotingPoint(+row.Amnt),
        ExtAmnt: row.ExtAmnt ? numberFormatNoFlotingPoint(+row.ExtAmnt) : " ",
        ExtTxt: row.ExtTxt || " "
      }
    })
    printData.push({
      id: -1,
      No: "-",
      AptTm: "-",
      FinDte : "-",
      Loc: "รวมยอดค่าเที่ยวทั้งหมด",
      ContSizeFull: "-",
      ContNo: "-",
      Amnt: numberFormatNoFlotingPoint(sumAmntOnly),
      ExtAmnt: numberFormatNoFlotingPoint(sumExtAmnt),
      ExtTxt: `    ${sumAmnt} บาท`,
      isBold: true
    })
    printDataTable({
      title: "รายงานค่าเที่ยว",
      dataTable: printData,
      // dataTable: [{id: 1, No: "1"}, {id:2, No:"2"}],
      companyData: msData.serverData.CompanyData,
      columnHeader: printColumnHeader,
      extraData: extraData,
      maxPageSize: 28
    })
  }, [selectedDrvId, msData, finDte, dataTable, sumAmnt])


  const handleAddDriverIncome = useCallback(() => {
    setDialogDriverIncomeOpen(true)
  }, [])
  
  const onDriverIncomeFinish = useCallback((data)=>{
    if(data){
      setDataTable(addIdForDataGrid(data, "DrvIncId"))
    }
  }, [])

  const handleSearch = useCallback(()=>{
    getData({finDte, aptTm, selectedDrvId})
  }, [getData, finDte, aptTm, selectedDrvId])
  console.log("aptTm::", aptTm)
  useEffect(() => {
    if(dialogOpen){
      const finDte = {
        st: dayjs(dayjs().format("YYYY-MM-DD 00:00:00")),
        en: dayjs(dayjs().format("YYYY-MM-DD 23:59:59.997"))
      }
      const aptTm = {
        st: null,
        en: null
      }
      const selectedDrvId = msData.driverOnlyCombo[0]?.id
      setFinDte(finDte)
      setAptTm(aptTm)
      setSelectedDrvId(selectedDrvId)
      getData({finDte, aptTm, selectedDrvId})
    }
  }, [ax, dialogOpen, msData.driverOnlyCombo, getData])

  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' >
      <DialogHeader handleDialogClose={handleClose}
        title="รายงานค่าเที่ยวพนักงานขับรถ"
      />
      <DialogContent>
        <BoxFC mt={2} height={700}>
          <BoxFR>

            <AddButton onClick={handleAddDriverIncome}>เพิ่มค่าเที่ยว</AddButton>
            <Button variant="contained" onClick={handlePrint}><PrintRoundedIcon sx={{ mr: 1 }} />พิมพ์รายงาน</Button>
            <Box flex={1} />
            <DateTimeSelect
              sx={{ width: 160 }}
              label="วันที่ลงข้อมูลจบ"
              start={finDte.st}
              end={finDte.en}
              setSelectDate={setSelectFinDte} />
            <DateTimeSelect
              sx={{ width: 160 }}
              label="วันที่วิ่งงาน"
              start={aptTm.st}
              end={aptTm.en}
              setSelectDate={setSelectAptTm} />
            <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label="พนักงานขับรถ"
              selectedId={selectedDrvId} setSelectedId={handleDriverSelect} />
            <SearchButton onClick={handleSearch} />

          </BoxFR>

          <Box width="100%" flexGrow={1} >
            <DataGridCellExpand
              hideFooter
              rows={dataTable}
              columns={driverIncomeReportColumns}
              disableSelectionOnClick
            />
          </Box>
          <Typography variant="h6">จำนวนเงินรวม: {sumAmnt} บาท</Typography>
        </BoxFC>
        <DriverIncomDialog
          dialogOpen={dialogDriverIncomeOpen}
          setDialogOpen={setDialogDriverIncomeOpen}
          selectedId={0}
          onFinish={onDriverIncomeFinish} 
          getArgs={lastFilter}/>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={handleClose}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(DriverIncomeReportDialog)