
import { Paper, Typography } from "@mui/material";
import { blueGrey, red, yellow } from "@mui/material/colors";
import { Box } from "@mui/system";
import { BoxFC } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useContext } from "react";
import { jobColumns } from "./columns";


const getRowClassName = (params) => {
  if(params.row.TakeDteEn && dayjs(params.row.TakeDteEn).isBefore(dayjs())){
    return "red"
  } else if(params.row.TakeDteEn && dayjs(params.row.TakeDteEn).add(-3, "day").isBefore(dayjs())){
    return "yellow"
  } else if(params.row.TakeDteSt && dayjs(params.row.TakeDteSt).isAfter(dayjs())){
    return "grey"
  }else {
    return null
  }
}

const sxColor = {
  "& .grey": {bgcolor: blueGrey[100],'&:hover': {bgcolor: `${blueGrey[200]}!important`}},
  "& .yellow": {bgcolor: yellow[100],'&:hover': {bgcolor: `${yellow[200]}!important`}},
  "& .red": {bgcolor: red[100],'&:hover': {bgcolor: `${red[200]}!important`}}
}
const JobBox = ({ tagId,  data, handleSelect, disableArrow }) => {
  const {msData} = useContext(GlobalStateContext)

  const numCont = useMemo(()=>{
    return data[tagId].reduce((prev, cur) => prev + cur.JobOrdIds.length, 0)
  }, [data, tagId])

  const header = useMemo(()=>{
    return msData.tags.find(tag=>tag.TagId === tagId)?.Name || "ไม่มี Tag"
  }, [msData.tags, tagId])
  
  return (
    <Paper elevation={3} sx={{ p: 1, width: "100%", minWidth:600}}>
      <BoxFC height={400} sx={{ gap: 0 }}>
        <Typography variant="h6">{header} ({numCont}เที่ยว)</Typography>
        <Box flex={1} sx={sxColor}>
          <DataGridCellExpand
            hideFooter
            rows={data[tagId]}
            columns={jobColumns(handleSelect, disableArrow)} 
            getRowClassName={getRowClassName}/>
        </Box>
      </BoxFC>
    </Paper>
  )
}

export default JobBox