import { Box, Dialog, DialogContent, Paper, Typography } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { jobOrderApiNm } from "constants/jobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState, useMemo, useRef } from "react"
import dayjs from "dayjs"
import { modifyExpenseItem } from "./ExpenseItem"
// import { UserContext } from "contexts/UserContext"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { addIdForDataGrid, numberFormat } from "utils"
import DailyExpense from "./JobOrderTransferComponent/DailyExpense"
import { driverExpenseColumns } from "./JobOrderTransferComponent/columns"
import JobOrderData from "./JobOrderTransferComponent/JobOrderData"
import { dailyExpApiNm } from "constants/dailyExpenseConstant"
import DailyExpenseDialog from "../DailyExpense/DailyExpenseDialog"

const initFilterData = {
  ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
  ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59"),
  OrderBy: "ModDte",
  OrderByDirection: "DESC"
}

let selectedDalyExpId = null

let selectedJobOrdId = null

let lastFilter = null

let dataHasChagne = false

// const setDataHasChange = ()=> {dataHasChagne = true}

const JobOrderTransferDialog = ({ drvId, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dailyExpenseData, setDailyExpenseData] = useState([])
  const [driverExpenseData, setDriverExpenseData] = useState([])
  const [joData, setJOData] = useState([])
  const [dialogDailyExpenseOpen, setDialogDailyExpenseOpen] = useState(false)
  // const [expenseJobOrdId, setExpenseJobOrdId] = useState("")

  const [filterData, setFilterData] = useState({ ...initFilterData })

  const joDataRef = useRef()

  // const [joData, setJOData] = useState({ ...initPageData })


  // const { user } = useContext(UserContext)

  const selectedDriver = useMemo(() => {
    console.log('drvId', drvId)
    return msData.drivers.find(d => d.DrvId === drvId)
  }, [msData, drvId])

  const sumDriverExpense = useMemo(() => {
    return driverExpenseData.reduce((prev, cur) => prev + +cur.Amnt, 0)
  }, [driverExpenseData])
  
  const driverFullName = useMemo(() => `${selectedDriver?.FName} ${selectedDriver?.LName} (${selectedDriver?.NName})`
    , [selectedDriver])


  const setAllData = useCallback((data) => {
    const driverExpenses = modifyExpenseItem(data.driverExpenses || [])
    const dailyExpenses = modifyExpenseItem(data.dailyExpenses || [])

    const jobOrders = data.jobOrders.map(jobOrder => {
      for (const contSts of jobOrder.ContStses) {
        if (contSts.DrvId === drvId && !contSts.IsCmpt) {
          jobOrder.IsCurJob = 1
          return jobOrder
        }
      }
      jobOrder.IsCurJob = 0
      return jobOrder
    })


    setJOData(addIdForDataGrid(jobOrders, "JobOrdId"))
    setDriverExpenseData(addIdForDataGrid(driverExpenses, "DalyExpId"))
    setDailyExpenseData(addIdForDataGrid(dailyExpenses, "DalyExpId"))

  }, [drvId])
  const getData = useCallback(() => {
    const postData = {
      DrvId: drvId,
      ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
      ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59"),
      getFn: "getJobOrderByDriver",
    }
    ax.post(jobOrderApiNm.getJobOrderByDriver, postData).then(value => {
      if (value.data) {
        lastFilter = postData
        setAllData(value.data)
      }
    })
  }, [ax, drvId, setAllData])

  // const handleAddDailyExpense = useCallback(() => { }, [])

  const handleCompleteDailyExpense = useCallback((row) => {
    const postData = {
      DalyExpId: row.DalyExpId,
      ColValues: {
        ClrDte: dayjs().format("YYYY-MM-DD HH:mm:ss")
      },
      LogRmk: "Clear เงิน"
    }
    ax.post(dailyExpApiNm.updateDailyExpenseColumn, postData).then(value => {

      dataHasChagne = true
      setDriverExpenseData(o => o.filter(item => item.DalyExpId !== row.DalyExpId).map((item, index) => ({ ...item, No: index + 1 })))

      if (dayjs(filterData.ModDteSt).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")
        && dayjs(filterData.ModDteEn).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")) {
        setDailyExpenseData(o => {
          const result = o.map(item => ({ ...item }))
          const found = result.find(item => item.DalyExpId === row.DalyExpId)
          if (found) {
            found.ClrDte = dayjs().format("YYYY-MM-DD HH:mm:ss")
            return result
          } else {
            return [
              {
                ...row,
                ModDte: dayjs().format(),
                ClrDte: dayjs().format("YYYY-MM-DD HH:mm:ss")
              },
              ...result
            ]
          }
        })
      }
    })

  }, [ax, filterData])

  // const handleAddExpense = useCallback(() => {
  //   if (expenseJobOrdId.length > 0) {
  //     ax.post(jobOrderApiNm.getJobOrder, { JobOrdId: expenseJobOrdId }).then(value => {
  //       if (!value.data || value.data.length === 0) {
  //         alertError("ไม่พบเลขที่ใบงานนี้")
  //         return
  //       }
  //       const jobOrder = value.data[0]
  //       setDriverExpenseData(o => ([{
  //         ...initExpenseObj,
  //         JobOrdId: expenseJobOrdId,
  //         ExpDte: dayjs(),
  //         payType: "OUT",
  //         DrvId: drvId,
  //         TakePOIId: jobOrder.TakePOIId,
  //         LocPOIId: jobOrder.LocPOIId,
  //         RtnPOIId: jobOrder.RtnPOIId,
  //         RcptNm: jobOrder.Shipment?.Job?.RcptNm,
  //         RcptAddr: jobOrder.Shipment?.Job?.RcptAddr,
  //         RcptTaxId: jobOrder.Shipment?.Job?.RcptTaxId
  //       }, ...o]))

  //     })
  //   } else {
  //     setDriverExpenseData(o => ([{
  //       ...initExpenseObj,
  //       JobOrdId: null,
  //       ExpDte: dayjs(),
  //       payType: "OUT",
  //       DrvId: drvId,
  //     }, ...o]))
  //   }
  // }, [ax, drvId, expenseJobOrdId])

  // const toggleContSts = useCallback((contSts) => {

  // }, [])

  // const finishClear = useCallback(() => {
  //   setFilterData({ ...initFilterData })
  // }, [])

  const handleRowClick = useCallback((params) => {
    const found = joData.find(item => item.JobOrdId === params.row.JobOrdId)
    if (found) {
      joDataRef.current.onRowClick(found)
    } else {
      joDataRef.current.onRowClick(null)
    }

  }, [joData])

  const handleDailyExpenseDialogOpen = useCallback((isOpen, dalyExpId) => {

  }, [])

  const handleRowDoubleClick = useCallback((params) => {
    selectedDalyExpId = params.id
    setDialogDailyExpenseOpen(true)
  }, [])

  const onJobOrderTrnsferFinish = useCallback((data) => {
    console.log("onJobOrderTrnsferFinish", data)
    if (data) {

      setFilterData(o => ({
        ...o,
        ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
        ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59")
      }))
      setAllData(data)
      dataHasChagne = true
    }
  }, [setAllData])

  const openDailyExpenseDialogFromJobOrder = useCallback((jobOrdId) => {
    selectedJobOrdId = jobOrdId
    setDialogDailyExpenseOpen(true)
  }, [])

  const handleclose = useCallback(() => {
    if(dataHasChagne) onFinish()

    setDialogOpen(false)
  }, [onFinish, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      getData()
    } else {
      setDriverExpenseData([])
      setJOData([])
      dataHasChagne = false
    }
  }, [dialogOpen, getData])

  console.log("JobOrderTransferDialog render ---- last Filter ::", lastFilter)

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 1850,
          // minHeight: 1000,
          // maxHeight: 1000,
        }
      }}
    >
      <DialogHeader handleDialogClose={handleclose}
        title={`รับ-จ่ายใบงาน ${driverFullName}`}
        onDeleteClick={null}
      />
      <DialogContent sx={{ bgcolor: blueGrey[50] }}>
        <BoxFR mt={2} height={760} sx={{ alignItems: "stretch" }}>
          <BoxFC sx={{ gap: 1 }} height="100%">
            <Paper sx={{ px: 2, py: 1, width: 900, flex: 1 }} >
              <BoxFC height="100%">
                <BoxFR sx={{ alignItems: "baseline" }}>
                  <Typography variant="h6" >เงินค้าง</Typography>
                  <Typography variant="body" >({driverExpenseData.length} รายการ รวม {numberFormat(sumDriverExpense)} บาท)</Typography>
                  <Box flex={1} />
                  <Typography variant="body">{driverFullName}</Typography>
                </BoxFR>
                <Box width="100%" height="100%">
                  <DataGridCellExpand
                    density="compact"
                    disableSelectionOnClick={false}
                    hideToolbar
                    hideFooter
                    rows={driverExpenseData}
                    columns={driverExpenseColumns(handleCompleteDailyExpense)}
                    onRowClick={handleRowClick}
                    onRowDoubleClick={handleRowDoubleClick}
                  />
                </Box>
              </BoxFC>
            </Paper>

            <Paper sx={{ px: 2, py: 1, width: 900, flex: 1 }} >
              <DailyExpense onFinish={onJobOrderTrnsferFinish} {...{
                dailyExpenseData, setDailyExpenseData, driverFullName, drvId, dialogOpen
                , filterData, setFilterData, initFilterData, lastFilter
              }} />
            </Paper>

          </BoxFC>
          <Paper sx={{ px: 2, py: 1, flex: 1 }}>
            <JobOrderData 
              reloadData={getData} 
              ref={joDataRef} 
              onFinish={onJobOrderTrnsferFinish}
              openDailyExpenseDialog={openDailyExpenseDialogFromJobOrder}
              {...{ joData, setJOData, dialogOpen, drvId, driverFullName, handleDailyExpenseDialogOpen, lastFilter }} />
          </Paper>
        </BoxFR>
        <DailyExpenseDialog
          dialogOpen={dialogDailyExpenseOpen}
          setDialogOpen={setDialogDailyExpenseOpen}
          selectedId={selectedDalyExpId}
          selectedJobOrdId={selectedJobOrdId}
          onFinish={onJobOrderTrnsferFinish}
          lastFilter={lastFilter}
          selectedDrvId={drvId} />
      </DialogContent>
      <DialogFooter okText={""} cancelText={"ปิด"}
        handleDialogClose={handleclose}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default JobOrderTransferDialog