import { numberFormatNoFlotingPoint } from "utils"

export const jobByJobYearColumns = (years) => {
  const columns = [
    {
      field: "No",
      headerName: "No",
      width: 50,
    },
    {
      field: "Port",
      headerName: "Port",
      flex: 1,
      minWidth: 150
    },
  ]
  years.forEach(year => {
    columns.push({
      field: year.toString(),
      headerName: year.toString(),
      width: 100,
      align: "right",
      valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
      cellClassName: (params) => {
        const yearB4 = params.row[year - 1]
        if (yearB4) {
          const yearNow = params.row[year]
          if (yearNow < yearB4) return "less-than"
          else if (yearNow > yearB4) return "more-than"
        }
      },
    })
  })
  columns.push({
    field: "Total",
    headerName: "Total",
    width: 130,
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  })
  return columns
}