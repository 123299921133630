import React from "react";
import { Box } from "@mui/material"

const PlanColorBox = ({color}) =>{
  return (
    <Box sx={{
      width: 20,
      height: 20,
      backgroundColor: color,
      border: "1px solid ",
      borderColor: "text.disabled",
      borderRadius: 1.4
    }} />
  )
}

export default PlanColorBox