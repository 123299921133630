import dayjs from "dayjs"


export const initDialogData = {
  DalyExpId: 0,
  ExpDte: dayjs(),
  DrvId: null,
  JobOrdId: null,
  Amnt: 0,
  IsCash: 1,
  IsPaid: 1,
  ClrDte: null,
  Dscp: "",
  Rmk: "",
  ModDte: null,
  ModAccId: 0,
  ModAcc: null,
  AptTm: null,
  TakePlc:"",
  RtnPlc:"",
  Loc:"",
  TakePOIId: null,
  RtnPOIId: null,
  LocPOIId: null,
  ContSizeFull:"",
  JobOrdRmk: "",
  ClrFName: "",
  ClrLName: "",
  ClrNName: "",
}



export const clearFilterData = {
  ExpDteSt: null,
  ExpDteEn: null,
  DrvId: null,
  JobOrdId: null,
  IsNotClr: null,
  CusId: null,
  JobId: null,
  Dscp: null,
  Bkg: null
}
export const initFilterData = {
  ...clearFilterData,
  ExpDteSt: dayjs().format("YYYY-MM-DD 00:00:00.000"),
  ExpDteEn: dayjs().format("YYYY-MM-DD 23:59:59.997")
}