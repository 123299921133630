import React, { useMemo, useState } from "react";
import { Dialog, DialogContent, Paper, TextField, Typography } from '@mui/material'
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { validate } from "utils";
import PaperComponent from "components/PaperComponent";
import { useEffect } from "react";
import ComboBox from "components/ComboBox";
import { useContext } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { useCallback } from "react";
import { initDriverIncomeDialogData } from "./initData";
import { driverIncomeApiNm, driverIncomeColNm } from "constants/driverIncomeConstant";
import ModAccName from "components/ModAccName";
import { jobOrderApiNm } from "constants/jobOrderConstant";
import Loading from "components/Loading";
import dayjs from "dayjs";
import { alertWarning } from "components/Alert";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import TextFieldWithOption from "components/TextFieldWithOption/TextFieldWithOption";


const DriverIncomeDialog = ({ dialogOpen, setDialogOpen, selectedId, onFinish, getArgs }) => {

  const { ax, msData } = useContext(GlobalStateContext)

  const [dialogData, setDialogData] = useState(initDriverIncomeDialogData)
  const [isLoading, setIsLoading] = useState(false)

  const validation = useMemo(() => ({
    DrvId: dialogData.DrvId === null,
    AptTm: dialogData.AptTm === null,
    FinDte: dialogData.FinDte === null
  }), [dialogData])

  const dateTimePickerProps = useCallback((name) => ({
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} fullWidth required error={dialogData[name] === null}/>,
  }), [dialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: driverIncomeColNm[name],
    value: dialogData[name] || "",
    error: validation[name],
    onChange: (e) => {
      setDialogData((oldState) => ({
        ...oldState,
        [name]: e.target.value || null
      }))
    }
  }), [dialogData, validation])

  const comboProp = useCallback((name, label, options, isRequire) => ({
    options: options,
    label: label,
    required: isRequire,
    sx: { width: "100%" },
    selectedId: dialogData[name],
    setSelectedId: (id) => setDialogData(oldState => {
      return { ...oldState, [name]: id }
    })
  }), [dialogData, setDialogData])

  const handleDialogOk = useCallback(() => {
    if(!validate(validation)) return 
    // const getArgs = {
    //   DrvId: dialogData.DrvId,
    //   AptTmSt: dialogData.AptTm.format("YYYY-MM-01 00:00:00.000"),
    //   AptTmEn: dialogData.AptTm.endOf("month").format("YYYY-MM-DD 23:59:59.997")
    // }
    const postData={
      argsArr: [
        {...dialogData}
      ],
      getArgs: getArgs
    }
    ax.post(driverIncomeApiNm.insertDriverIncomeBulk, postData).then(value=>{
      if(value.data){
        onFinish(value.data, getArgs)
        setDialogOpen(false)
      }
    })
  }, [ax, setDialogOpen, onFinish, dialogData, validation, getArgs])

  const handleDelete = useCallback(() => {
    ax.post(driverIncomeApiNm.deleteDriverIncome, {DrvIncId: selectedId}).then(value=>{
      if(value.data){
        onFinish(null, null)
        setDialogOpen(false)
      }
    })
  }, [ax, onFinish, setDialogOpen, selectedId])

  const getJobOrderData = useCallback((e) => {
    if(!e.target.value) return
    setIsLoading(true)
    ax.post(jobOrderApiNm.getDailyJob, { JobOrdId: e.target.value }, false).then(value => {
      if (value.data) {
        if(value.data.length === 0){
          alertWarning(`ไม่พบข้อมูลเลขที่ใบงาน ${e.target.value}`)
          setDialogData(o=>({
            ...o, 
            JobOrdId: "",
            JoAptTm: "",
            TakePlc: "",
            Loc: "",
            RtnPlc: "",
            ContSize: "",
            ContTyp: "",
            TlrTyp: "",
            JobRmk: ""
          }))
          setIsLoading(false)
          return
        }
        const data = value.data[0]
        setDialogData(o => ({
          ...o,
          AptTm: dayjs(data.AptTm), 
          JoAptTm: dayjs(data.AptTm).format("DD/MM/YYYY HH:mm"),
          TakePlc: data.TakePlc,
          Loc: data.Loc,
          RtnPlc: data.RtnPlc,
          ContSize: data.ContSize,
          ContTyp: data.ContTyp,
          TlrTyp: data.TlrTyp,
          JobRmk: data.Rmk
        }))
      }
      setIsLoading(false)
    })
  }, [ax])

  const handleExtTxtChange = useCallback((newValue) => {
    setDialogData(o => ({ ...o, ExtTxt: newValue }))
  }, [])

  useEffect(() => {
    if (dialogOpen === true) {
      if (selectedId > 0) {
        ax.post(driverIncomeApiNm.getDriverIncome, { DrvIncId: selectedId }).then(value => {
          if (value.data && value.data.length > 0) {
            setDialogData({
              ...value.data[0]
            })
          }
        })
      }
    } else {
      setDialogData(initDriverIncomeDialogData)
    }
  }, [dialogOpen, ax, msData.jobCombo, selectedId])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedId > 0 ? "แก้ไขค่าเที่ยว" : "เพิ่มค่าเทียว"}
        onDeleteClick={selectedId > 0 && handleDelete}
      />
      <DialogContent>
        <BoxFC mt={2}>
          <BoxFR sx={{ gap: 2 }}>
            <BoxFC flex={1}>
              <Typography >ข้อมูลค่าเทียว </Typography>
              <TextField {...dialogDataProp("JobOrdId")} onBlur={getJobOrderData} type="number" />
              <DateTimePickerCustomTime {...dateTimePickerProps("AptTm")} label="วันที่วิ่งงาน"/>
              <DateTimePickerCustomTime {...dateTimePickerProps("FinDte")} label="วันที่ลงข้อมูลจบ"/>
              <ComboBox {...comboProp("DrvId", driverIncomeColNm.DrvId, msData.driverOnlyCombo, true)} />
              <TextField {...dialogDataProp("Amnt")} type="number" />
              <TextField {...dialogDataProp("ExtAmnt")} type="number" />
              
              <TextFieldWithOption {...dialogDataProp("ExtTxt")}
                    setValue={handleExtTxtChange}
                    options={msData.serverData.PreText.DriverIncomeExtras || []} />
              {/* <TextField {...dialogDataProp("ExtTxt")} /> */}
              <TextField {...dialogDataProp("Rmk")} />
            </BoxFC>
            <Paper sx={{ flex: 1, p: 2 }} elevation={3}>
              <BoxFC >

                <Typography>ข้อมูลใบงาน </Typography>
                <TextField {...dialogDataProp("JoAptTm")}  disabled/>
                <TextField {...dialogDataProp("JoFinDte")} disabled/>
                <TextField {...dialogDataProp("TakePlc")} disabled/>
                <TextField {...dialogDataProp("Loc")} disabled/>
                <TextField {...dialogDataProp("RtnPlc")} disabled/>
                <BoxFR>
                  <TextField {...dialogDataProp("ContSize")} disabled/>
                  <TextField {...dialogDataProp("ContTyp")} disabled/>
                </BoxFR>
                <TextField {...dialogDataProp("TlrTyp")} disabled/>
                <TextField {...dialogDataProp("JoRmk")} disabled/>

                {
                  isLoading && <Loading />
                }
              </BoxFC>
            </Paper>
          </BoxFR>
          {
            dialogData.JobDrvPayId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
          }
        </BoxFC>

      </DialogContent>
      <DialogFooter okText="บันทึกข้อมูล"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(DriverIncomeDialog)