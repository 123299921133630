import { Collapse, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import SwitchFormControl from "components/SwitchFormControl";
import React from "react";

const TargetDataContent = ({ targetData, setTargetData }) => {

  return (
    <BoxFC>
      <Typography variant="h6">เป้าหมายวันนี้</Typography>
      <BoxFC pl={2}>
        <SwitchFormControl label="แสดงเป้าหมายวันนี้" 
        checked={targetData.isShowTarget} 
        onChange={e=>setTargetData(o=>({...o, isShowTarget: e.target.checked}))}/>
        <Collapse in={targetData.isShowTarget} sx={{ml:1}}>
          <RadioGroup
            value={targetData.targetMode}
            onChange={e=>setTargetData(o=>({...o, targetMode: e.target.value}))}
          >
            <FormControlLabel value={1} control={<Radio />} label="เป้าหมาย นักปฏิบัติการ" />
            <FormControlLabel value={2} control={<Radio />} label="เป้าหมาย บัญชีและการเงิน" />
          </RadioGroup>
        </Collapse>
      </BoxFC>
    </BoxFC>
  )
}

export default React.memo(TargetDataContent)