import { jobOrderApiNm } from "constants/jobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { useCallback, useContext, useEffect, useState } from "react"
import { addIdForDataGrid } from "utils"


let contStatusData0 = []
let contStatusData1 = []
let contStatusData3 = []
let contStatusData4 = []
const useClientContainerStatus = () => {

  const { ax, msData } = useContext(GlobalStateContext)

  const [dataTable, setDataTable] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(3)

  const selectGroup = useCallback((prty) => {
    setSelectedGroup(prty)
    if (prty === 0) {
      setDataTable(contStatusData0)
    } else if (prty === 1) {
      setDataTable(contStatusData1)
    } else if (prty === 3) {
      setDataTable(contStatusData3)
    } else if (prty === 4) {
      setDataTable(contStatusData4)
    }
  }, [])
  useEffect(() => {
    ax.post(jobOrderApiNm.getDailyJob, { IsNotFin: 1 }).then(value => {
      console.log("value.data", value.data)
      if (value.data) {

        contStatusData0 = []
        contStatusData1 = []
        contStatusData3 = []
        contStatusData4 = []
        for (const item of value.data) {
          if (item.DrvSts5) continue
          else if (item.DrvSts4) contStatusData4.push(item)
          else if (item.DrvSts3) contStatusData3.push(item)
          else if (item.DrvSts1) contStatusData1.push(item)
          else contStatusData0.push(item)
        }
        contStatusData0 = addIdForDataGrid(contStatusData0, "JobOrdId")
        contStatusData1 = addIdForDataGrid(contStatusData1, "JobOrdId")
        contStatusData3 = addIdForDataGrid(contStatusData3, "JobOrdId")
        contStatusData4 = addIdForDataGrid(contStatusData4, "JobOrdId")
        setDataTable(contStatusData3)
      }
    })
  }, [ax, msData])
  return {
    state: { dataTable, selectedGroup },
    fn: { selectGroup }
  }
}

export default useClientContainerStatus