import { Box, Button } from "@mui/material"
import { driverIncomeColNm } from "constants/driverIncomeConstant"
import { jobOrderColNm } from "constants/jobOrderConstant"
import dayjs from "dayjs"
import { numberFormatNoFlotingPoint } from "utils"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { GridActionsCellItem } from "@mui/x-data-grid"
import { getPOIPlace } from "utils/getPOIPlace"


export const columns = (ref2Name) => [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 60,
    align: 'center',
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 100,
    align: "center",
    renderCell: (params) => (
      <Button variant="contained">{params.value}</Button>
    )
  },
  {
    field: "AptTm",
    headerName: "วันที่วิ่งงาน",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'Bkg',
    headerName: jobOrderColNm.Bkg,
    width: 120,
  },
  {
    field: 'Agnt',
    headerName: jobOrderColNm.Agnt,
    width: 70,
  },
  {
    field: 'ContSizeFull',
    headerName: jobOrderColNm.ContSizeFull,
    width: 70,
  },
  {
    field: 'TakePlc',
    headerName: jobOrderColNm.TakePlc,
    width: 70,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE")
  },
  {
    field: 'RtnPlc',
    headerName: jobOrderColNm.RtnPlc,
    width: 70,
    valueGetter: (params) => getPOIPlace(params.row, "RTN")
  },
  {
    field: "ContStses",
    headerName: "พนักงานขับรถ",
    width: 400,
    valueGetter: (params) => params.value.map(item=>item.DrvNNm|| "---").join(","),
    renderCell: (params) => {
      return (
        <Box display="flex" gap={0.4} width="100%">
          {
            params.row.ContStses.map((item, index) => (
              <Button key={item.ContStsId} sx={{ min: 60, height: 30, flex: 1 }}
                variant={item.DrvNNm ? "contained" : "outlined"}
                color={item.Clr}>
                {item.DrvNNm}
              </Button>
            ))
          }
        </Box>
      )
    },
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 230,
    renderCell: (params) => {
      return (
        <Button fullWidth sx={{ height: 30 }} variant={params.value ? "contained" : "outlined"} >{params.value}</Button>
      )
    },
  },
  {
    field: "FinDte",
    headerName: jobOrderColNm.FinDte,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  // {
  //   field: 'JobSNm',
  //   headerName: jobOrderColNm.JobSNm,
  //   width: 120,
  // },
  // {
  //   field: 'CusSNm',
  //   headerName: jobOrderColNm.CusSNm,
  //   width: 120,
  // },
  {
    field: "TagNm",
    headerName: jobOrderColNm.TagNm,
    width: 100,
  },
  {
    field: "Ref2",
    headerName: ref2Name,
    width: 100,
  },
  {
    field: 'DIAmnt',
    headerName: "ค่าเที่ยว",
    width: 70,
    align: "right",
    valueFormatter:(params)=> params.value?numberFormatNoFlotingPoint(+params.value): ""
  },
  {
    field: 'DIExtAmnt',
    headerName: "ค่าเที่ยวพิเศษ",
    width: 100,
    align: "right",
    valueFormatter:(params)=> params.value?numberFormatNoFlotingPoint(+params.value): ""
  },
  {
    field: "Rmk",
    headerName: jobOrderColNm.Rmk,
    flex: 1,
    minWidth: 120,
  },
  // {
  //   field: "actions",
  //   type: "actions",
  //   minWidth: 0,
  //   hide: !handleSelect,
  //   width: 30,
  //   getActions: (params) => [
  //     <GridActionsCellItem
  //       disabled={disableArrow || params.row.disableArrow}
  //       icon={<ArrowCircleRightIcon color={disableArrow || params.row.disableArrow ? "inherit" : "primary"} sx={{ fontSize: 30 }} />}
  //       label="Select"
  //       onClick={() => handleSelect(params.row)}
  //     />
  //   ],
  // }
]


export const generateDriverIncomeReportColumns = (handleDelete) => [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 60,
    align: 'center',
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 100,
    align: "center",
  },
  {
    field: "AptTm",
    headerName: "วันที่วิ่งงาน",
    width: 110,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "FinDte",
    headerName: "วันที่ลงข้อมลูจบ",
    width: 110,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'ContSizeFull',
    headerName: jobOrderColNm.ContSizeFull,
    width: 70,
  },
  {
    field: 'TakePlc',
    headerName: jobOrderColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 70,
  },
  {
    field: 'Loc',
    headerName: jobOrderColNm.Loc,
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 70,
  },
  {
    field: 'RtnPlc',
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 70,
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 230,
  },
  {
    field: "Amnt",
    headerName: driverIncomeColNm.Amnt,
    width: 70,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
  {
    field: "ExtAmnt",
    headerName: "พิเศษ",
    width: 70,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
  {
    field: "ExtTxt",
    headerName: "รายละเอียดพิเศษ",
    flex: 1,
  },
  {
    field: 'actions',
    type: 'actions',
    width: 30,
    getActions: (params) => !params.row.JobOrdId ? [
      <GridActionsCellItem
        icon={<DeleteRoundedIcon />}
        label="Delete"
        onClick={() => handleDelete(params.id)}
      />
    ] : [],
  },
]

export const driverIncomeShipmentColumns = [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 60,
    align: 'center',
  },
  {
    field: 'JobSNm',
    headerName: jobOrderColNm.JobSNm,
    flex: 1
  },
  {
    field: 'Bkg',
    headerName: jobOrderColNm.Bkg,
    flex: 1
  },
  {
    field: 'TagNms',
    headerName: "Tag",
    width: 100
  },
  {
    field: 'TakePlc',
    headerName: jobOrderColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    flex: 1
  },
  {
    field: 'RtnPlc',
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    flex: 1
  },
  {
    field: "Amnt",
    headerName: driverIncomeColNm.Amnt,
    width: 100,
    align: "right",
    editable: true,
    type: "number",
    // valueGetter: (params)=>  +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(+params.value, true)
  },
]

export const printColumnHeader = [
  {
    id: "No",
    name: "No",
    prompt: "No.",
    width: 0.5,
    align: "center",
    padding: 0,
  },
  {
    id: "FinDte",
    name: "FinDte",
    prompt: "วันที่ลงข้อมูล",
    width: 1,
    align: "left",
    padding: 0,
  },
  {
    id: "AptTm",
    name: "AptTm",
    prompt: "วันที่วิ่งงาน",
    width: 0.9,
    align: "left",
    padding: 0,
  },
  {
    id: "Loc",
    name: "Loc",
    prompt: "สถานที่",
    width: 2.3,
    align: "left",
    padding: 0,
  },
  {
    id: "ContSizeFull",
    name: "ContSizeFull",
    prompt: "ขนาดตู้",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "ContNo",
    name: "ContNo",
    prompt: "หมายเลขตู้",
    width: 2.2,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Amnt",
    name: "Amnt",
    prompt: "ค่าเที่ยว",
    width: 0.6,
    align: "right",
    padding: 0,
  },
  {
    id: "ExtAmnt",
    name: "ExtAmnt",
    prompt: "พิเศษ",
    width: 0.5,
    align: "right",
    padding: 0,
  },
  {
    id: "ExtTxt",
    name: "ExtTxt",
    prompt: "รายละเดียดพิเศษ",
    width: 1.2,
    align: "left",
    padding: 0,
  },
]

export const dailyExpenseReportColumns = [
  {
    field: 'FullNm',
    headerName: "พนักงานขับรถ",
    // valueGetter: (params) => `${params.row.FName} ${params.row.LName} (${params.row.NName})`,
    width: 200,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex:1,
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 230,
  },
  {
    field: "Fuel",
    headerName: "ค่าน้ำมัน",
    width: 80,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
  {
    field: "GateCharge",
    headerName: "ค่าผ่านท่า",
    width: 80,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
  {
    field: "LiftOnOff",
    headerName: "ค่ายกตู้",
    width: 80,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
  {
    field: "Toll",
    headerName: "ค่าทางด่วน",
    width: 80,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
  {
    field: "Other",
    headerName: "ค่าอื่นๆ",
    width: 70,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
  {
    field: "Total",
    headerName: "รวม",
    width: 90,
    align: "right", 
    valueGetter: (params)=> +params.value,
    valueFormatter: (params)=> numberFormatNoFlotingPoint(params.value)
  },
]

export const printDailyExpenseColumnHeader = [
  {
    id: "FullNm",
    name: "FullNm",
    prompt: "พนักงานขับรถ",
    width: 2,
    align: "left",
    padding: 0,
  },
  {
    id: "Loc",
    name: "Loc",
    prompt: "สถานที่",
    width: 2.1,
    align: "left",
    padding: 0,
  },
  {
    id: "ContNo",
    name: "ContNo",
    prompt: "หมายเลขตู้",
    width: 2.1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Fuel",
    name: "Fuel",
    prompt: "น้ำมัน",
    width: 0.6,
    align: "right",
    padding: 0,
  },
  {
    id: "GateCharge",
    name: "GateCharge",
    prompt: "ผ่านท่า",
    width: 0.6,
    align: "right",
    padding: 0,
  },
  {
    id: "LiftOnOff",
    name: "LiftOnOff",
    prompt: "ยกตู้",
    width: 0.6,
    align: "right",
    padding: 0,
  },
  {
    id: "Toll",
    name: "Toll",
    prompt: "ทางด่วน",
    width: 0.6,
    align: "right",
    padding: 0,
  },
  {
    id: "Other",
    name: "Other",
    prompt: "อื่นๆ",
    width: 0.6,
    align: "right",
    padding: 0,
  },
  {
    id: "Total",
    name: "Total",
    prompt: "รวม",
    width: 0.6,
    align: "right",
    padding: 0,
  },
]