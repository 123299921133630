import React from "react";
import LoadingAnimation from "resource/Interwind-1s-200px.svg"

const Loading = () => (
  <div style={{
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    background: "rgba(244,244,244,0.5)",
    zIndex: 19999
  }}>
    <img style={{ left: "50%", top: "50%", position: "absolute" }} src={LoadingAnimation} alt="Loading" />
  </div>
)

export default Loading