import { blue, cyan, indigo, lightBlue, teal
  // , deepOrange, deepPurple, green, lightGreen, lime, orange, purple, amber, yellow 
} from "@mui/material/colors"

export const borderColors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
]

export const bgColors = [
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
]

export const bgGraphToneColor = [
  // blue[100], blue[300], blue[500], blue[700], blue[900]
  // green[800], lightGreen[500], yellow[500], orange[500], deepOrange[800]
  // deepOrange[800], orange[500], yellow[500], lightGreen[500], green[800]
  // cyan[100], lightBlue[300], blue[500], indigo[700], purple[800]
  teal[100], cyan[300], lightBlue[500], blue[700], indigo[900]
  // deepOrange[100], orange[300], yellow[500], lightGreen[700], green[900]
  // lightGreen[100], green[300], teal[500], lightBlue[700], blue[900] 
  // 'rgba(178, 223, 219, 0.5)',//teal[100]
  // 'rgba(77, 208, 225, 0.5)',
  // 'rgba(3, 169, 244, 0.5)',
  // 'rgba(25, 118, 210, 0.4)',
  // 'rgba(26, 35, 126, 0.3)'
]

export const borderGraphToneColor = [
  teal[100], cyan[300], lightBlue[500], blue[700], indigo[900]
]


export const jobColumns= [
  {
    field: 'No',
    headerName: "No.",
    width: 70,
  },
  {
    field: 'SName',
    headerName: "งาน",
    flex:1,
  },
  {
    field: 'Num',
    headerName: "จำนวน",
    width: 70,
  },
]

export const subColumns= [
  {
    field: 'No',
    headerName: "No.",
    width: 70,
  },
  {
    field: 'NName',
    headerName: "รถร่วม",
    flex:1,
  },
  {
    field: 'Num',
    headerName: "จำนวน",
    width: 70,
  },
]