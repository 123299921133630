import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
// import { IconButton, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
// import { BoxFC, BoxFR } from 'components/BoxCustom';
// import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
// import { numberFormat } from 'utils';
// import TodayIcon from '@mui/icons-material/Today';
// import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
// import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
// import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
// import { Link } from 'react-router-dom';
// import { blueGrey, green, red, yellow } from '@mui/material/colors';
import DataBox from './DataBox';
import { locationText } from 'utils/locationText';

export const jobColumns = (handleSelect, disableArrow) => [
  // {
  //   field: 'No',
  //   headerName: 'ลำดับ',
  //   width: 60,
  //   align: 'center',
  // },
  {
    field: 'Bkg',
    headerName: 'Booking',
    width: 120,
  },
  {
    field: 'Agnt',
    headerName: 'Agent',
    width: 70,
  },
  {
    field: 'ContSizeFull',
    headerName: 'ขนาดตู้',
    width: 70,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex: 1,
    valueGetter: (params) => locationText(params.row),
  },
  {
    field: 'TakeDteSt',
    headerName: "First",
    width: 55,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM") : "-",
  },
  {
    field: 'TakeDteEn',
    headerName: "Last",
    width: 55,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM") : "-",
  },
  {
    field: 'IsRdyTake',
    headerName: 'เช็ค',
    width: 50,
    renderCell: (params) => {
      return params.value === 1 ? <CheckCircleIcon color="success" /> : <CancelIcon color="action" />
    },
  },
  {
    field: 'Count',
    headerName: 'จำนวน',
    valueGetter: (params) => params.row.JobOrdIds.length,
    valueFormatter: (params) => params.value.toString(),
    width: 70,
    align: "center"
  },
  {
    field: "actions",
    type: "actions",
    minWidth: 0,
    hide: !handleSelect,
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        disabled={disableArrow || params.row.disableArrow}
        icon={<ArrowCircleRightIcon color={disableArrow || params.row.disableArrow ? "inherit" : "primary"} sx={{ fontSize: 30 }} />}
        label="Select"
        onClick={() => handleSelect(params.row)}
      />
    ],
  }
]

export const summaryColumns = [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 60,
    align: 'center',
  },
  {
    field: 'TagNm',
    headerName: 'Tag',
    width: 100,
  },
  {
    field: 'Bkg',
    headerName: 'Booking',
    width: 120,
  },
  {
    field: 'Agnt',
    headerName: 'Agent',
    width: 100,
  },
  {
    field: 'ContSizeFull',
    headerName: 'ขนาดตู้',
    width: 80,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex: 1,
    valueGetter: (params) => locationText(params.row),
  },
  {
    field: 'Count',
    headerName: 'จำนวน',
    valueGetter: (params) => params.row.JobOrdIds.length,
    valueFormatter: (params) => params.value.toString(),
    width: 80,
    align: "center"
  },
]

// const handleContextMenu = (event)=>{
//   // event.preventDefault()
//   console.log("xx::::ssss")
//   return true
// }
// const sxButton = {
//   p: 0, m: 0, minWidth: 0, fontSize:"1rem"
// }

// const sxGap0 = { gap: 0 }
// const generateDataBox = (dataArr, handleRemoveJobOrder, handleChangeAptTm, handleCheckBoxChange, handleBoxClick) => (

//   <BoxFC sx={{ gap: 1 }} py={0.5} >
//     {dataArr.map(item => (
//       <BoxFR key={item.JobOrdId}
//         sx={{
//           border: `1px solid ${item.IsAdded ? green[500] : blueGrey[300]}`,
//           borderRadius: 2,
//           bgcolor: item.Bkg === item.hilightBkg ? yellow[50] : item.TagIds.includes(10) ? green[100] : "none",
//           px: 0.5,
//           position: "relative"
//         }}>
//         <BoxFC sx={sxGap0}>
//           <BoxFR>
//             {!item.IsAdded && <Checkbox sx={{ p: 0 }} checked={item.IsSelected} onChange={handleCheckBoxChange(item)} />}
//             <Typography>ID:<Link target="_blank" to={`/emily/DailyJob/${item.JobOrdId}`} style={{ color: "#1976d2" }}>{item.JobOrdId}</Link> ${numberFormat(item.IsAdded ? +item.DXAmnt : +item.DXAmnt * -1)} บาท </Typography>
//           </BoxFR>
//           <BoxFR>
//             <Typography>{item.Agnt}-<Link target="_blank" to={`/emily/DailyJob/Bkg/${item.Bkg}`} style={{ color: "#1976d2" }}>{item.Bkg}</Link> </Typography>
//             <IconButton sx={{ p: 0, m: 0 }} onClick={handleBoxClick(item.hilightBkg === item.Bkg ? null : item.Bkg)}>
//               {
//                 item.Bkg === item.hilightBkg ?
//                   <BookmarkRoundedIcon color="primary" /> :
//                   <BookmarkBorderRoundedIcon color="primary" />
//               }
//             </IconButton>
//           </BoxFR>

//           <Typography>{item.ContSizeFull} {item.TakePlc}{item.Loc === '-' ? " - " : `-${item.Loc}-`}{item.RtnPlc}</Typography>
//         </BoxFC>
//         <BoxFC sx={sxGap0}>
//           <IconButton onClick={item.IsAdded ? null : (e) => handleChangeAptTm(item.JobOrdId)} sx={{ mb: -1 }}>
//             <TodayIcon color={item.IsAdded ? "inherit" : "primary"} />
//           </IconButton>
//           <IconButton onClick={(e) => handleRemoveJobOrder(item)}>
//             <RemoveCircleRoundedIcon />
//           </IconButton>
//         </BoxFC>

//         {item.Rmk && <Typography color={red[500]} sx={{
//           fontSize: "1.5rem",
//           position: "absolute",
//           top: -5,
//           right: 20,
//           cursor: "help"
//         }}>*</Typography>}
//       </BoxFR>
//     ))}
//   </BoxFC>
// )
export const driverColumns = (handleRemoveJobOrder, handleChangeAptTm, handleCheckBoxChange, handleBoxClick, msData) => {
  const handles = {handleRemoveJobOrder, handleChangeAptTm, handleCheckBoxChange, handleBoxClick}
  return [
    {
      field: 'NName',
      headerName: 'พขร.',
      width: 90,
      renderCell: (params) => <div><div>{params.row.NName}</div> <div>{params.row.TukCd}</div> </div>
    },
    // {
    //   field: 'TukCd',
    //   headerName: 'เลขรถ',
    //   width: 90,
    // },
    {
      field: 'ToLaem',
      headerName: msData.tags.find(tag => tag.TagId === 1)?.Name,
      flex: 1,
      valueGetter: (params) => params.row[1].map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${locationText(item)}`).join("\n"),
      // renderCell: (params) => generateDataBox(params.row[1], handleRemoveJobOrder, handleChangeAptTm, handleCheckBoxChange, handleBoxClick),
      renderCell: (params) => <DataBox dataArr={params.row[1]} handles={handles} />
    },
    {
      field: 'AroundHere',
      headerName: `${msData.tags.find(tag => tag.TagId === 2)?.Name}, ${msData.tags.find(tag => tag.TagId === 5)?.Name}`,
      flex: 1,
      valueGetter: (params) => params.row[2].map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${locationText(item)}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row[2]} handles={handles} />,
    },
    {
      field: 'ToBKK',
      headerName: msData.tags.find(tag => tag.TagId === 3)?.Name,
      flex: 1,
      valueGetter: (params) => params.row[3].map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${locationText(item)}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row[3]} handles={handles} />,
    },
  ]
}

export const printColumnHeader = (msData) => [
  {
    id: "NName",
    name: "NName",
    prompt: "พขร",
    width: 1.6,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "BL",
    name: "BL",
    prompt: msData.tags.find(tag => tag.TagId === 1)?.Name,
    width: 2.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "BB",
    name: "BB",
    prompt: `${msData.tags.find(tag => tag.TagId === 2)?.Name},${msData.tags.find(tag => tag.TagId === 5)?.Name}`,
    width: 2.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "LB",
    name: "LB",
    prompt: msData.tags.find(tag => tag.TagId === 3)?.Name,
    width: 2.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "DX",
    name: "DX",
    prompt: "เงิน",
    width: 0.8,
    align: "right",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Toll",
    name: "Toll",
    prompt: "ทางด่วน",
    width: 0.8,
    align: "right",
    padding: 0,
    separateRow: "-"
  },
]

export const printJobColumnHeader = [
  {
    id: "Tag",
    name: "Tag",
    prompt: "Tag",
    width: 1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Bkg",
    name: "Bkg",
    prompt: "Booking",
    width: 1.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Agnt",
    name: "Agnt",
    prompt: "Agent",
    width: 0.8,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "ContSizeFull",
    name: "ContSizeFull",
    prompt: "ขนาดตู้",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Loc",
    name: "Loc",
    prompt: "สถานที่",
    width: 2.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "TakeDteSt",
    name: "TakeDteSt",
    prompt: "First",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "TakeDteEn",
    name: "TakeDteEn",
    prompt: "Last",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "IsRdyTake",
    name: "IsRdyTake",
    prompt: "พร้อมรับ",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Count",
    name: "Count",
    prompt: "จำนวน(เที่ยว)",
    width: 1,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
]