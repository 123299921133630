import { Box, Dialog, DialogContent, useMediaQuery } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { coreApiNm } from "constants/serverConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useContext, useEffect, useState } from "react"
import { addIdForDataGrid } from "utils"
import { vanningDetailColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { yellow } from "@mui/material/colors"

const VaningDailog = ({ queryData, dialogOpen, setDialogOpen }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])

  const w800 = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    if (dialogOpen) {
      ax.post(coreApiNm.getVanningData, queryData).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, 'JobOrdId'))
        }
      })
    } else {
      setDataTable([])
    }
  }, [dialogOpen, ax, queryData])


  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' fullScreen={!w800}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="Container Vanning Detail"
        onDeleteClick={null}
      />
      <DialogContent sx={{px: w800?null: 1}}>
        <BoxFC mt={2} sx={{gap:0}} height={w800?null: "100%"}>
          {/* <Typography variant="h6">xxx</Typography> */}
          <Box height={w800?500: "100%"} sx={{"& .highlight": {bgcolor: yellow[50]}}}>
            <DataGridCellExpand
              density={w800 ? "standard" : "compact"}
              hideFooter
              rows={dataTable}
              columns={vanningDetailColumns} />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(VaningDailog)