import { Checkbox, CircularProgress, Dialog, DialogContent, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { branchApiNm } from "branch/constant/branchApiNm";
import { BoxFC, BoxFR } from "components/BoxCustom";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import { drawBox } from "pages/Menu1/DailyJob/ContainerStatus";
import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { initDialogData, initDIData, initDXData } from "./initData";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ComboBox from "components/ComboBox";
import { dailyExpenseColNm } from "constants/dailyExpenseConstant";
import { useMemo } from "react";
import { UserContext } from "contexts/UserContext";
import { addIdForDataGrid, containerNoCheck } from "utils";
import { processConstStatus } from "pages/Menu1/DailyJob/processContStatus";
import { alertWarning } from "components/Alert";
import { driverIncomeColNm } from "constants/driverIncomeConstant";
import TextFieldWithOption from "components/TextFieldWithOption/TextFieldWithOption";

const moneyProp = {
  type: "number",
  inputProps: { style: { textAlign: 'right' } },
  InputProps: { endAdornment: <InputAdornment position="end">บาท</InputAdornment> }
}

const sxFlexStart = { alignItems: "stretch", justifyContent: "center" }
let oDialogDataStr = ""
let oContStsDataStr = ""
let oDXDataStr = ""
let oDIDataStr = ""

const generateContNo = (contNo1, contNo2) => {
  const contNos1 = contNo1.split(",").map(item=>item.trim());
  const contNos2 = contNo2.split(",").map(item=>item.trim());
  const returnData = []
  
  for(const contNo of contNos1){
    if(contNo.trim().length > 0) returnData.push(contNo)
  }
  for(const contNo of contNos2){
    if(contNo.trim().length > 0) returnData.push(contNo)
  }
  return returnData.length> 0? returnData.join(", "):""
  // let mContNo1 = contNo1.trim()
  // let mContNo2 = contNo2.trim()

  // if (mContNo1.length === 0 && mContNo2.length > 0) {
  //   return mContNo2
  // }
  // return `${mContNo1}${mContNo2.length > 0 ? `, ${mContNo2}` : ""}`
}
const JobOorderDriverIncomeDialog = ({ dialogOpen, setDialogOpen, jobOrdId, setDataTable, lastFilter }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const [contStsData, setContStsData] = useState(msData.containerStatuses)
  const [dxData, setDXData] = useState([])
  const [diData, setDIData] = useState({ ...initDIData })
  const [isShowLoading1, setIsShowLoading1] = useState(false)
  const [isShowLoading2, setIsShowLoading2] = useState(false)

  const suggestDrvCombo = useCallback((drvId) => {
    if (!drvId) return msData.driverCombo
    const result = []
    for (const drv of msData.driverCombo) {
      if (drv.id === drvId) {
        result.unshift({ ...drv, group: "แนะนำ" })
      } else {
        result.push({ ...drv, group: "พนักงานขับรถ" })
      }
    }
    return result
  }, [msData.driverCombo])

  const dxDrvCombo = useMemo(() => {
    let foundId = null
    for (const data of contStsData) {
      if (data.DrvId) foundId = data.DrvId
    }
    return suggestDrvCombo(foundId)
  }, [contStsData, suggestDrvCombo])

  const dialogDataProp = useCallback((name, readOnly) => ({
    variant: "outlined",
    size: "small",
    label: jobOrderColNm[name],
    value: dialogData[name] || "",
    onChange: readOnly ? null : e => setDialogData(o => ({ ...o, [name]: e.target.value })),
    onBlur: async e => {
      if (name === "ContNo1" || name === "ContNo2") {
        const value = e.target.value.toUpperCase()
        containerNoCheck(value)
        setDialogData(o => ({ ...o, [name]: value }))

        if (name === "ContNo1") setIsShowLoading1(true)
        else setIsShowLoading2(true)
        const contNos = value.split(",");
        for(const contNo of contNos){
          if (contNo.trim().length !== 11) continue
          const postData = {
            JobOrdId: jobOrdId,
            ContNo: contNo.trim()
          }
          
          const result = await ax.post(jobOrderApiNm.CheckDuplicateContNoInBooking, postData, false)
          if (result.data !== null) {
            if (result.data) {
              alertWarning(`มีหมายเลขตู้ ${contNo} ซ้ำใน Booking นี้แล้ว`)
            }
          }
        }
        if (name === "ContNo1") setIsShowLoading1(false)
        else setIsShowLoading2(false)
      }
    }
  }), [dialogData, ax, jobOrdId])

  const diDataProp = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    label: driverIncomeColNm[name],
    sx: { flex: 1 },
    value: diData[name] || "",
    onChange: e => setDIData(o => ({ ...o, [name]: e.target.value || null })),
  }), [diData])
  const diDataPropAmnt = useMemo(() => ({
    ...diDataProp("Amnt"),
    ...moneyProp,
    sx: { flex: 1, color: diData.IsFrmSh ? "yellow" : null },
    error: !diData.Amnt && !diData.AmntSh,
    value: diData.Amnt || "",
    onChange: e => setDIData(o => ({ ...o, Amnt: e.target.value || null, IsFrmSh: false })),
    helperText: diData.IsFrmSh ? "(ข้อมูลจาก ค่าเที่ยว-บุ๊คกิ้ง)" : ""
  }), [diData, diDataProp])

  const dxDataProp = useCallback((name, item) => ({
    variant: "outlined",
    size: "small",
    label: dailyExpenseColNm[name],
    value: item[name] || "",
    onChange: e => setDXData(o => {
      const result = [...o]
      item[name] = e.target.value
      return result
    })
  }), [])

  const handleClearCheckboxChange = useCallback((item) => (e) => {
    setDXData(o => {
      const result = [...o]
      if (item.ClrAccId) {
        item.ClrDte = null
        item.ClrAccId = null
      } else {
        item.ClrDte = dayjs().format("YYYY-MM-DD HH:mm:ss.SSS")
        item.ClrAccId = user.AccId
      }
      return result
    })
  }, [user.AccId])

  const datePickerJobOrderProp = useCallback((name, isRequire) => ({
    label: jobOrderColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small"  {...params} fullWidth />,
  }), [dialogData])

  const handleOk = useCallback(() => {
    dialogData.ContNo = generateContNo(dialogData.ContNo1, dialogData.ContNo2)
    // console.log(dialogData.ContNo);
    // return;
    console.log("oDIDataStr::", oDIDataStr)
    console.log("dialogData::", dialogData)
    const postData = {
      JobOrderData: oDialogDataStr === JSON.stringify(dialogData) ? null : dialogData,
      ContStsData: oContStsDataStr === JSON.stringify(contStsData) ? null : contStsData,
      DXData: oDXDataStr === JSON.stringify(dxData) ? null : dxData,
      DIData: oDIDataStr === JSON.stringify(diData) ? null : diData,
      getArgs: lastFilter
    }
    if (postData.DXData !== null) {
      postData.DXData = dxData.map(dx => ({ ...dx, Amnt: +dx.Amnt * -1 }))
    }
    console.log("postData:::", postData);
    if (!postData.JobOrderData && !postData.ContStsData && !postData.DXData && !postData.DIData) {
      setDialogOpen(false)
      return
    }
    ax.post(branchApiNm.updateDailyJobWithOthers, postData).then(value => {
      if (value.data) {
        processConstStatus(value.data, msData.containerStatuses);
        setDataTable(addIdForDataGrid(value.data, "JobOrdId"))
        setDialogOpen(false)
      }

    })
  }, [dialogData, contStsData, dxData, diData, ax, setDataTable, setDialogOpen, lastFilter, msData])

  const handleClose = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])

  const hanldeNowClick = useCallback(() => {
    setDialogData(o => ({ ...o, FinDte: dayjs() }))
  }, [])

  const handleDIDrvIdChange = useCallback((id) => {
    setDIData(o => ({ ...o, DrvId: id }))
  }, [])

  const handleDriverContStsChange = useCallback((contSts) => (fn) => {
    setContStsData(fn)
    if (contSts.ContStsId === 5) {
      setDIData(o => ({ ...o, DrvId: contSts.DrvId }))
    }
  }, [])

  const handleExtTxtChange = useCallback((newValue) => {
    setDIData(o => ({ ...o, ExtTxt: newValue }))
  }, [])

  const handleDXRmkChange = useCallback((dx) => (newValue)=> {
    setDXData(o => {
      const result = [...o]
      dx.Rmk = newValue
      return result
    })
  }, [])

  const handleAddDX = useCallback(() => {
    let foundDrvId = null
    for (const data of contStsData) {
      if (data.DrvId) foundDrvId = data.DrvId
    }

    setDXData(o => {
      const result = [...o]
      result.push({
        ...initDXData,
        DalyExpId: result.length * -1,
        ExpDte:  dayjs().format("YYYY-MM-DD HH:mm:00"),
        ClrDte: dayjs().format("YYYY-MM-DD HH:mm:00"),
        ClrAccId: user.AccId,
        JobOrdId: jobOrdId,
        DrvId: foundDrvId,
        IsAddData: true
      })
      return result
    })
  }, [user.AccId, jobOrdId, contStsData])

  const handleRemoveAddedDXItem = useCallback((item) => (e) => {
    console.log("remove item::", item)
    setDXData(o => {
      const result = [...o]
      const index = result.findIndex(dx => dx.DalyExpId === item.DalyExpId)
      console.log("index::", index)
      result.splice(index, 1)
      return result
    })
  }, [])

  const handleDXDriverChange = useCallback((dx) => (id) => {
    setDXData(o => {
      const result = [...o]
      dx.DrvId = id
      return result
    })
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(branchApiNm.getDailyJobWithDriverIncome, { JobOrdId: jobOrdId }).then(value => {
        if (value.data) {
          const data = value.data[0]
          if (data.ContNo) {
            const contNos = data.ContNo.split(",")
            let contNoObj = {1: [], 2:[]};
            let num = 1;
            for(const contNo of contNos){
              contNoObj[num].push(contNo.trim())
              if(num === 1) num =2
              else num = 1
            }
            data.ContNo1 = contNoObj[1].join(", ")
            data.ContNo2 = contNoObj[2].join(", ")
            // data.ContNo2 = contNos.length > 1 ? contNos[1].trim() : ""
            data.ContNo = generateContNo(data.ContNo1, data.ContNo2)
          } else {
            data.ContNo1 = ""
            data.ContNo2 = ""
          }
          let oContStsData = msData.containerStatuses.filter(item => item.IsHide === 0).map(item => ({
            ...item,
            DrvId: data[`DrvId${item.ContStsId}`],
            JobOrdId: jobOrdId
          }))
          let oDXData = data.DailyExpenses.map(dx => ({ ...dx, Amnt: +dx.Amnt * -1 }))
          let oDIData = {
            Amnt: data.DIAmnt,
            AmntSh: data.DIAmntSh ? +data.DIAmntSh : null,
            ExtAmnt: data.DIExtAmnt? +data.DIExtAmnt : null,
            ExtTxt: data.DIExtTxt,
            DrvId: data.DIDrvId,
            JobOrdId: data.JobOrdId,
            IsFrmSh: (!data.DIAmnt && data.DIAmntSh)
          }
          oDIData.Amnt = data.DIAmnt ? +data.DIAmnt : data.DIAmntSh ? +data.DIAmntSh : null
          oDialogDataStr = JSON.stringify(data)
          oContStsDataStr = JSON.stringify(oContStsData)
          oDXDataStr = JSON.stringify(oDXData)
          oDIDataStr = JSON.stringify(oDIData)

          setContStsData(oContStsData)
          setDialogData(data)
          setDXData(oDXData)
          setDIData(oDIData)

        }
      })
    } else {
      setDialogData({ ...initDialogData })
      setContStsData(msData.containerStatuses)
      setDXData([])
      setDIData({ ...initDIData })
      setIsShowLoading1(false)
      setIsShowLoading2(false)
    }
  }, [ax, jobOrdId, dialogOpen, msData.containerStatuses])

  console.log("contStsData:::", contStsData)
  console.log("dialogData:::", dialogData)
  console.log("dxData:::", dxData)
  console.log("diData:::", diData)
  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={handleClose}
        title="ข้อมูลใบงาน"
      />
      <DialogContent>
        <BoxFR mt={2} sx={sxFlexStart}>
          <BoxFC sx={{ gap: 1, width: 500 }}>
            <Paper sx={{ px: 2, pb: 2, width: 500 }} elevation={4}>
              <Typography variant="h6">พนักงานขับรถ</Typography>
              <BoxFR>
                {contStsData.map((item, index) => {
                  // console.log("contStsData::", contStsData)
                  if (index === 0) {
                    return drawBox(msData, item, handleDriverContStsChange(item), msData.driverCombo)
                  }
                  /**check if there is any drv id in box b4 this box return it */
                  for (let i = index - 1; i >= 0; i--) {
                    if (contStsData[i].DrvId) {
                      return drawBox(msData, item, handleDriverContStsChange(item), suggestDrvCombo(contStsData[i].DrvId))
                    }
                  }
                  /**otherwise, no drvid in any prev box return normal */
                  return drawBox(msData, item, handleDriverContStsChange(item), msData.driverCombo)
                })}
              </BoxFR>
            </Paper>

            <Paper sx={{ px: 2, pb: 2, width: 500 }} elevation={4}>
              <Typography variant="h6">ข้อมูลตู้</Typography>
              <BoxFC sx={{ gap: 1 }}>
                <BoxFR position="relative">
                  <TextField {...dialogDataProp("ContNo1")} label="หมายเลขตู้1" sx={{ flex: 1 }} />
                  <TextField {...dialogDataProp("ContNo2")} label="หมายเลขตู้2" sx={{ flex: 1 }} />
                  {isShowLoading1 &&
                    <BoxFR position="absolute" width="100%">
                      <BoxFC flex={1} alignItems="flex-end" ><CircularProgress color="secondary" /></BoxFC>
                      <BoxFC flex={1}></BoxFC>
                    </BoxFR>}
                  {isShowLoading2 &&
                    <BoxFR position="absolute" width="100%">
                      <BoxFC flex={1}></BoxFC>
                      <BoxFC flex={1} alignItems="flex-end" ><CircularProgress color="secondary" /></BoxFC>
                    </BoxFR>}
                </BoxFR>
                <BoxFR>
                  <BoxFR sx={{ flex: 1 }}>
                    <DateTimePickerCustomTime {...datePickerJobOrderProp("FinDte")} />
                    <IconButton onClick={hanldeNowClick} sx={{ p: 0 }}>
                      <WatchLaterRoundedIcon color="primary" fontSize="medium" />
                    </IconButton>
                  </BoxFR>
                  <BoxFR sx={{ flex: 1 }}>
                    <DateTimePickerCustomTime {...datePickerJobOrderProp("AptTm", true)} />
                  </BoxFR>
                </BoxFR>
                <BoxFR>
                <TextField {...dialogDataProp("Ref2")} sx={{ flex: 1 }} label={msData.serverData.RefColumnName?.job_order?.Ref2 || "Reference"}/> 
                <TextField {...dialogDataProp("Rmk")} sx={{ flex: 1 }} /> 
                </BoxFR>
              </BoxFC>
            </Paper>
            <Paper sx={{ px: 2, pb: 2 }} elevation={4}>
              <Typography variant="h6">ค่าเที่ยว</Typography>
              <BoxFC sx={{ gap: 1 }}>
                <BoxFR sx={{ alignItems: "flex-start" }}>
                  <TextField {...diDataPropAmnt} />
                  <BoxFR sx={{ flex: 1 }}>
                    <ComboBox sx={{ flex: 1 }} options={dxDrvCombo} label="พนักงานขับรถ" required={true}
                      selectedId={diData.DrvId} setSelectedId={handleDIDrvIdChange} />
                  </BoxFR>
                </BoxFR>
                <BoxFR sx={{ alignItems: "flex-start" }}>
                  <TextField {...diDataProp("ExtAmnt")} {...moneyProp} />
                  <TextFieldWithOption {...diDataProp("ExtTxt")}
                    setValue={handleExtTxtChange}
                    options={msData.serverData.PreText.DriverIncomeExtras} />
                </BoxFR>
              </BoxFC>
            </Paper>
            <Paper sx={{ px: 2, pb: 2 }} elevation={4}>
              <BoxFR>
                <Typography variant="h6">เงินแนบใบงาน</Typography>
                <IconButton onClick={handleAddDX}>
                  <AddCircleIcon color="primary" />
                </IconButton>
              </BoxFR>
              <BoxFC sx={{ gap: 1, pt: 1 }}>
                {dxData.map((dx, index) => {
                  return (
                    <BoxFR key={dx.DalyExpId}>
                      <TextField {...dxDataProp("Amnt", dx)} sx={{ width: 130 }} type="number"
                        inputProps={{ style: { textAlign: 'right' } }}
                        InputProps={{ endAdornment: <InputAdornment position="end">บาท</InputAdornment> }} />
                      <TextFieldWithOption {...dxDataProp("Rmk", dx)}
                        setValue={handleDXRmkChange(dx)}
                        options={msData.serverData.PreText.DailyExpense} />
                      {/* <TextField {...dxDataProp("Rmk", dx)} sx={{ flex: 1 }} /> */}
                      <ComboBox sx={{ width: 120 }} options={dxDrvCombo} label="พนักงานขับรถ"
                        selectedId={dx.DrvId} setSelectedId={handleDXDriverChange(dx)} />
                      {dx.IsAddData ?
                        <IconButton sx={{ p: 0 }} onClick={handleRemoveAddedDXItem(dx)}>
                          <DeleteRoundedIcon />
                        </IconButton>
                        :
                        <Tooltip title="เคลียค่าใช้จ่าย">
                          <Checkbox size="medium" sx={{ p: 0 }}
                            checked={dx.ClrDte != null}
                            icon={<PlaylistAddCheckCircleIcon color="disable" />}
                            checkedIcon={< PlaylistAddCheckCircleIcon color="success" />}
                            onChange={handleClearCheckboxChange(dx)}
                          />
                        </Tooltip>
                      }
                    </BoxFR>
                  )
                })}
              </BoxFC>
            </Paper>
          </BoxFC>
          <Paper sx={{ px: 2, width: 250 }} elevation={4}>
            <BoxFC>
              <Typography variant="h6">ข้อมูลใบงาน</Typography>
              <TextField {...dialogDataProp("JobNo", true)} />
              <TextField {...dialogDataProp("Bkg", true)} />
              <TextField {...dialogDataProp("Agnt", true)} />
              <TextField {...dialogDataProp("ContSizeFull", true)} />
              <TextField {...dialogDataProp("TakePlc", true)} />
              <TextField {...dialogDataProp("RtnPlc", true)} />
              <TextField {...dialogDataProp("TagNm", true)} />
            </BoxFC>
          </Paper>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={handleClose}
        handleDialogOk={handleOk} />
    </Dialog>
  )
}

export default React.memo(JobOorderDriverIncomeDialog)