import { jobOrderApiNm } from "constants/jobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { useCallback, useContext, useEffect, useState } from "react"
import { addIdForDataGrid } from "utils"


let selectedJobOrdId = null

const useClientToday = () => {

  const { ax } = useContext(GlobalStateContext)

  const [todayData, setTodayData] = useState([])
  const [scheduleData, setScheduleData] = useState([])
  const [dialogImageOpen, setDialogImageOpen] = useState(false)

  const getData = useCallback(() => {
    ax.post(jobOrderApiNm.getJobOrderForClientSummary, {}).then(value => {
      if (value.data) {
        setTodayData(addIdForDataGrid(value.data.today,"JobOrdId"))
        setScheduleData(addIdForDataGrid(value.data.schedule, "JobOrdId"))
      }
    })
  }, [ax])
  const handleImageClick = useCallback((jobOrdId)=>(e)=>{
    selectedJobOrdId  = jobOrdId
    setDialogImageOpen(true)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])
  return {
    state: { todayData, scheduleData, dialogImageOpen, selectedJobOrdId },
    fn: {handleImageClick, setDialogImageOpen, getData}
  }
}

export default useClientToday