import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useContext, useEffect, useMemo } from "react";
import { Box, Button, ButtonGroup, MenuItem, TextField, Typography, useMediaQuery } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { columns } from "./columns";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { addIdForDataGrid, calculateRowClosingColor } from "utils";
import { useCallback } from "react";
import { useState } from "react";
import ComboBox from "components/ComboBox";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import { initFilterData } from "./initData";
import { shipmentColNm } from "constants/shipmentConstant";
import dayjs from "dayjs";

const ContainerClosingSummary = () => {
  const [selectedGroupId, setSelectedGroupId] = useState(1)
  const [filterData, setFilterData] = useState(initFilterData)
  const { msData, ax } = useContext(GlobalStateContext)
  const [rawDataTable, setRawDataTable] = useState([])

  const w800 = useMediaQuery('(min-width:800px)');

  const dataTable = useMemo(() => {

    if (selectedGroupId === 4) {
      return addIdForDataGrid([...rawDataTable], "JobOrdId")
    } else {
      return addIdForDataGrid(rawDataTable.filter(row => {
        if (selectedGroupId === 1) {
          return dayjs(row.RtnDteEn).isBefore(dayjs().add(1, "day"))
        } else if (selectedGroupId === 2) {
          return dayjs(row.RtnDteEn).isBefore(dayjs().add(2, "day"))
        } else if (selectedGroupId === 3) {
          return dayjs(row.RtnDteEn).isBefore(dayjs().add(3, "day"))
        } else return true
      }), "JobOrdId")
    }
  }, [rawDataTable, selectedGroupId])

  const searchData = useCallback((filter) => {
    ax.post(jobOrderApiNm.getContainerClosing, filter).then(value => {
      if (value.data) {
        setRawDataTable(addIdForDataGrid(value.data, "JobOrdId"))
      }
    })
  }, [ax])
  const filterProps = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    sx: { width: 150 },
    label: jobOrderColNm[name],
    value: filterData[name] || "",
    onKeyUp: e => { if (e.code === "Enter" || e.code === "NumpadEnter") { searchData(filterData) } },
    onChange: (e) => { setFilterData(oldState => ({ ...oldState, [name]: e.target.value || null })) }
  }), [filterData, searchData])

  const buttonGroupProps = useCallback((groupId) => ({
    key: groupId,
    onClick: () => setSelectedGroupId(groupId),
    variant: selectedGroupId === groupId ? "contained" : "outlined"
  }), [selectedGroupId])

  useEffect(() => {
    searchData(initFilterData)
  }, [searchData])

  return (
    <BoxFC mx={2} height="100%" >
      {w800 ? (
        <BoxFR ml={1}>
          <Typography>Cut-off time  :</Typography>
          <ButtonGroup variant="outlined" >
            <Button {...buttonGroupProps(1)}>In 24 Hrs</Button>
            <Button {...buttonGroupProps(2)}>In 48 Hrs</Button>
            <Button {...buttonGroupProps(3)}>In 72 Hrs</Button>
            <Button {...buttonGroupProps(4)}>All</Button>
          </ButtonGroup>
          <Box flex={1} />
          <TextField {...filterProps("JobNo")} />
          <TextField {...filterProps("Bkg")} />
          <TextField {...filterProps("ContNo")} />
          <TextField {...filterProps("Loc")} />
          <ComboBox sx={{ width: 160 }} options={msData.cusCombo} label={shipmentColNm.CusSNm}
            selectedId={filterData.CusId}
            setSelectedId={(cusId) => setFilterData(o => ({ ...o, CusId: cusId }))}
          />
          <SearchButton onClick={() => { searchData(filterData) }} />
          <ClearButton onClick={() => { setFilterData(initFilterData) }} />
        </BoxFR>
      ) : (
        <BoxFR ml={1}>
          <Typography>Cut-off time  :</Typography>
          <TextField size="small" variant="outlined" select sx={{ flex: 1 }}
            value={selectedGroupId}
            onChange={(e)=>{setSelectedGroupId(e.target.value)}}>
              <MenuItem value={1}>In 24 Hrs</MenuItem>
              <MenuItem value={2}>In 48 Hrs</MenuItem>
              <MenuItem value={3}>In 72 Hrs</MenuItem>
              <MenuItem value={4}>All</MenuItem>
          </TextField>
        </BoxFR>
      )}

      <Box width="100%" flex={1}
        sx={{
          "& .container-closing-red": {
            bgcolor: "salmon!important",
            "&:hover": {
              bgcolor: "darkSalmon!important"
            }
          },
          "& .container-closing-yellow": {
            bgcolor: "khaki!important",
            "&:hover": {
              bgcolor: "darkKhaki!important"
            }
          },
        }}>
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          getRowClassName={calculateRowClosingColor}
          columns={columns} />
      </Box>
    </BoxFC>
  )
}

export default ContainerClosingSummary