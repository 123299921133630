/**
 * 
 * @param {*} d1  
 * @param {*} d2 
 * @returns boolean
 * 
 * d1, d2 ar joData that contains TakePlc, TakePOIId, Loc, LocPOIId, RtnPlc and RtnPOIId
 * 
 * Mainly use for check duplicate and add in array
 */
export const isSamePlace = (d1, d2) => {
  // if(d1.TakePlc === "D1" || d1.TakePOIId === 29){
  //   console.log("d1::", d1)
  //   console.log("d2::", d2)
  // }
  return (((!d1.TakePlc && !d2.TakePlc) || (d1.TakePlc === d2.TakePlc)) && ((!d1.TakePOIId && !d2.TakePOIId) || (d1.TakePOIId === d2.TakePOIId)))
  && (((!d1.Loc && !d2.Loc) || (d1.Loc === d2.Loc)) &&((!d1.LocPOIId && !d2.LocPOIId) || (d1.LocPOIId === d2.LocPOIId)))
  && (((!d1.RtnPlc && !d2.RtnPlc) || (d1.RtnPlc === d2.RtnPlc)) &&((!d1.RtnPOIId && !d2.RtnPOIId) || (d1.RtnPOIId === d2.RtnPOIId)))
}