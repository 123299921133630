import { Badge, Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import React from 'react';
import { useContext } from 'react';

const NavMenuItem = ({ num, text, badgeName }) => {
  const {badge} = useContext(GlobalStateContext)

  return (
    <Badge badgeContent={badgeName?badge[badgeName]:0} color="error" sx={{ width: '100%' }}>
    <Box display='flex' justifyContent='flex-start' sx={{ width: '100%' }}>
      <ButtonGroup fullWidth>
        <Button variant='contained' size='small' style={{ width: 40, minWidth: 40 }}>
          {num}
        </Button>
        <Button variant='outlined' size='small' fullWidth>
          {text}
        </Button>
      </ButtonGroup>
    </Box>
      </Badge>
  )
}

export default NavMenuItem;