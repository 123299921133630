

export const getPOIPlace = (jobOrderData, type)=>{

  const msData = JSON.parse(localStorage.getItem("msData"))

  let poiIdName = ""
  let placeName = ""
  if(type === "TAKE"){
    poiIdName = "TakePOIId"
    placeName = "TakePlc"
  } else if(type === "RTN"){
    poiIdName = "RtnPOIId"
    placeName = "RtnPlc"
  } else if(type === "LOC"){
    poiIdName = "LocPOIId"
    placeName = "Loc"
  } else {
    return ""
  }

  // return jobOrderData[poiIdName] ? msData?.poiCombo.find(poi=>poi.id === jobOrderData[poiIdName])?.label : jobOrderData[placeName]
  return jobOrderData[poiIdName] ? msData?.poiObj[jobOrderData[poiIdName]] || "" : jobOrderData[placeName] || ""
}