import { BoxFC, BoxFR } from "components/BoxCustom";
import React from "react";
import useClientToday from "./useClientToday"
import { Box, Divider, IconButton, Typography, useMediaQuery } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { scheduleJobColumns, todayColumns } from "./columns";
import { blue } from "@mui/material/colors";
import closingColor from "utils/closingColor";
import { calculateRowClosingColor } from "utils";
import JobOrderImageDialog from "./JobOrderImageDialog";
import { RefreshRounded } from "@mui/icons-material";

const ClientToday = () => {
  const { state, fn } = useClientToday();
  const { todayData, scheduleData, selectedJobOrdId } = state;

  const w800 = useMediaQuery('(min-width:800px)');
  return (
    <BoxFC mx={2} height="100%" sx={{
      "& .MuiDataGrid-columnHeaders": { backgroundColor: blue[100] }
    }}>
      <BoxFR>
        <Typography variant="h5">Today</Typography>
        <IconButton sx={{ p: 0 }} onClick={fn.getData}>
          <RefreshRounded color="primary" />
        </IconButton>
      </BoxFR>
      <Box width="100%" flex={1} sx={closingColor}>
        <DataGridCellExpand
          density={w800 ? "standard" : "compact"}
          hideFooter
          rows={todayData}
          columns={todayColumns(fn.handleImageClick)}
          getRowClassName={calculateRowClosingColor} />
      </Box>
      <Divider />
      <Typography variant="h5" mt={2}>Schedule Jobs</Typography>
      <Box width="100%" flex={1} sx={closingColor}>
        <DataGridCellExpand

          density={w800 ? "standard" : "compact"}
          hideFooter
          rows={scheduleData}
          columns={scheduleJobColumns(fn.handleImageClick)}
          getRowClassName={calculateRowClosingColor} />
      </Box>
      <JobOrderImageDialog
        dialogOpen={state.dialogImageOpen}
        setDialogOpen={fn.setDialogImageOpen}
        selectedId={selectedJobOrdId}
      />
    </BoxFC>
  )
}

export default ClientToday