import { PlaylistAddCheckCircleRounded } from "@mui/icons-material";
import { dailyExpenseColNm } from "constants/dailyExpenseConstant";
import dayjs from "dayjs";
import { numberFormat } from "utils";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { locationText } from "utils/locationText";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { BoxFR } from "components/BoxCustom";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LensIcon from '@mui/icons-material/Lens';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { IconButton } from "@mui/material"
const baseExpenseColumn = [
  {
    field: "JobOrdId",
    headerName: dailyExpenseColNm.JobOrdId,
    width: 90,
    align: "center"
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: (params) => params.row.TakePOIId == null && params.row.LocPOIId == null && params.row.RtnPOIId === null ?
      "-" : locationText(params.row),
    width: 190,
  },
  {
    field: "ContSizeFull",
    headerName: dailyExpenseColNm.ContSizeFull,
    width: 60,
  },
  {
    field: "IsCash",
    headerName: dailyExpenseColNm.IsCash,
    width: 65,
    valueFormatter: (params) => params.value === 1 ? "เงินสด" : "เงินโอน",
  },
  {
    field: "Amnt",
    headerName: dailyExpenseColNm.Amnt,
    width: 80,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(parseFloat(params.value)) : "0",
  },
  {
    field: "Dscp",
    headerName: dailyExpenseColNm.Dscp,
    flex: 1
  },
]
export const dailyExpenseColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "ModDte",
    headerName: "วันที่ทำรายการ",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  ...baseExpenseColumn,
  {
    field: "ClrDte",
    headerName: "Clear",
    width: 40,
    align: "center",
    renderCell: (params) => <PlaylistAddCheckCircleRounded color={params.value ? "success" : "disabled"} />
  }

]

export const driverExpenseColumns = (handleCompleteExpense) => [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "ExpDte",
    headerName: dailyExpenseColNm.ExpDte,
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  ...baseExpenseColumn,
  {
    field: 'actions',
    type: 'actions',
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<PlaylistAddCheckCircleRounded />}
        label="Clear"
        onClick={() => handleCompleteExpense(params.row)}
      />,
      // <GridActionsCellItem
      //   icon={<PlaylistAddCheckCircleRounded />}
      //   label="Clear"
      //   onClick={() => handleCompleteExpense(params.row)}
      // />
    ]
  }
]

export const jobOrderColumns = (toggleContSts) => [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 70,
  },
  {
    field: "Location",
    headerName: "สถานที่",
    valueGetter: (params) => locationText(params.row),
    flex: 1,
  },
  {
    field: "ContSts",
    headerName: "สถานะ",
    width: 120,
    renderCell: (params) => {
      const msData = JSON.parse(localStorage.getItem("msData"))
      const contStsesMaster = msData.containerStatuses.filter(sts => !sts.IsHide)
      const contStses = params.row.ContStses || []
      const contStsObj = contStses.reduce((prev, cur) => {
        prev[cur.ContStsId] = cur
        return prev
      }, {})
      return (
        <BoxFR sx={{ gap: 0 }}>
          {contStsesMaster.map(contSts => {
            const contStsId = contSts.ContStsId
            return contStsObj[contStsId] ?
              <IconButton key={contStsId} sx={{ p: 0 }} onClick={toggleContSts(contStsObj[contStsId])}>
                {contStsObj[contStsId].IsCmpt ?
                  <CheckCircleRoundedIcon color={contSts.BgClr} />
                  :
                  <LensIcon color={contSts.BgClr} />
                }
              </IconButton>
              :
              <PanoramaFishEyeIcon key={contStsId} color={contSts.BgClr} />
          })}
        </BoxFR>
      )
    }
  },
]