
import { jsPDF } from "jspdf";
import { numberFormat, toNumber } from "utils";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
// import { columnHeader } from "./columns";
import companyLogo from "branch/resource/company-logo.png"


export const makeDataRow = (no, rcptNo, invNo, invDte, ownRcptAmnt, cusRcptAmnt, totalInv, whtPr, total) => {
  return {
    No: no,
    RcptNo: rcptNo,
    InvNo: invNo,
    InvDte: invDte,
    OwnRcptAmnt: ownRcptAmnt,
    CusRcptAmnt: cusRcptAmnt,
    TotalInv: totalInv,
    WhtPr: whtPr,
    Total: total
  }
}
/**
 * 
 * extraData :{
 *  TitleData: [{name, value, headerWidth}],
 *  
 * } 
 */

const printDataTable = ({ title, extraData, dataTable, companyData, columnHeader, isShowSum, maxPageSize, tableFontSize, paddingB4Top, marginLeft }) => {
  const insertText = (separatorRow, text) =>{
    const textCol = columnHeader.find(col=>col.separateRow === "#TEXT#")
    if(!textCol){
      return false
    }
    for(const col in separatorRow){
      if(col === textCol.name){
        separatorRow[col] = text
        break;
      }
    }
    return true
  }
  const printExtra = (data, xPos, yPos) => {
    doc.setFont("THSarabun", "bold")
    doc.text(data.header, xPos, yPos)
    doc.setFont("THSarabun", "normal")
    doc.text(data.value ?? "-", xPos + data.headerWidth + (data.valueWidth || 0), yPos, null, null, data.align || "left")
  }
  // console.log("rvData ::", rvData)
  console.log("dataRows ::", dataTable)
  console.log("extraData ::", extraData)


  const MAX_PAGE_SIZE = maxPageSize || 20
  const PADDING_B4_TOP = paddingB4Top || 0
  const pageSize = [8.3, 11.7]; //A4
  const defaultHeaderSize = 20;
  const comapanyAddrSize = 10;
  const contentSize = 14;

  const tablePadding = 0.05
  const talbeLineWidth = 0.01
  const startY = 0.4
  const logoWidth = 1.6
  const logoHeight = 0.85
  const startX = 0.5 + (marginLeft || 0)
  const lineHeight = 0.2

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });

  let realDataRowCount = 0
  for (const data of dataTable) {
    if (data.No && !isNaN(+data.No) && +data.No > realDataRowCount) {
      realDataRowCount = +data.No
    }
  }

  const totalPage = Math.ceil(realDataRowCount / MAX_PAGE_SIZE)
  console.log("totalPage::", totalPage)
  let pageNo = 1
  let lastNextPageRow = null
  do {
    
    let yPos = startY
    let xPos = startX

    /**
     * Have to use constPageNo = pageNo coz ESLint warning
     * Function declared in a loop contains unsafe references to variable(s) 'pageNo', 'pageNo'  no-loop-func
     */
    const constPageNo = pageNo
    //--------------------------------- Calculate seperate row --------------------------------------------------------
    let printRows = dataTable.filter((item, index) => index >= (constPageNo - 1) * MAX_PAGE_SIZE && index < constPageNo * MAX_PAGE_SIZE)
    
    const colSum =  columnHeader.map(item => ({
      id: -10,
      name: item.name,
      value: item.separateRow === "#SUM#" ? 0 : item.separateRow,
      isSum: item.separateRow === "#SUM#",
      isText: item.separateRow === "#TEXT#",
      isBold: true
    }))

    if(lastNextPageRow){
      for (const col of colSum) {
        if(col.isSum){
          col.value += toNumber(lastNextPageRow[col.name])
        }
      }
    }
    if (pageNo !== 1) {
      if(insertText(lastNextPageRow, "ยกยอดมา")){
        printRows = [{...lastNextPageRow, isBold: true, isSeparateRow: true}, ...printRows, ]
      }
      doc.addPage(pageSize, "portrait")
    }
    for (const item of printRows) {
      if(!item.isSeparateRow){
        for (const col of colSum) {
          if (col.isSum ) {
            col.value += toNumber(item[col.name])
          }
        }
      }
    }
    const separateRow = colSum.reduce((prev, cur) => ({ ...prev, [cur.name]: cur.isSum ? numberFormat(cur.value) :cur.value }), {})
    separateRow.isBold = true
    if ((pageNo) * MAX_PAGE_SIZE < dataTable.length) {
      
      if(insertText(separateRow, "ยกยอดไป")){
        printRows.push(separateRow)
      }
      lastNextPageRow = separateRow
    }else {
      if(isShowSum){
        if(insertText(separateRow, "รวม")){
          printRows.push(separateRow)
        }
      }
    }


    //--------------------------------- End Calculate seperate row ------------------------------------------------------

    
    //--------------------------------- Print Topic  ------------------------------------------------------------------
    let yTopic = startY + 0.2
    xPos = 5.5
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(22)
    doc.text(`${title} ${totalPage > 1 ? `(${pageNo}/${totalPage})` : ""}`, xPos, yTopic)
    if(extraData && extraData.title){
      doc.setFontSize(contentSize)
      yTopic += lineHeight * 5 / 4
      for(const data of extraData.title){
        printExtra(data, xPos, yTopic)
        yTopic += lineHeight 
      }
    }
    //--------------------------------- End Print Topic  ------------------------------------------------------------------

    
    //--------------------------------- Print CompanyData  ------------------------------------------------------------
    const img = new Image()
    //TODO:: finally change to /file/etc/company_log.png
    img.src = companyLogo

    xPos = startX
    try {
      doc.addImage(img, 'png', xPos, yPos, logoWidth, logoHeight)
      xPos += logoWidth 
    } catch (e) {
      console.log("error::", e)
    }
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(defaultHeaderSize)
    yPos += 0.2
    doc.text(companyData.NameTH, xPos, yPos)
    doc.setFont("THSarabun", "normal")
    doc.setFontSize(comapanyAddrSize)
    yPos += lineHeight
    doc.text(companyData.AddrTH, xPos, yPos)
    yPos += lineHeight
    doc.text(`เลขประจำตัวผู้เสียภาษี: ${companyData.TaxId}`, xPos, yPos)
    yPos += lineHeight
    doc.text(`โทร: ${companyData.Tel}${companyData.Fax && ` Fax: ${companyData.Fax}`}`, xPos, yPos)

    //--------------------------------- End Print CompanyData  ------------------------------------------------------------

    
    //--------------------------------- Top Table  ------------------------------------------------------------
    doc.setFontSize(contentSize)
    yPos += lineHeight * 3 + PADDING_B4_TOP
    if(pageNo === 1){
      xPos = startX + 0.7
      if(extraData && extraData.top){
        doc.setFontSize(contentSize)
        for(const data of extraData.top){
          printExtra(data, xPos, yPos)
          yPos += lineHeight 
        }
      }
    }
    //--------------------------------- Table  ------------------------------------------------------------
    doc.setLineWidth(talbeLineWidth)
    console.log("printRows::", printRows)
    doc.table(startX, yPos, printRows, columnHeader, {
      autoSize: false,
      padding: tablePadding,
      headerBackgroundColor: "#cce6ff",
      fontSize: tableFontSize?tableFontSize:null,
      rowStart: function (row, jspdf) {
        if (row.data.isBold) {
          jspdf.setFont("THSarabun", "bold")
        } else {
          jspdf.setFont("THSarabun", "normal")
        }
      }
    })
    //--------------------------------- Bottom Table  ------------------------------------------------------------
    if (pageNo === totalPage && extraData && extraData.bottom) {
      let dimen = doc.getTextDimensions("MTB2205001")
      console.log("dimen::", dimen)
      console.log("printRows.length ::", printRows.length )
      /**
       *  1/6 come from dimen of data if change fontsize have to see log and change this value
       */
      // let testY = yPos + tablePadding*2 
      // doc.text("row0", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h; 
      // doc.text("row1", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h
      // doc.text("row2", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h
      // doc.text("row3", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h
      // doc.text("row4", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h
      // doc.text("row5", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h
      // doc.text("row6", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h
      // doc.text("row7", 0.2, testY);  testY += tablePadding*2 + talbeLineWidth *2 + dimen.h

      doc.setFontSize(contentSize)
      yPos += (printRows.length + 1) * (tablePadding*2 + talbeLineWidth *2 + dimen.h) //printRows.length + 1 coz header 
      yPos += 0.5
      
      xPos = startX + 0.7
      for(const data of extraData.bottom){
        printExtra(data, xPos, yPos)
        yPos += lineHeight + lineHeight/5
      }
      // doc.setFont("THSarabun", "bold")
      // doc.setFontSize(contentSize)
      // doc.text(`รายละเอียดส่วนต่าง: `, xPos, yPos)
      // doc.setFont("THSarabun", "normal")
      // doc.text(`${rvData.DiffDscp || "-"}`, xPos + 1.2, yPos)
    }


    pageNo++
  } while (pageNo <= totalPage)

  doc.output('dataurlnewwindow');
}

export default printDataTable