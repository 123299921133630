import { Switch, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { navMenuItemsHeader } from "components/MainLayout/constant";
import React from "react";

const DefaultOpenMenuContent = ({defaultOpenMenu, setDefaultOpenMenu})=> {

  return (
    <BoxFC>
    <Typography variant="h6">เปิดเมนูเริ่มต้น</Typography>
    <BoxFC sx={{ gap: 0 }} pl={2}>
      {navMenuItemsHeader.map(item => (
        <BoxFR key={item.num}>
          <Typography sx={{ width: 200 }}>{`${item.num}. ${item.menuName}`}</Typography>
          <Switch checked={defaultOpenMenu[item.num]} onChange={(e) => setDefaultOpenMenu(o => ({ ...o, [item.num]: e.target.checked }))} />
        </BoxFR>
      ))}
    </BoxFC>
  </BoxFC>
  )
}

export default React.memo(DefaultOpenMenuContent)