import React from "react";
import { Button } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';


const SaveButton = ({children, onClick, color, disabled, sx}) => {

    return (
        <Button
            variant="contained"
            color={color || "primary"}
            disabled={disabled}
            sx={sx}
            onClick={onClick}>
            <SaveIcon sx={{ mr: 1 }} />
            {children||`บันทึก`}
        </Button>
    )
}

export default SaveButton