import dayjs from "dayjs"

export const clearFilterData = {
  JobOrdId: null,
  DrvId: null,
  JobNo: null,
  Bkg: null,
  AptTmSt: null,
  AptTmEn: null,
  CusId: null,
  JobId: null,
  ContNo: null,
  IsNotFin: null
}

export const initFilterData = {
  ...clearFilterData,
  AptTmSt: dayjs().format("YYYY-MM-DD 00:00:00.000"),
  AptTmEn: dayjs().format("YYYY-MM-DD 23:59:59.997"),
}
