import { Button, MenuItem, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useMemo } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import { coreApiNm } from "constants/serverConstant";
import { useState } from "react";
import { initIncomeByDateChartData, initIncomeByDateFilter, incomeLineChartOption } from "../initData";
import { bgGraphToneColor } from "../constant";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { useCallback } from "react";
import ComboBox from "components/ComboBox";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { addIdForDataGrid, sortObjDesc } from "utils";
import YearSelect from "components/YearSelect";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { incomeByCusYearColumns, overDueYearMonthColumns } from "./columns";
import { Box } from "@mui/system";
import { red, green } from '@mui/material/colors';
import dayjs from "dayjs";
import { leftRightComboKeyUp } from "utils/keyUpSearchData";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, CategoryScale, LinearScale, PointElement, LineElement, BarElement);

const titleProp = {
  textAlign: "center",
  variant: "overline",
  sx: { fontSize: "1rem" },
  lineHeight: "100%"
}


const getCellClassName = (params) => {
  // console.log("in getCellClassName params::", params)
  if (params.field === "DiffAmnt" && params.value !== 0) {
    return "has-diff"
  }
  return ""
}

let calYears = []
let allDataTable = []


const calculateDataTable = (selectedDataset) => {

  const sumRow = { id: 0, No: "", JobId: 0, SName: "รวม" }
  for (const year of calYears) {
    sumRow[year] = 0
  }


  const selectedDataTable = allDataTable.map((row) => {
    const newRow = {
      JobId: row.JobId,
      SName: row.SName
    }
    let sum = 0;
    calYears.forEach(year => {
      newRow[year] = row[year] ? row[year][selectedDataset] : ""
      sum += newRow[year] ? +newRow[year] : 0
      sumRow[year] += newRow[year] ? +newRow[year] : 0
    })
    newRow.sum = sum
    return newRow
  })
  console.log("selectedDataTable::", selectedDataTable)
  sortObjDesc(selectedDataTable, "sum")
  const finalResult = addIdForDataGrid(selectedDataTable, "JobId")
  finalResult.splice(0, 0, sumRow)
  return finalResult
}

const InvoiceReport = () => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [chartData, setChartData] = useState(initIncomeByDateChartData)
  const [incomeByDateFilter, setIncomeByDateFilter] = useState(initIncomeByDateFilter)
  const [dataTable, setDataTable] = useState([])
  const [invoiceTable, setInvoiceTable] = useState([])
  const [tableColumns, setTableColumns] = useState([])
  const [selectedDataset, setSelectedDataset] = useState("Total")


  const w800 = useMediaQuery('(min-width:800px)');


  const modIncomeLineChartOption = useMemo(() => {
    const result = { ...incomeLineChartOption }
    if (!w800) {
      result.plugins.legend.position = "bottom"
    }
    return result
  }, [w800])

  const searchIncomeByDate = useCallback((filter, selectedDataset) => {
    ax.post(coreApiNm.getMonthlyIncomeReportClient, filter).then(value => {
      if (value.data) {
        const data = value.data
        const datasets = []
        const startYear = dayjs(filter.AptTmSt).year()

        for (let i = 0; i < 5; i++) {
          datasets.push({
            label: `${+startYear + i}`,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            CusAmnt: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            OwnAmnt: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            OwnNoTrnAmnt: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            Total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            TrnAmnt: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            borderColor: bgGraphToneColor[i],
            backgroundColor: bgGraphToneColor[i],
            tension: 0.2,
            pointStyle: "circle",
          })
        }
        console.log("dataSet::", datasets);
        for (const item of data.incomeByDate) {
          const year = item.YearMonth.toString().substring(0, 4)
          const found = datasets.find(it => it.label === year)
          if (!found) {
            console.log(`Not found year ${year}!!!`);// this should not occur
            continue
          }
          const month = item.YearMonth.toString().substring(4, 6)
          found.data[+month - 1] = +item[selectedDataset]
          found.CusAmnt[+month - 1] = +item.CusAmnt
          found.OwnAmnt[+month - 1] = +item.OwnAmnt
          found.OwnNoTrnAmnt[+month - 1] = +item.OwnNoTrnAmnt
          found.Total[+month - 1] = +item.Total
          found.TrnAmnt[+month - 1] = +item.TrnAmnt
        }
        const incomeByDateData = {
          labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
          datasets: datasets
        }

        //------------------------------------------- lower part -----------------------------------------

        // const sumCuses = []
        let lastCus = { CusId: 0 };
        calYears = []

        // const cols = ["OwnAmnt", "CusAmnt", "OwnNoTrnAmnt", "TrnAmnt", "Total"]


        allDataTable = []

        data.incomeByCusYear.forEach(item => {
          if (lastCus.JobId !== item.JobId) {
            lastCus = {
              JobId: item.JobId,
              SName: item.SName,
              [item.Year]: {
                OwnAmnt: +item.OwnAmnt,
                CusAmnt: +item.CusAmnt,
                OwnNoTrnAmnt: +item.OwnNoTrnAmnt,
                TrnAmnt: +item.TrnAmnt,
                Total: +item.Total,
              }
            }
            allDataTable.push(lastCus)
          } else {
            lastCus[item.Year] = {
              OwnAmnt: +item.OwnAmnt,
              CusAmnt: +item.CusAmnt,
              OwnNoTrnAmnt: +item.OwnNoTrnAmnt,
              TrnAmnt: +item.TrnAmnt,
              Total: +item.Total,
            }
          }



          if (!calYears.includes(item.Year)) calYears.push(item.Year)
        })
        console.log("allDataTable::", allDataTable)
        // sortObjDesc(sumCuses, selectedDataset)
        calYears.sort((a, b) => a - b)

        setTableColumns(incomeByCusYearColumns(calYears))
        setChartData({ incomeByDateData })

        setDataTable(calculateDataTable(selectedDataset))

        const sumRow = data.invoiceNotPaid.reduce((sumData, row) => ({
          "YearMonth": "รวม",
          OwnRcptAmnt: sumData.OwnRcptAmnt + +row.OwnRcptAmnt,
          CusRcptAmnt: sumData.CusRcptAmnt + +row.CusRcptAmnt,
          Total: sumData.Total + +row.Total,
          TotalInv: sumData.TotalInv + +row.TotalInv,
          TotalPay: sumData.TotalPay + +row.TotalPay,
        }), { id: 0, CusRcptAmnt: 0, OwnRcptAmnt: 0, Total: 0, TotalInv: 0, TotalPay: 0 })

        const notPaidTable = [sumRow, ...data.invoiceNotPaid]
        setInvoiceTable(addIdForDataGrid(notPaidTable, "YearMonth"))
      }
    })
  }, [ax,])


  const handleComboKeyUp = useCallback((e) => {
    if (e.keyCode === 13) {
      setTimeout(() => searchIncomeByDate(incomeByDateFilter, selectedDataset), 100)
      return
    }
    leftRightComboKeyUp(e, msData.driverOnlyCombo, "DrvId", setIncomeByDateFilter, searchIncomeByDate)
  }, [incomeByDateFilter, searchIncomeByDate, selectedDataset, msData])

  const handleDatasetChange = useCallback((e) => {
    const newDataset = e.target.value
    setSelectedDataset(newDataset)
    // setChartData(initIncomeByDateChartData)
    setChartData(o => {
      const returnData = { ...o }
      console.log("returnData::", returnData)
      const incomeByDateData = { ...returnData.incomeByDateData }

      console.log("incomeByDateData::", incomeByDateData)
      incomeByDateData.datasets = incomeByDateData.datasets.map(item => item)

      for (const dataset of incomeByDateData.datasets) {
        dataset.data = dataset[newDataset].map(item => item)
      }

      return { incomeByDateData }
    })
    setDataTable(calculateDataTable(newDataset))
    // setDataTable(o => {
    //   console.log("datatAble::", o)
    //   return o
    // })
  }, [])

  useEffect(() => {

    setIncomeByDateFilter(o => {
      const filter = { ...initIncomeByDateFilter }
      filter.AptTmSt = dayjs(dayjs().add(-4, "year").format("YYYY-01-01 00:00:00.00"))
      filter.AptTmEn = dayjs()
      searchIncomeByDate(filter, "Total")
      return filter
    })
  }, [searchIncomeByDate])


  return (
    <BoxFC height="100%" sx={{ gap: 1 }} >
      <BoxFR justifyContent="center">
        <ComboBox sx={{ flex: 1, maxWidth: 200 }} options={msData.jobCombo} label={jobOrderColNm.JobSNm}
          selectedId={incomeByDateFilter.JobId}
          setSelectedId={(id) => setIncomeByDateFilter(oldState => ({ ...oldState, JobId: id }))}
          onKeyUp={handleComboKeyUp}
        />
        <YearSelect dteEn={incomeByDateFilter.AptTmEn}
          setDte={(dteSt, dteEn) => setIncomeByDateFilter(o => ({ ...o, AptTmSt: dteSt, AptTmEn: dteEn }))} />
        <Button variant="contained" onClick={() => searchIncomeByDate(incomeByDateFilter, selectedDataset)}><SearchOutlinedIcon /></Button>
      </BoxFR>
      <TextField variant="outlined" size="small" label="Data Type" select sx={{ width: 300, alignSelf: "center" }}
        value={selectedDataset} onChange={handleDatasetChange}>
        <MenuItem value="OwnAmnt">Transport Charge</MenuItem>
        <MenuItem value="CusAmnt">Advance Charge</MenuItem>
        <MenuItem value="TrnAmnt">Transport Charge Only</MenuItem>
        <MenuItem value="OwnNoTrnAmnt">Non-Transport Charge</MenuItem>
        <MenuItem value="Total">Invoice</MenuItem>
      </TextField>
      <Paper sx={{ p: 2, flex: 1, minHeight: 400 }}>
        <BoxFC sx={{ gap: 0 }} height="100%" >
          <Typography {...titleProp} >Amount of income for the past 5 calendar years by month</Typography>
          <Box height="100%">
            <Line flex={1} data={chartData.incomeByDateData} options={modIncomeLineChartOption} />
          </Box>
        </BoxFC>
      </Paper>
      <Grid2 container spacing={2} sx={{flex: 1}}>
        <Grid2 xs={12} md={6} >
          <Paper  sx={{ p: 2, width: "100%", height: "100%", minHeight: 400 }}>
            <BoxFC height="100%" width="100%" sx={{
                '& .sum-row': {
                  fontWeight: "bold",
                },
              }} >
              <Typography {...titleProp} >Monthly Invoice Totals and Outstanding Balances</Typography>
                <DataGridCellExpand
                  
                  hideFooter
                  rows={invoiceTable}
                  getRowClassName={(params) => params.row.YearMonth === "รวม" && "sum-row"}
                  columns={overDueYearMonthColumns}
                />
            </BoxFC>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper  sx={{ p: 2, height: "100%", minHeight: 400 }}>
            <BoxFC height="100%" width="100%"
              sx={{
                '& .sum-row': {
                  fontWeight: "bold",
                },
                '& .more-than': {
                  color: green[800]
                },
                '& .less-than': {
                  color: red[900]
                }
              }}>

              <Typography {...titleProp} >5-Year Transport Charge Breakdown by Job</Typography>
              <DataGridCellExpand
                hideFooter
                rows={dataTable}
                columns={tableColumns}
                getRowClassName={(params) => params.id === 0 && "sum-row"}
                getCellClassName={getCellClassName} />
            </BoxFC>
          </Paper>
        </Grid2>
      </Grid2>
    </BoxFC>
  )
}

export default InvoiceReport