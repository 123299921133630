import Swal from 'sweetalert2'

/**
 * If have one parameter it will be callback
 * If have two parameters 
 * - first will be string
 * - second will be callback
 */
export function alertConfirmDelete(param1, param2) {
  let msg, callBack
  if(typeof param1 === 'function'){
    callBack = param1
    msg = "ต้องการลบข้อมูล หรือไม่"
  } else {
    callBack = param2
    msg = param1
  }
  Swal.fire({
    html: msg,
    icon: 'warning',
    confirmButtonText: 'OK',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
  }).then((result) => {
    if (result.isConfirmed) {
      callBack()
    }
  })
}

export function alertConfirm(msg, okCallback, cancelCallback, okText = "ตกลง", cancelText = "ยกเลิก"){
  Swal.fire({
    html: msg,
    icon: 'warning',
    confirmButtonText: okText,
    cancelButtonText: cancelText,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
  }).then((result) => {
    if (result.isConfirmed) {
      okCallback()
    } else {
      cancelCallback && cancelCallback()
    }
  })
}

export function alertError(text) {
  Swal.fire({
    html: text,
    icon: 'error',
    confirmButtonText: 'OK',
    confirmButtonColor: '#3085d6'
  })
}

export function alertWarning(text) {
  Swal.fire({
    html: text,
    icon: 'warning',
    confirmButtonText: 'OK',
    confirmButtonColor: '#3085d6'
  })
}

export function alertInfo(text) {
  Swal.fire({
    html: text,
    icon: 'info',
    confirmButtonText: 'OK',
    confirmButtonColor: '#3085d6'
  })
}


export function alertSuccess(text) {
  Swal.fire({
    html: text,
    icon: 'success',
    confirmButtonText: 'OK',
    confirmButtonColor: '#3085d6'
  })
}