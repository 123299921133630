import defaultDailyTableColWidth from "components/UserPreferenceDialog/defaultDailyTableColWidth"

export const TARGET_MODE_OPERATION = "1";
export const TARGET_MODE_FINANCE = "2";
export const ACC_TYP_EMP = 1
export const ACC_TYP_CLI = 2

export const DIALOG_MODE_PROFILE = 100
export const DIALOG_MODE_EMPLOYEE = ACC_TYP_EMP
export const DIALOG_MODE_CLIENT = ACC_TYP_CLI

export const accountApiNm = {
  getAccount: "/account/getAccount",
  getAccountForClientTable: "/account/getAccountForClientTable",
  insertAccount: "/account/insertAccount",
  addNewUserAccount: "/account/addNewUserAccount",
  updateAccount: "/account/updateAccount",
  updateAccountPermission: "/account/updateAccountPermission",
  updateAccountJob: "/account/updateAccountJob",
  changePassword: "/account/changePassword",
  setNewPassword: "/account/setNewPassword",
  updateUserPref: "/account/updateUserPref", 
  updateFCMToken: "/account/updateFCMToken", 
  subscribeTopicFCM: "/account/subscribeTopicFCM", 
  deleteAccount: "/account/deleteAccount",
  logout: "/account/logout"
}


export const accountColNm =  {
  FName: "ชื่อ",
  LName: "นามสกุล",
  NName: "ชื่อเล่น",
  Email: "อีเมล",
  Tel: "โทร.",
  Pass: "พาสเวิร์ด",
  UsrPref: "ตั้งค่าส่วนตัว",
  AccSts: "สถานะ",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}

export const topicData = {
  ContNo: "CONT_NO",
}
export const defaultUserPref = {
  DefaultOpenMenu: {1:false, 2:false, 3: false, 4: false, 5:false},
  DailyTableColWidth: defaultDailyTableColWidth,
  EmailData: {Cc: "", Bcc: "", ReplyTo: "", Signature: ""},
  TargetData: {isShowTarget: true, targetMode: TARGET_MODE_OPERATION},
  NotificationData: {Contno: false}
}
