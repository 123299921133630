import { Box, Dialog, DialogContent, TextField, Button } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext } from "react";
import { useState } from "react";
import SearchButton from "components/buttons/SearchButton";
import { dailyExpenseReportColumns, printDailyExpenseColumnHeader } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { addIdForDataGrid, numberFormatNoFlotingPoint, sortObj } from "utils";
import dayjs from "dayjs";
import { branchApiNm } from "branch/constant/branchApiNm";
import TagBox from "components/TagBox";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import printDataTable from "utils/printDataTable";
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import { useMemo } from "react";
import { locationText } from "utils/locationText";
// import { getPOIPlace } from "utils/getPOIPlace";

let searchedDate = dayjs()

const DailyExpenseReportDialog = ({ dialogOpen, setDialogOpen }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])

  
  const modDataTable = useMemo(() => {
    return addSumRow({
      dataTable: dataTable,
      sumColNms: ["Fuel", "GateCharge", "LiftOnOff", "Toll", "Other", "Total"],
      sumColTotal: "ContNo"
    })
  }, [dataTable])

  const [filterData, setFilterData] = useState({
    ExpDte: dayjs(),
    TagData: msData.tagCombo.filter(tag => tag.refId === "DV")
  })

  const getData = useCallback((filterData) => {
    const postData = {
      ExpDteSt: filterData.ExpDte.format("YYYY-MM-DD 00:00:00"),
      ExpDteEn: filterData.ExpDte.format("YYYY-MM-DD 23:59:59.997"),
      TagIds: filterData.TagData.map(tag => tag.id)
    }

    ax.post(branchApiNm.getDailyExpenseReport, postData).then(value => {
      if (value.data) {
        sortObj(value.data, "JobOrdId")

        searchedDate = filterData.ExpDte

        const result = []
        let lastDrvId = 0
        for (const data of value.data) {
          
          const found =  result.find(item => result.JobOrdId?
             item.JobOrdId === data.JobOrdId: item.DrvId === data.DrvId)
          data.Amnt = +data.Amnt * -1

          if (found) {
            if (data.Rmk === "ค่าน้ำมัน") {
              found.Fuel += +data.Amnt
            } else if (data.Rmk === "ค่าผ่านท่า") {
              found.GateCharge += +data.Amnt
            } else if (data.Rmk === "ค่ายกตู้") {
              found.LiftOnOff += +data.Amnt
            } else if (data.Rmk === "ค่าทางด่วน") {
              found.Toll += +data.Amnt
            } else {
              found.Other += +data.Amnt
            }
            found.Total += +data.Amnt
            found.JobOrdId = data.JobOrdId
            found.Loc =  locationText(data)
            found.ContNo = data.ContNo
          } else {
            result.push({
              JobOrdId: data.JobOrdId,
              DrvId: data.DrvId,
              FullNm: lastDrvId === data.DrvId ? " " : `${data.FName} ${data.LName} (${data.NName})`,
              Loc: locationText(data, {numChar: 5}),
              ContNo: data.ContNo,
              Fuel: data.Rmk === "ค่าน้ำมัน" ? +data.Amnt : 0,
              GateCharge: data.Rmk === "ค่าผ่านท่า" ? +data.Amnt : 0,
              LiftOnOff: data.Rmk === "ค่ายกตู้" ? +data.Amnt : 0,
              Toll: data.Rmk === "ค่าทางด่วน" ? +data.Amnt : 0,
              Other: data.Rmk !== "ค่าน้ำมัน" 
                && data.Rmk !== "ค่าผ่านท่า"
                && data.Rmk !== "ค่ายกตู้"
                && data.Rmk !== "ค่าทางด่วน" ? +data.Amnt : 0,
              Total: +data.Amnt
            })
          }

          lastDrvId = data.DrvId
        }
        setDataTable(addIdForDataGrid(result, "JobOrdId"))
      }
    })
  }, [ax])

  const handleClose = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])

  const handleDateTimeChange = useCallback((newValue) => {
    setFilterData(o => ({ ...o, ExpDte: newValue }))
  }, [])

  const setTagData = useCallback((newValue) => {
    setFilterData(o => ({ ...o, TagData: newValue }))
  }, [])

  const handlePrint = useCallback(() => {

    const extraData = {
      title: [
        { header: "ประจำวันที่ :", value: searchedDate.format("DD/MM/YYYY"), headerWidth: 1 },
      ],
      top: [],
      bottom: []
    }
    const parintData = modDataTable.map((row, index) => {
      return {
        FullNm: row.FullNm || " ",
        Loc: row.Loc || " ",
        id: row.id,
        ContNo: row.ContNo || " ",
        Fuel: numberFormatNoFlotingPoint(row.Fuel) ,
        GateCharge: numberFormatNoFlotingPoint(row.GateCharge),
        LiftOnOff: numberFormatNoFlotingPoint(row.LiftOnOff),
        Toll: numberFormatNoFlotingPoint(row.Toll),
        Other: numberFormatNoFlotingPoint(row.Other),
        Total: numberFormatNoFlotingPoint(row.Total),
        isBold: row.id === 0
      }
    })
    printDataTable({
      title: "รายงานค่าใช้จ่าย",
      dataTable: parintData,
      companyData: msData.serverData.CompanyData,
      columnHeader: printDailyExpenseColumnHeader,
      extraData: extraData,
      maxPageSize: 27
    })
  }, [modDataTable, msData.serverData.CompanyData])

  const handleSearch = useCallback(() => {
    getData(filterData)
  }, [getData, filterData])


  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' >
      <DialogHeader handleDialogClose={handleClose}
        title="รายงานค่าใช้จ่ายประจำวัน"
      />
      <DialogContent>
        <BoxFC mt={2} height={700}>
          <BoxFR>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              label="วันที่ลงค่าใช้จ่าย"
              mask="__/__/____"
              value={filterData.ExpDte}
              onChange={handleDateTimeChange}
              renderInput={(params) => <TextField size="small" {...params} sx={{ width: 150 }} />} />
            <TagBox sx={{ width: 400 }} value={filterData.TagData} setValue={setTagData} tblAbbr="DV" />
            <SearchButton onClick={handleSearch} />
            <Box flex={1} />
            <Button variant="contained" onClick={handlePrint}>
              <PrintRoundedIcon sx={{ mr: 1 }} />พิมพ์รายงาน
            </Button>
          </BoxFR>
          <Box width="100%" flexGrow={1}
            sx={sxSumColorClass}>
            <DataGridCellExpand
              hideFooter
              rows={modDataTable}
              columns={dailyExpenseReportColumns}
              disableSelectionOnClick
              getRowClassName={getSumColorClassName}
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={handleClose}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(DailyExpenseReportDialog)