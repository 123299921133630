import { BoxFC, BoxFR } from "components/BoxCustom";
import React from "react";
import useClientContainerStatus from "./useClientContainerStatus"
import { Box, Button, ButtonGroup, MenuItem, TextField, Typography, useMediaQuery } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { columns } from "./columns";
import { blue } from "@mui/material/colors";
import { calculateRowClosingColor } from "utils";
import closingColor from "utils/closingColor";

const ClientContainerStatus = () => {
  const { state, fn } = useClientContainerStatus();
  const { dataTable, selectedGroup } = state;

  const w800 = useMediaQuery('(min-width:800px)');
  return (
    <BoxFC mx={2} height="100%" sx={{
      "& .MuiDataGrid-columnHeaders": { backgroundColor: blue[100] }
    }}>
      <BoxFR ml={1}>
        <Typography>Container Status :</Typography>
        {w800 ? (
          <ButtonGroup variant="outlined" >
            <Button onClick={() => fn.selectGroup(0)} variant={selectedGroup === 0 ? "contained" : "outlined"}>Open</Button>
            <Button onClick={() => fn.selectGroup(1)} variant={selectedGroup === 1 ? "contained" : "outlined"}>Ready for Deliver</Button>
            <Button onClick={() => fn.selectGroup(3)} variant={selectedGroup === 3 ? "contained" : "outlined"}>At Destination</Button>
            <Button onClick={() => fn.selectGroup(4)} variant={selectedGroup === 4 ? "contained" : "outlined"}>Waiting to Return</Button>
          </ButtonGroup>
        ) : (
          <TextField size="small" variant="outlined" select sx={{flex: 1}}
            value={selectedGroup}
            onChange={(e) => { fn.selectGroup(e.target.value) }}>
            <MenuItem value={0}>Open</MenuItem>
            <MenuItem value={1}>Ready for Deliver</MenuItem>
            <MenuItem value={3}>At Destination</MenuItem>
            <MenuItem value={4}>Waiting to Return</MenuItem>
          </TextField>
        )}

      </BoxFR>
      <Box width="100%" flex={1}
        sx={closingColor}>
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={columns}
          getRowClassName={calculateRowClosingColor} />
      </Box>
    </BoxFC>
  )
}

export default ClientContainerStatus