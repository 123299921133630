import React, { useContext } from "react";
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { BoxFC, BoxFR } from "./BoxCustom";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useState } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { useMemo } from "react";
import dayjs from "dayjs";

const DateTimePickerCustomTime = (props) =>{

  const {msData} = useContext(GlobalStateContext)

  const timeList = useMemo(()=>{
    if(!msData.serverData.PreText.Times || msData.serverData.PreText.Times.length === 0){
      return ["23:59", "00:00"]
    } else {
      return  msData.serverData.PreText.Times
    }
  }, [msData.serverData.PreText.Times])
  const [open, setOpen] = useState(false)
  return <DesktopDateTimePicker 
    closeOnSelect={false}
    views={["day"]}
    open={open}
    onOpen={()=>setOpen(true)}
    onClose={()=>setOpen(false)}
    InputAdornmentProps={{ style: { marginLeft:0} }}
    OpenPickerButtonProps={{style: { paddingLeft:0}}}
    components={{
      PaperContent: ({value, setValue, children}) =>(
        <BoxFR>
          <Box>{children}</Box>
          <BoxFC sx={{gap:0.5}} height={300} overflow="auto" pr={1}>
            <Button key="now" variant="outlined" onClick={(e)=>{
              if(value=== null){
                setValue(dayjs())
              } else {
                setValue(dayjs(dayjs(dayjs(value).format(`YYYY-MM-DD ${dayjs().format("HH:mm:00.000")}`))))
              } 
              setOpen(false)
            }}>Now</Button>
            {timeList.map(item=>(
            <Button key={item} variant="outlined" onClick={(e)=>{
              if(value=== null){
                setValue(dayjs(dayjs().format(`YYYY-MM-DD ${item}:00.000`)))
              } else {
                setValue(dayjs(dayjs(value).format(`YYYY-MM-DD ${item}:00.000`)))
              }
              setOpen(false)
            }}>{item}</Button>
            ))}
          </BoxFC>
        </BoxFR>
      )
    }}
    
    PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
    componentsProps={{ paperContent: {value: props.value, setValue: props.onChange } }}
    {...props}
  />
}

export default DateTimePickerCustomTime