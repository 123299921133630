import React from "react";
import { Button } from '@mui/material'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';


const AddButton = ({children, onClick, color, disabled, size, sx}) => {

    return (
        <Button
            size={size}
            variant="contained"
            color={color || "primary"}
            disabled={disabled}
            onClick={onClick}
            sx={sx}>
            <AddCircleRoundedIcon sx={{ mr: 1 }} />
            {children}
        </Button>
    )
}

export default AddButton