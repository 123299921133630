

export const clearFilterData = {
  JobOrdId: null,
  AptTmSt: null,
  AptTmEn: null,
  ContNo: null,
  TakePlc: null,
  RtnPlc: null,
  JobNo: null,
  Bkg: null
}

export const initDialogData = {
  JobOrdId: 0,
  ShpmId: 0,
  JobNo: "",
  Bkg: "",
  Agnt: "",
  AptTm: null,
  ContNo: "",
  ContNo1: "",
  ContNo2: "",
  SealNo: "",
  TakePlc: "",
  Loc: "",
  RtnPlc: "",
  TakeDteSt: null,
  TakeDteEn: null,
  RtnDteSt: null,
  RtnDteEn: null,
  ContSizeFull: "",
  ContSize: "20",
  ContTyp: "GP",
  ContHq: 0,
  Rmk: "",
  JobId: "",
  CusId: "",
  FinDte: null,
  DIAmnt: "",
  DailyExpenses: [],
  TagNm: "",
}

export const initDIData = {
  Amnt: null,
  ExtAmnt: null,
  ExtTxt: null,
  AmntSh: null,
  DrvId: null, 
  JobOrdId: null,
  IsFrmSh: false
}

export const initDXData = {
  Amnt: "",
  Rmk: "",
  DrvId: null,
  ClrDte: null,
  ClrAccId: null,
  ExpDte: null,
  JobOrdId: null
}