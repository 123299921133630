
import {  jobOrderColNm } from "./jobOrderConstant"
import { billingNoteColNm } from "./billingNoteConstant"
import { receiveVoucherColNm } from "./receiveVoucherConstant"

export const receiptApiNm = {
  getReceipt: "/receipt/getReceipt",
  getReceiptTable: "/receipt/getReceiptTable",
  generateReceiptNo: "/receipt/generateReceiptNo",
  getReceiveVoucher: "/receipt/getReceiveVoucher",
  insertReceipt: "/receipt/insertReceipt",
  insertReceiptWithExpense: "/receipt/insertReceiptWithExpense",
  insertReceiptWithRV: "/receipt/insertReceiptWithRV",
  updateReceipt: "/receipt/updateReceipt",
  updateReceiptColumn: "/receipt/updateReceiptColumn",
  deleteReceipt: "/receipt/deleteReceipt"
}


export const receiptColNm =  {
  RcptId: "เลขที่ใบเสร็จ",
  RcptDte: "วันที่ใบเสร็จ",
  RcptNo: "เลขที่ใบเสร็จ",
  InvNo: billingNoteColNm.InvNo,
  BNNo: billingNoteColNm.BNNo,
  RVNo: receiveVoucherColNm.RVNo,
  BkAccId: receiveVoucherColNm.BkAccId,
  RVDte: receiveVoucherColNm.RVDte, 
  Rmk: "หมายเหตุ",
  CusSNm: jobOrderColNm.CusSNm,
  BillNm: "ชื่อลูกค้า",
  BillAddr: "ที่อยู่",
  BillTaxId: "เลขประจำตัวผู้เสียภาษี",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}